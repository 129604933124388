export const environmentallyHazardous = 'eh';

export const adrHazardClasses = [
  '1',
  '1.1',
  '1.2',
  '1.3',
  '1.4',
  '1.5',
  '1.6',
  '2.1',
  '2.2',
  '2.3',
  '3',
  '4.1',
  '4.2',
  '4.3',
  '5.1',
  '5.2',
  '6.1',
  '6.2',
  '7',
  '8',
  '9',
  environmentallyHazardous,
] as const;

export type AdrHazardClass = (typeof adrHazardClasses)[number];

export const isAdrHazardClass = (value: string): value is AdrHazardClass => adrHazardClasses.includes(value as AdrHazardClass);
