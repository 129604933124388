import { FC, ReactNode } from 'react';
import { Container, FullWidthGrid } from './style';
import { Testable } from '@components/types';

interface TableTile extends Testable {
  children: ReactNode;
}

export const TableTile: FC<TableTile> = ({ children, 'data-testid': dataTestId }) => {
  return (
    <Container data-testid={dataTestId}>
      <FullWidthGrid>{children}</FullWidthGrid>
    </Container>
  );
};
