import { QlikObjectProps } from '../QlikObject';
import { StyledQlikObject } from './styles';
import { FC } from 'react';

export const QLIK_SELECTION_OBJECT_HEIGHT_NUMERICAL = 35;
export const QLIK_SELECTION_OBJECT_WIDTH_NUMERICAL = 180;

export const QlikSelectionObject: FC<QlikObjectProps> = ({
  height = `${QLIK_SELECTION_OBJECT_HEIGHT_NUMERICAL}px`,
  width = `${QLIK_SELECTION_OBJECT_WIDTH_NUMERICAL}px`,
  minWidth = `${QLIK_SELECTION_OBJECT_WIDTH_NUMERICAL}px`,
  padding = '0px',
  ...rest
}) => <StyledQlikObject height={height} width={width} minWidth={minWidth} padding={padding} {...rest}></StyledQlikObject>;
