import { HorizontalContainer, VerticalContainer } from '@components/styles';
import { spacing } from '@fortum/elemental-ui';
import styled from 'styled-components';

export const DynamicTableContainer = styled(HorizontalContainer)`
  column-gap: ${spacing.xxxs};
`;

export const VerticalContainerWithSmallGaps = styled(VerticalContainer)`
  row-gap: ${spacing.xxxxs};
`;

export const VerticalContainerWithBigGaps = styled(VerticalContainer)`
  row-gap: ${spacing.xxxs};
`;

export const FiltersContainer = styled(HorizontalContainer)`
  column-gap: ${spacing.xxxs};
  flex: 1;
`;
