import { fetchResetPasswordUrl } from '@data/api/user';
import { useQuery } from '@tanstack/react-query';
import { shouldRetry } from '@utils/data';
import { QueryOptions } from './types';
import { QueryKeys } from '@common/query';
import { PasswordChangeUrlResponse } from '@models/user';

export const getChangePasswordUrlQueryOptions = (enabled: boolean): QueryOptions<PasswordChangeUrlResponse> => ({
  queryKey: [QueryKeys.passwordChangeUrl],
  queryFn: fetchResetPasswordUrl,
  enabled: enabled,
  refetchOnWindowFocus: false,
  retry: shouldRetry,
});

export const useChangePasswordUrl = (enabled: boolean) =>
  useQuery<PasswordChangeUrlResponse, Error>(getChangePasswordUrlQueryOptions(enabled));
