import React, { useEffect } from 'react';

export const useClickOutside = (componentRef: React.MutableRefObject<HTMLElement | null> | null, onClickOutside: () => void) => {
  useEffect(() => {
    const listener = (ev: MouseEvent) => {
      if (!componentRef || !componentRef.current) {
        return;
      }

      if (!componentRef.current.contains(ev.target as HTMLElement)) {
        onClickOutside();
      }
    };

    window.addEventListener('mousedown', listener);

    return () => {
      window.removeEventListener('mousedown', listener);
    };
  }, [onClickOutside, componentRef]);
};
