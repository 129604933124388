import { useUsersProfile } from '@data/hooks/useUserProfile';
import { Button, IconButton, IconCross } from '@fortum/elemental-ui';
import { FC } from 'react';
import {
  ButtonContainer,
  Container,
  DRAWER_WIDTH,
  Divider,
  StyledErrorView,
  closeButtonStyles,
  closeIconButtonStyles,
} from './styles';
import { MainSection } from './MainSection';
import { DefaultPermissionsSection } from './DefaultPermissionsSection';
import { BusinessPartnersSection } from './BusinessPartnersSection';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { isUserInternal } from '@utils/user';
import { AdditionalPermissionsSection } from './AdditionalPermissionsSection';
import { SideDrawer } from '@components/SideDrawer';
import { UserProfile } from '@models/usersManagement';

interface UserProfilePanelProps {
  selectedUser: UserProfile | null;
  open: boolean;
  onClose: () => void;
}

export const UserProfilePanel: FC<UserProfilePanelProps> = ({ selectedUser, open, onClose }) => {
  const { data: user, isLoading, isError } = useUsersProfile(selectedUser?.uuid);
  const { t } = useTranslation<Namespace>('common');

  if (!selectedUser) return null;

  return (
    <SideDrawer open={open} onClickOutside={onClose} width={DRAWER_WIDTH}>
      <Container>
        <IconButton {...closeIconButtonStyles} icon={<IconCross size={24} />} onClick={onClose} />

        <MainSection user={user} prefetchedUser={selectedUser} isLoading={isLoading} isError={isError} />

        <Divider />

        {isError ? (
          <StyledErrorView />
        ) : (
          <>
            <DefaultPermissionsSection user={user} isLoading={isLoading} />

            <Divider />

            {isUserInternal(selectedUser?.userRole) ? (
              <AdditionalPermissionsSection
                permissions={user?.additionalPermissions ?? []}
                disabledPermissions={user?.disabledAdditionalPermissions ?? []}
                isLoading={isLoading}
              />
            ) : (
              <BusinessPartnersSection user={user} isLoading={isLoading} />
            )}
          </>
        )}

        <ButtonContainer>
          <Button {...closeButtonStyles} onClick={onClose}>
            {t('close')}
          </Button>
        </ButtonContainer>
      </Container>
    </SideDrawer>
  );
};
