import { CONTAINER_BORDER_RADIUS, VerticalContainer } from '@components/styles';
import { ContentTextProps, Modal, colors, spacing, fontWeights, fontSizes, breakpoints } from '@fortum/elemental-ui';
import styled from 'styled-components';

export const NoServicesSelectedContainer = styled(VerticalContainer)`
  align-items: start;
  gap: ${spacing.xs};
  margin-bottom: ${spacing.s};
`;

export const headerTextStyles: ContentTextProps = {
  fontSize: fontSizes.l,
};

export const instructionsTextStyles: ContentTextProps = {
  fontSize: fontSizes.s,
  color: colors.sonicGrey,
};

export const shortDescriptionTextStyles: ContentTextProps = {
  fontWeight: fontWeights.medium,
};

export const WideModal = styled(Modal)`
  --padding-vertical: 48px;
  max-width: 1296px;
  width: 100%;
  box-sizing: border-box;
  max-height: ${`calc(100% - 2 * var(--padding-vertical))`};
  padding: ${spacing.xxs} 48px;
  justify-content: start;
  border-radius: ${CONTAINER_BORDER_RADIUS};

  :focus-visible {
    box-shadow: none;
    outline: none;
  }

  > div {
    width: 100%;
    align-items: start;
    text-align: unset;
    padding: 0;
    box-shadow: unset;
  }

  > div:nth-child(2) {
    height: 100%;
  }

  @media ${`(max-width: ${breakpoints.xxl}${'px'})`} {
    --padding-horizontal: 80px;
    max-width: ${`calc(100% - 2 * var(--padding-horizontal))`};
  }

  @media ${`(max-width: ${breakpoints.l}${'px'})`} {
    --padding-horizontal: 78px;
    max-width: ${`calc(100% - 2 * var(--padding-horizontal))`};
  }
`;
