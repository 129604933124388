import { HttpError } from '@common/http';
import { createOrders, CreateOrdersParams } from '@data/api/order';
import { OrdersCreationResponse } from '@models/orders';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

type OrdersCreationMutationOptions = Omit<
  UseMutationOptions<OrdersCreationResponse, HttpError, CreateOrdersParams>,
  'mutationFn'
>;

export const useOrdersCreationMutation = (mutationOptions?: OrdersCreationMutationOptions) =>
  useMutation({
    mutationFn: createOrders,
    ...mutationOptions,
  });
