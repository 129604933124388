import { NonWrappingLinkLowerCased } from '@components/layout/styles';
import { Namespace } from '@config/i18n';
import { paths } from '@config/routes';
import { ContentText, IconDocuments } from '@fortum/elemental-ui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Container } from '../styles';
import { linkStyles, messageWrappingTextStyles, PositionedPendingRequestView, StyledPageHeader } from './styles';

const PendingRequestMessage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation<Namespace>('orderCreation');

  return (
    <ContentText {...messageWrappingTextStyles}>
      {t('pendingRequestNote.message')}
      <NonWrappingLinkLowerCased {...linkStyles} onClick={() => navigate(paths.contactUs)}>
        {t('pendingRequestNote.linkText')}
      </NonWrappingLinkLowerCased>
    </ContentText>
  );
};

export const PendingRequestView = () => {
  const { t } = useTranslation<Namespace>('orderCreation');

  return (
    <Container>
      <StyledPageHeader header={t('summaryPage.header.mainText')} icon={<IconDocuments />} />

      <PositionedPendingRequestView header={t('pendingRequestNote.header')} message={<PendingRequestMessage />} />
    </Container>
  );
};
