import { Stylable } from '@components/types';
import { Namespace } from '@config/i18n';
import { paths } from '@config/routes';
import { Box, ContentText, IconDistrictHeatingOutage, IconMassMarketTested, Link } from '@fortum/elemental-ui';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { messageStyle, iconStyle, boxStyle, headingStyle, linkStyle } from './styles';

interface Props extends Stylable {
  headingKey: 'noResults' | 'failedToLoadData';
  messageKey: 'tryToAdjust' | 'tryReload';
}

const Message: FC<Pick<Props, 'messageKey'>> = ({ messageKey }) => {
  const { t } = useTranslation<Namespace[]>(['common', 'errors']);
  const navigate = useNavigate();

  return messageKey === 'tryToAdjust' ? (
    <ContentText {...messageStyle}>{t(`common:${messageKey}`)}</ContentText>
  ) : (
    <ContentText {...messageStyle}>
      {t(`errors:${messageKey}`)}
      <Link {...linkStyle} onClick={() => navigate(paths.contactUs)}>
        {t('errors:contactUs')}
      </Link>
    </ContentText>
  );
};

const InfoNote: FC<Props> = ({ headingKey, messageKey, className }) => {
  const { t } = useTranslation<Namespace>('common');

  const icon =
    headingKey === 'noResults' ? <IconMassMarketTested {...iconStyle} /> : <IconDistrictHeatingOutage {...iconStyle} />;

  return (
    <Box {...boxStyle} className={className}>
      {icon}
      <ContentText {...headingStyle}>{t(headingKey)}</ContentText>
      <Message messageKey={messageKey} />
    </Box>
  );
};

export const NoResultsNote: FC<Stylable> = ({ className }) => (
  <InfoNote headingKey="noResults" messageKey="tryToAdjust" className={className} />
);

export const FailedToLoadDataNote: FC<Stylable> = ({ className }) => (
  <InfoNote headingKey="failedToLoadData" messageKey="tryReload" className={className} />
);
