import { FC } from 'react';
import { Box, colors, ContentText, fontSizes, fontWeights, px2rem, spacing } from '@fortum/elemental-ui';
import { EmptyContractsIcon } from '@assets/icons/EmptyContracts';
import { Stylable } from '@components/types';

interface FilteringResultProps extends Stylable {
  message: string;
  title?: string;
  isSuccessful?: boolean;
}

export const FilteringResult: FC<FilteringResultProps> = ({ message, isSuccessful, title, className }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      alignSelf="center"
      mt={px2rem(140)}
      flexDirection="column"
      width={px2rem(392)}
      className={className}
    >
      <EmptyContractsIcon />
      {isSuccessful && (
        <ContentText
          mt={spacing.m}
          textAlign="center"
          fontWeight={fontWeights.regular}
          fontSize={fontSizes.xl}
          color={colors.emperorGrey}
        >
          {title}
        </ContentText>
      )}
      <ContentText
        mt={isSuccessful ? 0 : spacing.m}
        textAlign="center"
        fontWeight={fontWeights.regular}
        fontSize={fontSizes.s}
        color={colors.silverGrey}
      >
        {message}
      </ContentText>
    </Box>
  );
};
