import { createContext, FC, ReactNode, useMemo } from 'react';

export const QlikEngineContext = createContext<{ enigma: enigmaJS.IGeneratedAPI | null }>({
  enigma: null,
});

interface QlikEngineContextProviderProps {
  enigma: enigmaJS.IGeneratedAPI | null;
  children: ReactNode;
}
export const QlikEngineContextProvider: FC<QlikEngineContextProviderProps> = ({ enigma, children }) => {
  const providerValue = useMemo(() => ({ enigma }), [enigma]);
  return <QlikEngineContext.Provider value={providerValue}>{children}</QlikEngineContext.Provider>;
};
