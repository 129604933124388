import { QueryKeys } from '@common/query';
import { sessionKeys, StorageUtils } from '@common/storage';
import { setUpConnection } from '@data/api/qlik';
import { useQuery } from '@tanstack/react-query';
import { QueryOptions } from './types';
import { shouldRetry } from '@utils/data';
import { QlikAppAuthConfig } from 'src/types/qlik';

const qlikSessionQuery = (config: QlikAppAuthConfig): QueryOptions<boolean> => ({
  queryKey: [QueryKeys.qlikAuth],
  queryFn: () => setUpConnection(config),
  refetchOnWindowFocus: false,
  retry: shouldRetry,
  enabled: StorageUtils.hasItem(sessionKeys.idToken),
});

export const useQlikSession = (config: QlikAppAuthConfig) => useQuery<boolean, Error>(qlikSessionQuery(config));
