import { Namespace } from '@config/i18n';
import { useTranslation } from 'react-i18next';
import { Box, Col, colors, Grid, Row, spacing } from '@fortum/elemental-ui';
import { ContactForm } from './components/ContactForm';
import { ContactDetails } from './components/ContactDetails';
import { FC } from 'react';
import { PageHeader } from '@components/PageHeader';

const ContactUs: FC = () => {
  const { t } = useTranslation<Namespace>('contactUs');

  return (
    <Box backgroundColor={colors.snowWhite} flex={1}>
      <PageHeader header={t('pageTitle')} />

      <Grid
        maxWidth={`calc(100% - 2*${spacing.xs})`}
        marginHorizontal={{ m: spacing.xs }}
        padding={{ m: '0px' }}
        marginVertical={spacing.xs}
      >
        <Row>
          <Col m={5}>
            <ContactForm />
          </Col>
          <Col m={1}></Col>
          <Col m={5}>
            <ContactDetails />
          </Col>
          <Col m={1}></Col>
        </Row>
      </Grid>
    </Box>
  );
};

export default ContactUs;
