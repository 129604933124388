import { VerticalContainer } from '@components/styles';
import { FC, useCallback, useMemo } from 'react';
import { SectionHeader } from './SectionHeader';
import { Box, ContentText, IconBusinessUi } from '@fortum/elemental-ui';
import { DetailedUserProfile, UserPermissionsInCompany } from '@models/usersManagement';
import {
  Divider,
  labelStyles,
  ShiftedAdditionalPermissionsSection,
  smallPaddingBottomBoxProps,
  UserRole,
  UserRoleContainer,
} from './styles';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { translate } from '@utils/internationalization';
import { PlaceholderWrapper } from '@components/PlaceholderWrapper';
import { getEmptyData } from '@utils/dataPlaceholders';

interface BusinessPartnersSectionProps {
  user: DetailedUserProfile | undefined;
  isLoading: boolean;
}

export const BusinessPartnersSection: FC<BusinessPartnersSectionProps> = ({ user, isLoading }) => {
  const { t } = useTranslation<Namespace>('usersManagement');

  const shouldDisplayDivider = useCallback(
    (index: number, companiesAmount: number) => index > 1 || index + 1 !== companiesAmount,
    [],
  );

  const companies = useMemo(() => (isLoading ? getEmptyData<UserPermissionsInCompany>(1) : user?.companies), [isLoading, user]);

  return (
    <>
      {companies?.map((company, index) => (
        <VerticalContainer key={index}>
          <SectionHeader icon={<IconBusinessUi />} text={company.companyName} size="s" displayPlaceholder={isLoading} />
          <Box {...smallPaddingBottomBoxProps}>
            <UserRoleContainer>
              <ContentText {...labelStyles}>{t('detailsPanel.businessPartner.userRole.label')}</ContentText>
              <PlaceholderWrapper displayPlaceholder={isLoading}>
                <UserRole data-testid="user-role">{translate(company.userRole, 'usersManagement', 'userRole')}</UserRole>
              </PlaceholderWrapper>
            </UserRoleContainer>
          </Box>

          <ShiftedAdditionalPermissionsSection
            permissions={company.additionalPermissions}
            disabledPermissions={company.disabledAdditionalPermissions}
            isLoading={isLoading}
          />

          {!!user && shouldDisplayDivider(index, user.companies.length) && <Divider />}
        </VerticalContainer>
      ))}
    </>
  );
};
