import { FC } from 'react';
import { closeIconButtonStyles, HeaderContainer, headerTextStyles } from './styles';
import { ContentText, IconButton, IconCross } from '@fortum/elemental-ui';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';

interface OrderPreviewHeaderProps {
  onClose: () => void;
}

export const OrderPreviewHeader: FC<OrderPreviewHeaderProps> = ({ onClose }) => {
  const { t } = useTranslation<Namespace[]>(['orderDetails', 'common']);

  return (
    <HeaderContainer data-testid="order-preview-header">
      <ContentText {...headerTextStyles}>{t('orderDetails:pageTitle')}</ContentText>

      <IconButton
        aria-label={t('common:close')}
        data-testid="order-details-modal-close-button"
        status="plain"
        onClick={onClose}
        icon={IconCross}
        {...closeIconButtonStyles}
      />
    </HeaderContainer>
  );
};
