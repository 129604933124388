import { ContractMetaFilters, ContractFiltersKeys, ContractMetaFiltersSelectItems, ContractFilters } from 'src/types/services';
import { useQuery } from '@tanstack/react-query';
import i18n from '@config/i18n';
import { useServiceFilters } from '@data/hooks/useServiceFilters';
import { compact } from 'lodash';
import { useState, useRef, useMemo, useCallback, useEffect } from 'react';
import {
  mapToFiltersKeys,
  mapToServiceFilters,
  getServiceFiltersByKeyOrValue,
  mapToFiltersSelectItems,
} from '@utils/servicesFilters';
import { useDebounceValue } from 'usehooks-ts';

type QueryResultType = ReturnType<typeof useQuery<ContractMetaFilters, Error>>;
const debounceOptions: Parameters<typeof useDebounceValue>[2] = { maxWait: 1000, trailing: true };

export interface UseContractsFiltersHandlerReturnValue {
  selectedWasteTypes: ContractMetaFilters['wastes'];
  selectedEquipmentTypes: ContractMetaFilters['equipments'];
  search: string;
  queryResult: Pick<QueryResultType, 'isLoading' | 'isError' | 'fetchStatus'>;
  filtersKeysWithSearch: ContractFiltersKeys;
  debouncedFiltersKeysWithSearch: ContractFiltersKeys;
  filtersSelectItems: ContractMetaFiltersSelectItems;
  lastDataReloadCause: keyof ContractMetaFiltersSelectItems | null;
  handleWasteTypesChange: (selectedWasteTypesKeys: string[]) => void;
  handleEquipmentTypesChange: (selectedEquipmentKeys: string[]) => void;
  handleSearchChange: (searchText: string) => void;
  resetAll: () => void;
}

const initialSelectedFiltersValues: ContractFilters = {
  wasteTypes: [],
  equipmentTypes: [],
  search: '',
};

export const useContractsFiltersHandler = (
  additionalFilteringForOrdersCreation = false,
  debounceTimeMS = 600,
): UseContractsFiltersHandlerReturnValue => {
  const [selectedFilters, setSelectedFilters] = useState<ContractFilters>(initialSelectedFiltersValues);

  const [filtersSelectItems, setFiltersSelectItems] = useState<ContractMetaFiltersSelectItems>({
    wasteTypes: [],
    equipmentTypes: [],
  });

  const lastDataReloadCause = useRef<keyof ContractMetaFiltersSelectItems | null>(null);

  const filtersKeysWithSearch = useMemo<ContractFiltersKeys>(
    () => mapToFiltersKeys(selectedFilters),
    [selectedFilters?.equipmentTypes, selectedFilters?.wasteTypes, selectedFilters?.search],
  );

  const [debouncedFiltersKeysWithSearch, setDebouncedFiltersKeysWithSearch] = useDebounceValue(
    filtersKeysWithSearch,
    debounceTimeMS,
    debounceOptions,
  );

  useEffect(() => {
    setDebouncedFiltersKeysWithSearch(filtersKeysWithSearch);
  }, [filtersKeysWithSearch]);

  const {
    data: availableFilters,
    isError,
    isLoading,
    fetchStatus,
  } = useServiceFilters(debouncedFiltersKeysWithSearch, additionalFilteringForOrdersCreation);

  const resetAll = useCallback(() => {
    setSelectedFilters(initialSelectedFiltersValues);

    lastDataReloadCause.current = null;
  }, []);

  const handleSearchChange = useCallback((searchText: string) => {
    setSelectedFilters(prev => ({
      ...prev,
      search: searchText.trim() ? searchText : '',
    }));
  }, []);

  const handleEquipmentTypesChange = useCallback(
    (selectedEquipmentKeys: string[]) => {
      const items = mapToServiceFilters(selectedEquipmentKeys, filtersSelectItems.equipmentTypes);

      lastDataReloadCause.current = 'equipmentTypes';

      setSelectedFilters(prev => ({ ...prev, equipmentTypes: items }));
    },
    [filtersSelectItems.equipmentTypes],
  );

  const handleWasteTypesChange = useCallback(
    (selectedWasteTypesKeys: string[]) => {
      const items = mapToServiceFilters(selectedWasteTypesKeys, filtersSelectItems.wasteTypes);

      lastDataReloadCause.current = 'wasteTypes';

      setSelectedFilters(prev => ({ ...prev, wasteTypes: items }));
    },
    [filtersSelectItems.wasteTypes],
  );

  useEffect(() => {
    if (!availableFilters) {
      return;
    }

    setSelectedFilters(prev => {
      const wasteServices = prev.wasteTypes.map(waste =>
        getServiceFiltersByKeyOrValue(availableFilters.wastes, waste.key, waste.value),
      );

      const equipments = prev.equipmentTypes.map(equipment =>
        getServiceFiltersByKeyOrValue(availableFilters.equipments, equipment.key, equipment.value),
      );

      return {
        ...prev,
        wasteTypes: compact(wasteServices),
        equipmentTypes: compact(equipments),
      };
    });

    setFiltersSelectItems(() => ({
      wasteTypes: mapToFiltersSelectItems(availableFilters.wastes),
      equipmentTypes: mapToFiltersSelectItems(availableFilters.equipments),
    }));
  }, [availableFilters?.equipments, availableFilters?.wastes, i18n.language]);

  return {
    selectedWasteTypes: selectedFilters.wasteTypes,
    selectedEquipmentTypes: selectedFilters.equipmentTypes,
    search: selectedFilters.search,
    queryResult: { isError, isLoading, fetchStatus },
    filtersKeysWithSearch,
    debouncedFiltersKeysWithSearch,
    filtersSelectItems,
    lastDataReloadCause: lastDataReloadCause.current,
    handleWasteTypesChange,
    handleEquipmentTypesChange,
    handleSearchChange,
    resetAll,
  };
};
