import { InputField } from '@components/InputField';
import { Multiselect } from '@components/Multiselect';
import { Pagination } from '@components/Pagination';
import { ErrorView } from '@components/layout';
import { FilteringResult } from '@components/layout/FilteringResult';
import {
  CONTAINER_BORDER_RADIUS,
  VerticalContainer,
  horizontalContainer,
  staticBorderStyles,
  verticalContainer,
} from '@components/styles';
import { spacing } from '@fortum/elemental-ui';
import styled from 'styled-components';

export const Container = styled.div`
  ${verticalContainer};
  gap: ${spacing.xxs};
  width: 100%;
  height: 100%;
`;

export const HorizontalContainerWithGap = styled.div`
  ${horizontalContainer};

  align-items: flex-start;

  gap: ${spacing.xxs};
`;

export const FiltersInnerContainer = styled(HorizontalContainerWithGap)`
  > div {
    flex: 1;
  }
`;

export const HeaderContainer = styled.div`
  ${horizontalContainer};

  gap: ${spacing.xs};
  justify-content: space-between;
  align-items: flex-end;
`;

export const HeaderDescriptionContainer = styled(VerticalContainer)`
  flex: 1;
`;

export const FiltersContainer = styled(VerticalContainer)`
  gap: ${spacing.xxs};
`;

export const SearchInputField = styled(InputField)`
  width: 24rem;
`;

export const SitesMultiselect: typeof Multiselect = styled(Multiselect)`
  flex: 1;
`;

export const TableWithPaginationContainer = styled.div`
  ${staticBorderStyles()};
  ${verticalContainer};

  flex: 1;

  min-height: 232px;
`;

export const StyledPagination = styled(Pagination)`
  z-index: 2;

  box-shadow: 0 -6px 15px 0 rgba(0, 0, 0, 0.15);

  border-bottom-left-radius: ${CONTAINER_BORDER_RADIUS};
  border-bottom-right-radius: ${CONTAINER_BORDER_RADIUS};
`;

export const StyledErrorView = styled(ErrorView)`
  flex: unset;
  margin: auto;
`;

export const StyledFilteringResult = styled(FilteringResult)`
  padding: ${spacing.xxs};
  margin: auto;
`;

export const ButtonsContainer = styled.div`
  ${horizontalContainer};

  justify-content: space-between;
`;
