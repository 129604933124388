import { logEvent } from '@config/azureInsights';
import { OrdersSelectedFilters, OrdersSelectedFiltersWithSearch } from '@models/filters';
import { HandlingStatusGroup } from '@models/orders';
import { useCallback, useState } from 'react';
import { ALL_TIME_PERIOD, DEFAULT_PRECEDENCE, FiltersPrecedence, SELECTED_FILTERS_DEFAULT_VALUES } from './types';

export const useFiltersSetters = (temporaryState: boolean) => {
  const [selectedFiltersValues, setSelectedFiltersValues] =
    useState<OrdersSelectedFiltersWithSearch>(SELECTED_FILTERS_DEFAULT_VALUES);
  const [filtersPrecedence, setFiltersPrecedence] = useState<FiltersPrecedence>(DEFAULT_PRECEDENCE);

  const reset = useCallback(() => {
    setSelectedFiltersValues(SELECTED_FILTERS_DEFAULT_VALUES);
    setFiltersPrecedence(DEFAULT_PRECEDENCE);
  }, []);

  const setSelectedFilterPrecedence = useCallback((key: keyof OrdersSelectedFiltersWithSearch, remove: boolean) => {
    setFiltersPrecedence(prev => {
      if (remove) return prev.filter(prevPrecedence => prevPrecedence.key !== key);

      if (prev.find(prevPrecedence => prevPrecedence.key === key)) return prev;

      return [...prev, { key, order: prev.length }];
    });
  }, []);

  /**
   * Sending ALL value to the BE has same effect as sending en empty '' value
   * From the UI point of view it is better to store an empty selected value,
   * When the language is changed, all filters should be reset. When using ALL as unselected value what happens in the background is:
   * 1. Select component sets ALL item as selected. Since data has not been refreshed yet, this item contains translation to the old language.
   * At the same time request to the BE for data in new language is sent.
   * 2. Response from BE is received. It contains data in new language, it triggers SelectItems creation
   * 3. Select component is updated with SelectItems in new language, but selected item set in the 1st step cannot be found there - it is translated to the old lang.
   * As a result, selected item displayed on the component and list of items displayed in the component are translated to different languages.
   * Using the empty value eliminates this problem and simplifies the solution
   */
  const setTimePeriod = useCallback(
    (selectValue: string) => {
      const isAllTimePeriod = selectValue === ALL_TIME_PERIOD;

      if (!temporaryState && !isAllTimePeriod) {
        logEvent('ordersTrackingFilterTimePeriodSelected', { selectedValue: selectValue });
      }

      setSelectedFilterPrecedence('timePeriod', isAllTimePeriod);
      setSelectedFiltersValues(prev => ({
        ...prev,
        timePeriod: selectValue === ALL_TIME_PERIOD ? '' : selectValue,
      }));
    },
    [selectedFiltersValues],
  );

  const setOrderStatus = useCallback((orderStatus: HandlingStatusGroup[]) => {
    setSelectedFilterPrecedence('orderStatus', orderStatus.length === 0);

    setSelectedFiltersValues(prev => ({ ...prev, orderStatus }));

    if (!temporaryState && orderStatus.length > 0) {
      logEvent('ordersTrackingFilterStatusSelected', { selectedValue: orderStatus.join(',') });
    }
  }, []);

  const setOrderTypes = useCallback((orderTypes: string[]) => {
    setSelectedFilterPrecedence('orderType', orderTypes.length === 0);
    setSelectedFiltersValues(prev => ({ ...prev, orderType: orderTypes }));
  }, []);

  const setCities = useCallback((cities: string[]) => {
    setSelectedFilterPrecedence('city', cities.length === 0);
    setSelectedFiltersValues(prev => ({ ...prev, city: cities }));
  }, []);

  const setContainerTypes = useCallback((containerTypes: string[]) => {
    setSelectedFilterPrecedence('containerType', containerTypes.length === 0);
    setSelectedFiltersValues(prev => ({ ...prev, containerType: containerTypes }));
  }, []);

  const setTransportTypes = useCallback((transportTypes: string[]) => {
    setSelectedFilterPrecedence('transportType', transportTypes.length === 0);
    setSelectedFiltersValues(prev => ({ ...prev, transportType: transportTypes }));
  }, []);

  const setBusinessPartners = useCallback((businessPartners: string[]) => {
    setSelectedFilterPrecedence('businessPartner', businessPartners.length === 0);
    setSelectedFiltersValues(prev => ({ ...prev, businessPartner: businessPartners }));
  }, []);

  const setWasteDescriptions = useCallback((wasteDescriptions: string[]) => {
    setSelectedFilterPrecedence('wasteDescription', wasteDescriptions.length === 0);
    setSelectedFiltersValues(prev => ({ ...prev, wasteDescription: wasteDescriptions }));

    if (!temporaryState && wasteDescriptions.length > 0) {
      logEvent('ordersTrackingFilterWasteTypeSelected', { selectedValue: wasteDescriptions.join(',') });
    }
  }, []);

  const setSearch = useCallback((value: string) => {
    if (value.length === 0 || value.trim().length > 0) {
      setSelectedFilterPrecedence('search', value.length === 0);
      setSelectedFiltersValues(prev => ({ ...prev, search: value }));
    }
  }, []);

  const logFilterEvents = useCallback(
    (values: OrdersSelectedFilters) => {
      Object.entries(values).forEach(([key, value]) => {
        if (key === 'search' || !value.length) return;

        if (!temporaryState && selectedFiltersValues[key as keyof OrdersSelectedFiltersWithSearch] !== value) {
          switch (key) {
            case 'orderStatus':
              value = value as OrdersSelectedFiltersWithSearch['orderStatus'];
              logEvent('ordersTrackingFilterStatusSelected', { selectedValue: value.join(',') });
              break;
            case 'orderType':
              value = value as OrdersSelectedFiltersWithSearch['orderType'];
              logEvent('ordersTrackingFilterOrderTypeSelected', { selectedValue: value.join(',') });
              break;
            case 'city':
              value = value as OrdersSelectedFiltersWithSearch['city'];
              logEvent('ordersTrackingFilterCitySelected', { selectedValue: value.join(',') });
              break;
            case 'containerType':
              value = value as OrdersSelectedFiltersWithSearch['containerType'];
              logEvent('ordersTrackingFilterEquipmentTypeSelected', { selectedValue: value.join(',') });
              break;
            case 'transportType':
              value = value as OrdersSelectedFiltersWithSearch['transportType'];
              logEvent('ordersTrackingFilterTransportTypeSelected', { selectedValue: value.join(',') });
              break;
            case 'businessPartner':
              value = value as OrdersSelectedFiltersWithSearch['businessPartner'];
              logEvent('ordersTrackingFilterCompanySelected', { selectedValue: value.join(',') });
              break;
            case 'wasteDescription':
              value = value as OrdersSelectedFiltersWithSearch['wasteDescription'];
              logEvent('ordersTrackingFilterWasteTypeSelected', { selectedValue: value.join(',') });
              break;
            case 'timePeriod':
              value = value as OrdersSelectedFiltersWithSearch['timePeriod'];
              logEvent('ordersTrackingFilterTimePeriodSelected', { selectedValue: value });
              break;
            default:
              break;
          }
        }
      });
    },
    [selectedFiltersValues, temporaryState],
  );

  // TODO: FIXME: this seems to work inappropriately, because no matter on types we anyway receive values with search and it ends up in the same place
  // either make one function for all filters or make separate for search if it is really needed
  const setAllExceptSearch = useCallback(
    (values: OrdersSelectedFilters, precedence: FiltersPrecedence) => {
      logFilterEvents(values);

      setSelectedFiltersValues(prev => ({ ...prev, ...values }));
      setFiltersPrecedence(precedence);
    },
    [logFilterEvents],
  );

  const setAll = useCallback((values: OrdersSelectedFiltersWithSearch, precedence: FiltersPrecedence) => {
    setSelectedFiltersValues(values);
    setFiltersPrecedence(precedence);
  }, []);

  return {
    selectedFiltersValues,
    setters: {
      timePeriod: setTimePeriod,
      orderType: setOrderTypes,
      orderStatus: setOrderStatus,
      city: setCities,
      containerType: setContainerTypes,
      transportType: setTransportTypes,
      businessPartner: setBusinessPartners,
      wasteDescription: setWasteDescriptions,
      search: setSearch,
    },
    reset,
    setAllExceptSearch,
    filtersPrecedence,
    setAll,
  };
};
