import { horizontalContainer } from '@components/styles';
import { spacing, Button, Accordion } from '@fortum/elemental-ui';
import styled from 'styled-components';

export const ChipsContainer = styled.div`
  ${horizontalContainer};
  gap: ${spacing.xxs};
  flex-wrap: wrap;
`;

export const AbsolutelyPositionedButton = styled(Button)<{ $textWidth: string | undefined }>`
  --accordion-total-height: 68px;
  --button-total-height: 40px;

  --left-icon-width: 64px;
  --text-width: ${props => (props.$textWidth ? props.$textWidth : '150px')};

  position: absolute;
  left: calc(var(--left-icon-width) + var(--text-width));
  top: calc(var(--accordion-total-height) / 2 - var(--button-total-height) / 2);
`;

export const AccordionContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledAccordion = styled(Accordion)`
  & > div > div {
    padding-top: 0.5rem;
    padding-bottom: 1rem;
  }
`;
