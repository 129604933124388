import styled from 'styled-components';
import { px2rem, spacing } from '@fortum/elemental-ui';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${px2rem(848)};
  gap: 48px;
  padding: ${spacing.l};
`;
