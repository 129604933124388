import { CustomHttpHeaders, HttpError } from '@common/http';
import { StorageUtils } from '@common/storage';
import { appendRequestParams } from '@utils/data';
import { Headers as HeadersConstants } from 'http-constants-ts';
import { v4 as uuidV4 } from 'uuid';
import { QlikAppAuthConfig } from 'src/types/qlik';
import { getQlikUrl } from '@utils/qlik';

export const setUpConnection = async (config: QlikAppAuthConfig): Promise<boolean> => {
  const idToken = sessionStorage.getItem(StorageUtils.sessionKeys.idToken);
  if (!idToken) throw Error('Id token not found');

  const key = uuidV4().split('-').join('').substring(0, 16);

  const headers = new Headers([
    [CustomHttpHeaders.QLIK_XRF_KEY, key],
    [HeadersConstants.AUTHORIZATION, `Bearer ${idToken}`],
  ]);

  const url = getQlikUrl(config);

  const params = new Map<string, string>([['xrfkey', key]]);
  const urlWithParams = appendRequestParams(url, params);

  const resp = await fetch(urlWithParams, {
    headers,
    mode: 'cors',
    credentials: 'include',
  });

  if (resp.ok) {
    return true;
  }

  throw new HttpError(resp.status, resp.statusText);
};
