import { colors, ContentTextProps, fontSizes, lineHeights, PlaceholderProps } from '@fortum/elemental-ui';
import styled from 'styled-components';

export const ChildrenWrapper = styled.div`
  font-size: ${fontSizes.m};
  line-height: ${lineHeights.normal};
`;

export const ListItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 6px 0px;
`;

export const labelStyles: ContentTextProps = {
  fontSize: fontSizes.xs.default,
  color: colors.sonicGrey,
};

export const placeholderStyles: PlaceholderProps = {
  height: '24px',
  width: '90%',
};
