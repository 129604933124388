import { FC } from 'react';
import { OrderSummary } from 'src/types/ordersCreation';
import { OrderSummaryPreview } from './OrderSummaryPreview';
import { PlaceholdersContainer, placeholderStyles, PreviewContainer, StyledErrorView } from './styles';
import { useContractDetails } from '@data/hooks/useContractDetails';
import i18n from '@config/i18n';
import { containsWasteDetails } from '@utils/contract';
import { CompanySiteDetailsTile } from '@components/CompanySiteDetailsTile';
import { OrderPreviewDetailsWithWastes } from './OrderPreviewDatailsWithWastes';
import { OrderPreviewDetails } from './OrderPreviewDetails';
import { Placeholder } from '@fortum/elemental-ui';

const Placeholders: FC = () => (
  <PlaceholdersContainer>
    <Placeholder {...placeholderStyles} />
    <Placeholder {...placeholderStyles} />
  </PlaceholdersContainer>
);

interface OrderPreviewProps {
  orderSummary: OrderSummary;
  creationDate: string;
}

export const OrderPreview: FC<OrderPreviewProps> = ({ orderSummary, creationDate }) => {
  const {
    data: contract,
    isLoading,
    isError,
  } = useContractDetails(orderSummary.contractNo, orderSummary.dataSource, i18n.language);

  const displayWasteDetails = contract && containsWasteDetails(contract) && orderSummary.form.values.orderType !== 'DELIVERY';

  const shouldDisplayError = (!isLoading && !contract) || isError;

  return (
    <PreviewContainer>
      <OrderSummaryPreview
        contractNo={orderSummary.contractNo}
        orderType={orderSummary.form.values.orderType}
        creationDate={creationDate}
      />

      {shouldDisplayError ? (
        <StyledErrorView />
      ) : (
        <>
          <CompanySiteDetailsTile
            customer={contract?.generalInfo.customer}
            location={contract?.generalInfo.location}
            isLoading={isLoading}
          />

          {isLoading ? (
            <Placeholders />
          ) : displayWasteDetails ? (
            <OrderPreviewDetailsWithWastes form={orderSummary.form} contract={contract} />
          ) : (
            !!contract && <OrderPreviewDetails form={orderSummary.form} contract={contract} />
          )}
        </>
      )}
    </PreviewContainer>
  );
};
