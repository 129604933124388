import Ajv, { JSONSchemaType } from 'ajv';
import { QtyReportObjects, WasteQtyAppConfig, WasteQtyReportConfig } from './types';
import { defaultSelectionsSchema } from '../schemas';

const objectsSchema: JSONSchemaType<QtyReportObjects> = {
  $id: 'objects-schema',
  type: 'object',
  properties: {
    filters: {
      type: 'object',
      properties: { executionMonth: { type: 'string' }, executionQuarter: { type: 'string' }, executionYear: { type: 'string' } },
      required: ['executionMonth', 'executionQuarter', 'executionYear'],
    },
    visualization: {
      type: 'object',
      properties: {
        table: { type: 'string' },
        dimensions: {
          type: 'object',
          properties: {
            companyInformation: { type: 'string', nullable: true },
            customerHierarchy: { type: 'string', nullable: true },
            wasteDetails: { type: 'string', nullable: true },
            orderDetails: { type: 'string', nullable: true },
            invoiceDetails: { type: 'string', nullable: true },
            tourInformation: { type: 'string', nullable: true },
            deliveryPointInformation: { type: 'string', nullable: true },
            measures: { type: 'string', nullable: true },
          },
        },
      },
      required: ['table', 'dimensions'],
    },
    buttons: {
      type: 'object',
      properties: { changeWeightUnits: { type: 'string' } },
      required: ['changeWeightUnits'],
    },
  },
  required: ['filters', 'visualization', 'buttons'],
};

const appConfigSchema: JSONSchemaType<WasteQtyAppConfig> = {
  $id: 'app-config-schema',
  type: 'object',
  properties: {
    appId: { type: 'string' },
    objectIds: { type: 'object', $ref: 'objects-schema' },
    companyNameSelectionFieldName: { type: 'string' },
    defaultSelections: { type: 'array', items: defaultSelectionsSchema },
  },
  required: ['appId', 'companyNameSelectionFieldName', 'defaultSelections'],
};

const WasteQtyReportConfigSchema: JSONSchemaType<WasteQtyReportConfig> = {
  type: 'object',
  properties: {
    en: {
      type: 'object',
      $ref: 'app-config-schema',
      required: ['objectIds'],
    },
    fi: { type: 'object', $ref: 'app-config-schema' },
    sv: { type: 'object', $ref: 'app-config-schema' },
  },
  required: ['en'],
};

export const wasteQtyReportConfigSchemaValidator = () =>
  new Ajv().addSchema([defaultSelectionsSchema, objectsSchema, appConfigSchema]).compile(WasteQtyReportConfigSchema);
