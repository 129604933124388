import styled, { css } from 'styled-components';
import { colors, fontWeights, spacing } from '@fortum/elemental-ui';

export const MenuButton = styled.button<{ $checked: boolean }>`
  user-select: none;
  cursor: pointer;
  border: none;
  text-align: start;
  word-break: break-word;
  padding: 0 ${spacing.s};
  font-size: ${spacing.xxs};
  line-height: 21px;
  font-family: FortumSans, arial, sans-serif;
  min-height: 21px;
  font-style: normal;
  background-color: ${colors.snowWhite};
  font-weight: ${fontWeights.light};
  color: ${colors.mineShaftGrey};
  ${props =>
    props.$checked &&
    css`
      color: ${colors.mineShaftGrey};
      font-weight: ${fontWeights.medium};
    `}

  &:hover {
    cursor: pointer;
  }
`;
