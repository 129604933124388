import { IconWasteManagement, Heading, ContentText, Box, Button, CommonModalProps } from '@fortum/elemental-ui';
import {
  buttonsContainerStyles,
  confirmationButtonStyles,
  contentTextStyles,
  headingTextStyles,
  iconStyles,
  StyledModal,
} from './style';
import { FC } from 'react';
import { Testable } from '@components/types';

type ConfirmationModalProps = Omit<CommonModalProps, 'children'> &
  Testable & {
    headerText: string;
    messageText: string;
    onConfirmClick: () => void;
    closingButtonText: string;
    confirmingButtonText: string;
  };

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  headerText,
  messageText,
  closingButtonText,
  confirmingButtonText,
  onConfirmClick,
  'data-testid': dataTestId,
  ...props
}) => (
  <StyledModal {...props} aria-labelledby="modal-heading" data-testid={dataTestId}>
    <IconWasteManagement {...iconStyles} />
    <Heading {...headingTextStyles}>{headerText}</Heading>
    <ContentText {...contentTextStyles}>{messageText}</ContentText>
    <Box {...buttonsContainerStyles}>
      <Button onClick={props.onClose} data-testid="closing-button">
        {closingButtonText}
      </Button>
      <Button {...confirmationButtonStyles} onClick={onConfirmClick} data-testid="confirming-button">
        {confirmingButtonText}
      </Button>
    </Box>
  </StyledModal>
);
