import { FC } from 'react';
import { gridStyles, columnStyles, VerticalContainerWithBigGap, wasteColumnStyles } from './styles';
import { Grid, Row, Col } from '@fortum/elemental-ui';
import {
  EquipmentDetails,
  TransportDetailsForm,
  WasteDetailsForm,
  InvoicingDetailsForm,
} from '@routes/ordersCreation/components';
import { Testable } from '@components/types';
import { CreationForm } from 'src/types/ordersCreation';
import { Contract } from '@models/contract';

interface OrderPreviewDetailsWithWastesProps extends Testable {
  form: CreationForm['forms'][number];
  contract: Contract;
}

export const OrderPreviewDetailsWithWastes: FC<OrderPreviewDetailsWithWastesProps> = ({
  form,
  contract,
  'data-testid': dataTestId,
}) => {
  const { invoicingDetails } = contract.contractDetails;

  return (
    <Grid {...gridStyles} data-testid={dataTestId}>
      <Row>
        <Col {...wasteColumnStyles}>
          <WasteDetailsForm form={form} contract={contract} />
        </Col>

        <Col {...columnStyles}>
          <VerticalContainerWithBigGap>
            <EquipmentDetails equipmentDetails={contract.serviceDetails.equipmentDetails} />

            <TransportDetailsForm form={form} contract={contract} />

            <InvoicingDetailsForm form={form} invoicingDetails={invoicingDetails} />
          </VerticalContainerWithBigGap>
        </Col>
      </Row>
    </Grid>
  );
};
