import { CommonBoxProps, CommonButtonProps, CommonColProps, CommonForwardLinkProps, colors, px2rem } from '@fortum/elemental-ui';

export const contractNoColProps: CommonColProps = {
  xl: 2,
  xxs: 3,
};

export const openOrdersColProps: CommonColProps = {
  xl: 2,
  xxs: 3,
};

export const serviceTypeColProps: CommonColProps = {
  xl: 8,
  xxs: 6,
};

export const linkProps: CommonForwardLinkProps = {
  fontSize: '18px',
  color: colors.oceanGreen,
};

export const linkBoxProps: CommonBoxProps = {
  display: 'flex',
  justifyContent: 'flex-end',
  flexDirection: 'row',
  gap: px2rem(48),
};

export const buttonProps: CommonButtonProps = {
  status: 'secondary',
  variant: 'condensed',
};
