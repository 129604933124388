import { Tile } from '@components/Tile';
import { Namespace } from '@config/i18n';
import { DescriptionList } from '@fortum/elemental-ui';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledDescriptionListItem } from '@components/styles';
import { translate } from '@utils/internationalization';
import { hasValue } from '@utils/dataOperations';
import { displayedValue } from '@utils/dataOperations';
import { TransportDetails } from '@models/contract';
import { ContactInformation, LocationPointDetails } from '@components/transportDetails';
import { getListItemTestId, getTileTestId } from '@utils/testsHelpers';
import { Contact } from '@models/contact';

interface TransportDetailsTileProps {
  transportDetails: TransportDetails;
  contactDetails: Contact;
  isEquipmentSales: boolean;
}

export const TransportDetailsTile: FC<TransportDetailsTileProps> = ({ transportDetails, contactDetails, isEquipmentSales }) => {
  const { t } = useTranslation<Namespace>('wasteDetails');

  const displayContactDetails = Object.values(contactDetails).some(hasValue);

  return (
    <Tile header={t('transportDetails.details')} data-testid={getTileTestId('transport-details')}>
      <DescriptionList size="condensed">
        <StyledDescriptionListItem label={t('transportDetails.transportType')} data-testid={getListItemTestId('transport-type')}>
          {translate(displayedValue(transportDetails.type), 'domain', 'transportType')}
        </StyledDescriptionListItem>

        {displayContactDetails && (
          <StyledDescriptionListItem
            label={t('transportDetails.contactDetails')}
            data-testid={getListItemTestId('contact-details')}
          >
            <ContactInformation
              contactName={contactDetails.name}
              phoneNumber={contactDetails.phone}
              email={contactDetails.email}
            />
          </StyledDescriptionListItem>
        )}

        {isEquipmentSales ? (
          <StyledDescriptionListItem
            label={t('transportDetails.loadingLocation')}
            data-testid={getListItemTestId('loading-location')}
          >
            <LocationPointDetails
              companyName={transportDetails.pickUpLocation.name}
              address={transportDetails.pickUpLocation.address}
            />
          </StyledDescriptionListItem>
        ) : (
          <StyledDescriptionListItem
            label={t('transportDetails.receivingLocation')}
            data-testid={getListItemTestId('receiving-location')}
          >
            <LocationPointDetails
              companyName={transportDetails.deliveryLocation.name}
              address={transportDetails.deliveryLocation.address}
            />
          </StyledDescriptionListItem>
        )}

        {hasValue(transportDetails.instructionsForDriver) && (
          <StyledDescriptionListItem
            label={t('transportDetails.instructionsForDriver')}
            data-testid={getListItemTestId('instructions-for-driver')}
          >
            {transportDetails.instructionsForDriver}
          </StyledDescriptionListItem>
        )}

        {hasValue(transportDetails.recurrence) && (
          <StyledDescriptionListItem
            label={t('transportDetails.recurrence')}
            data-testid={getListItemTestId('transport-recurrence')}
          >
            {translate(transportDetails.recurrence, 'domain', 'cycleType')}
          </StyledDescriptionListItem>
        )}
      </DescriptionList>
    </Tile>
  );
};
