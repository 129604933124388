import { HttpError } from '@common/http';
import { deleteOrder } from '@data/api/order';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';

type DeleteTemporaryOrderOptions = Omit<UseMutationOptions<void, HttpError, string, unknown>, 'mutationFn'>;

export const useDeleteTemporaryOrderMutation = (mutationOptions?: DeleteTemporaryOrderOptions) =>
  useMutation({
    mutationFn: deleteOrder,
    ...mutationOptions,
  });
