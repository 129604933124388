import { Placeholder } from '@fortum/elemental-ui';
import { fill } from 'lodash';
import { FC } from 'react';
import { placeholderStyles } from './styles';

interface PlaceholdersProps {
  lines: number;
}

export const Placeholders: FC<PlaceholdersProps> = ({ lines }) => (
  <>
    {fill(Array(lines), '').map((_empty, index) => (
      <Placeholder key={index} {...placeholderStyles} />
    ))}
  </>
);
