import { createContext, PropsWithChildren } from 'react';
import { useServicesSelector, UseServicesSelectorReturnType } from './useServicesSelector';

type ServicesSelectorContextProviderProps = PropsWithChildren;

type ServicesSelectorContextProps = UseServicesSelectorReturnType;

export const ServicesSelectorContext = createContext<ServicesSelectorContextProps>({
  filters: {} as UseServicesSelectorReturnType['filters'],
  sites: {} as UseServicesSelectorReturnType['sites'],
  clearAllSelections: () => undefined,
});

export const ServicesSelectorStateProvider = ({ children }: ServicesSelectorContextProviderProps) => {
  const servicesSelectorHandler = useServicesSelector();

  return <ServicesSelectorContext.Provider value={servicesSelectorHandler}>{children}</ServicesSelectorContext.Provider>;
};
