import { RefObject, useLayoutEffect, useState } from 'react';

//TODO: use this hook in other occurrences of ResizeObserver
export const useResizeObserver = <T extends HTMLElement>(containerRef: RefObject<T>) => {
  const [containerHeight, setContainerHeight] = useState<string | undefined>(
    containerRef.current?.clientHeight ? `${containerRef.current?.clientHeight}px` : undefined,
  );

  useLayoutEffect(() => {
    if (!containerRef?.current) return;

    const observer = new ResizeObserver(entries => {
      setContainerHeight(entries[0].borderBoxSize[0].blockSize.toString() + 'px');
    });
    observer.observe(containerRef?.current);

    return () => observer.disconnect();
  }, []);

  return {
    containerHeight,
  };
};
