import { Box, ContentText, fontSizes, fontWeights, lineHeights, spacing } from '@fortum/elemental-ui';
import { Namespace } from 'i18next';
import { useTranslation } from 'react-i18next';
import { ChangePasswordLink } from './style';
import { FC } from 'react';

interface ResetPasswordSectionProps {
  linkHref: string;
}

export const ResetPasswordSection: FC<ResetPasswordSectionProps> = ({ linkHref }) => {
  const { t } = useTranslation<Namespace>('user');

  return (
    <Box display="flex" flexDirection="column" marginTop={spacing.l} gap={spacing.xxs}>
      <ContentText fontSize={fontSizes.m} fontWeight={fontWeights.medium}>
        {t('resetPassword.title')}
      </ContentText>
      <ContentText fontSize={fontSizes.s}>{t('resetPassword.message')}</ContentText>
      <ChangePasswordLink
        noUnderline
        fontSize={fontSizes.m}
        display="flex"
        alignItems="center"
        lineHeight={lineHeights.normal}
        gap={spacing.xxs}
        href={linkHref}
        newTab={true}
        data-testid={'change-password-link'}
      >
        {t('resetPassword.link')}
      </ChangePasswordLink>
    </Box>
  );
};
