import { Box, colors, fontWeights, spacing } from '@fortum/elemental-ui';
import { Company } from '@models/service';
import { FC, memo } from 'react';
import { AddressLayout } from '@routes/services/components/location/AddressLayout';
import { StyledContentText } from '@routes/services/components/location/styles';

interface CompanyLayoutProps {
  id: string;
  city: string;
  company: Company;
}

export const CompanyLayout: FC<CompanyLayoutProps> = memo(({ company, city, id }) => (
  <Box display="flex" flexDirection="column" backgroundColor={colors.snowWhite} gap={spacing.xxxs} pt={spacing.xxs} id={id}>
    <StyledContentText fontWeight={fontWeights.medium} fontSize="12px" color={colors.mineShaftGrey} mr={spacing.xxs}>
      {company.name.toUpperCase()}
    </StyledContentText>

    <Box display="flex" flexDirection="column" flex={1} gap={spacing.xxs}>
      {company.addresses
        .filter(address => address.numberOfContracts > 0)
        .map((item, index) => {
          const key = `${company.name}-${city}-${item.name}-address-${index}`;

          return <AddressLayout id={item.name} key={key} companyName={company.name} cityName={city} address={item} />;
        })}
    </Box>
  </Box>
));

CompanyLayout.displayName = 'CompanyLayout';
