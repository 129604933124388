import { Tile } from '@components/Tile';
import { Namespace } from '@config/i18n';
import { displayedValue, hasValue } from '@utils/dataOperations';
import { getListItemTestId, getTileTestId } from '@utils/testsHelpers';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { translate } from '@utils/internationalization';
import { LocationPointDetails } from '@components/transportDetails';
import { InputField } from '@components/InputField';
import { Contacts } from './Contacts';
import { VerticalContainerWithSmallGap, VerticalListItemWithPadding } from '@routes/ordersCreation/components';
import { CreationForm } from 'src/types/ordersCreation';
import { Contract } from '@models/contract';
import { UseQueryResult } from '@tanstack/react-query';
import { Contact } from '@models/contact';
import { SimplifiedEvent } from 'src/types/events';
import { getContactDescription } from '@utils/contact';

interface TransportDetailsFormProps {
  form: CreationForm['forms'][number];
  contract: Contract;
  contactsDataHandler?: UseQueryResult<Contact[], Error>;
  setContactPerson?: (person: Contact) => void;
  setLocationPointAdditionalInfo?: (ev: SimplifiedEvent<string>) => void;
}

export const TransportDetailsForm: FC<TransportDetailsFormProps> = ({
  form,
  contract,
  contactsDataHandler,
  setContactPerson,
  setLocationPointAdditionalInfo,
}) => {
  const { t } = useTranslation<Namespace[]>(['wasteDetails', 'orderCreation']);

  const { transportDetails } = contract.serviceDetails;

  const contactPersonDescription = useMemo(() => getContactDescription(form.values.contactPerson), [form.values.contactPerson]);

  return (
    <Tile header={t('wasteDetails:transportDetails.details')} data-testid={getTileTestId('transport-details')}>
      <VerticalContainerWithSmallGap>
        <VerticalListItemWithPadding
          label={t('wasteDetails:transportDetails.transportType')}
          data-testid={getListItemTestId('transport-type')}
        >
          {translate(displayedValue(transportDetails.type), 'domain', 'transportType')}
        </VerticalListItemWithPadding>

        {setContactPerson && contactsDataHandler ? (
          <Contacts
            contactsDataHandler={contactsDataHandler}
            selectedContactPersonId={form.values.contactPerson?.code}
            setSelectedContactPerson={setContactPerson}
          />
        ) : (
          <VerticalListItemWithPadding
            label={t('wasteDetails:transportDetails.contactDetails')}
            data-testid={getListItemTestId('contact-details')}
          >
            {contactPersonDescription}
          </VerticalListItemWithPadding>
        )}

        <VerticalListItemWithPadding
          label={t('wasteDetails:transportDetails.loadingLocation')}
          data-testid={getListItemTestId('loading-location')}
        >
          <LocationPointDetails
            companyName={transportDetails.pickUpLocation.name}
            address={transportDetails.pickUpLocation.address}
            data-testid="loading-location"
          />
        </VerticalListItemWithPadding>

        <VerticalListItemWithPadding
          label={t('wasteDetails:transportDetails.receivingLocation')}
          data-testid={getListItemTestId('receiving-location')}
        >
          <LocationPointDetails
            companyName={transportDetails.deliveryLocation.name}
            address={transportDetails.deliveryLocation.address}
            data-testid="receiving-location"
          />
        </VerticalListItemWithPadding>

        {hasValue(transportDetails.instructionsForDriver) && (
          <VerticalListItemWithPadding
            label={t('transportDetails.instructionsForDriver')}
            data-testid={getListItemTestId('instructions-for-driver')}
          >
            {transportDetails.instructionsForDriver}
          </VerticalListItemWithPadding>
        )}

        {setLocationPointAdditionalInfo ? (
          <InputField
            data-testid="location-point-additional-info-input-field"
            name={`location-point-additional-info-${form.formIdentifier}`}
            label={t('orderCreation:form.transportationAdditionalInfo.label')}
            value={form.values.locationPointAdditionalInfo}
            onChange={setLocationPointAdditionalInfo}
            size="m"
          />
        ) : (
          <VerticalListItemWithPadding
            label={t('orderCreation:form.transportationAdditionalInfo.label')}
            data-testid={getListItemTestId('location-point-additional-info')}
          >
            {displayedValue(form.values.locationPointAdditionalInfo)}
          </VerticalListItemWithPadding>
        )}
      </VerticalContainerWithSmallGap>
    </Tile>
  );
};
