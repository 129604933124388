import { IconProps, colors, spacing, BoxProps, ContentTextProps, fontSizes, LinkProps } from '@fortum/elemental-ui';

export const iconStyle: IconProps = { size: 48, color: colors.silverGrey, mb: spacing.xxxs };

export const boxStyle: BoxProps = { display: 'flex', flexDirection: 'column', alignItems: 'center' };

export const headingStyle: ContentTextProps = { fontSize: fontSizes.xl.s, color: colors.emperorGrey };

export const messageStyle: ContentTextProps = {
  fontSize: fontSizes.m.default,
  color: colors.silverGrey,
  maxWidth: '350px',
  textAlign: 'center',
};

export const linkStyle: LinkProps = {
  noUnderline: true,
  marginLeft: spacing.xxxxs,
  fontSize: fontSizes.m.default,
  textTransform: 'lowercase',
};
