import { QlikSelectionObject } from '@components/qlik';
import { QtyReportObjects } from '@config/qlik';
import { FC } from 'react';
import { VerticalContainerWithGaps } from '../styles';

interface DimensionsProps {
  objectIds: QtyReportObjects;
}

export const Dimensions: FC<DimensionsProps> = ({ objectIds }) => (
  <VerticalContainerWithGaps>
    {!!objectIds.visualization.dimensions.companyInformation && (
      <QlikSelectionObject
        id={objectIds.visualization.dimensions.companyInformation}
        dataTestId="dimensions-company-information"
      />
    )}

    {!!objectIds.visualization.dimensions.customerHierarchy && (
      <QlikSelectionObject id={objectIds.visualization.dimensions.customerHierarchy} dataTestId="dimensions-customer-hierarchy" />
    )}

    {!!objectIds.visualization.dimensions.wasteDetails && (
      <QlikSelectionObject id={objectIds.visualization.dimensions.wasteDetails} dataTestId="dimensions-waste-details" />
    )}

    {!!objectIds.visualization.dimensions.orderDetails && (
      <QlikSelectionObject id={objectIds.visualization.dimensions.orderDetails} dataTestId="dimensions-order-details" />
    )}

    {!!objectIds.visualization.dimensions.invoiceDetails && (
      <QlikSelectionObject id={objectIds.visualization.dimensions.invoiceDetails} dataTestId="dimensions-invoice-details" />
    )}

    {!!objectIds.visualization.dimensions.tourInformation && (
      <QlikSelectionObject id={objectIds.visualization.dimensions.tourInformation} dataTestId="dimensions-tour-information" />
    )}

    {!!objectIds.visualization.dimensions.deliveryPointInformation && (
      <QlikSelectionObject
        id={objectIds.visualization.dimensions.deliveryPointInformation}
        dataTestId="dimensions-delivery-point-information"
      />
    )}

    {!!objectIds.visualization.dimensions.measures && (
      <QlikSelectionObject id={objectIds.visualization.dimensions.measures} dataTestId="dimensions-measures" />
    )}
  </VerticalContainerWithGaps>
);
