import { City, Company, ExactAddress, Service } from '@models/service';
import { TreeDetails } from '@routes/services/components/menu';
import { sum } from 'lodash';
import { destructureContractIdentifier } from './routes';
import { countriesWithOrdersCreationSupport, serviceTypesWithOrdersCreationSupport } from '@config/ordersCreation';
import { getDataSourceByCountry } from './dataOperations';
import { isSupportedServiceTypeValue } from './contract';

const getContractsAmountInCompany = (company: Company) => sum(company.addresses.map(address => address.numberOfContracts));

const getContractsAmountInCity = (city: City) => sum(city.companies.map(getContractsAmountInCompany));

export const getContractsAmountInService = (service: Service) => sum(service.cities.map(getContractsAmountInCity));

export const hasContracts = (city: City) =>
  city.companies.some(company => company.addresses.some(address => address.numberOfContracts !== 0));

export const groupCitiesByLocationCode = (cities: City[]) => {
  const resultMap: Record<string, TreeDetails> = {};

  cities?.forEach(city =>
    city.companies.forEach(company =>
      company.addresses.forEach(address =>
        address.exactAddresses.forEach(exactAddress => {
          resultMap[exactAddress.locationCode] = {
            locationCode: exactAddress.locationCode,
            address: address.name,
            companyName: company.name,
            streetAddress: exactAddress.name,
            numberOfContracts: exactAddress.numberOfContracts.toString(),
          };
        }),
      ),
    ),
  );
  return resultMap;
};

export const findExactAddressByLocationCode = (exactAddresses: ExactAddress[], locationCode: string) =>
  exactAddresses.find(exactAddress => exactAddress.locationCode === locationCode);

export const findCompanyByLocationCode = (companies: Company[], locationCode: string) =>
  companies.find(company =>
    company.addresses.find(address => findExactAddressByLocationCode(address.exactAddresses, locationCode)),
  );

export const getContractsNumberAmountLabel = (numberOfContracts: number, totalNumberOfContracts: number) =>
  numberOfContracts === totalNumberOfContracts ? numberOfContracts.toString() : `${numberOfContracts}/${totalNumberOfContracts}`;

export const shouldAllowOrdersCreation = (contractIdentifier: string, serviceType: string) => {
  if (!isSupportedServiceTypeValue(serviceType)) return false;
  const { dataSource } = destructureContractIdentifier(contractIdentifier);

  if (!dataSource) return false;

  return (
    countriesWithOrdersCreationSupport.map(getDataSourceByCountry).includes(dataSource) &&
    serviceTypesWithOrdersCreationSupport.includes(serviceType)
  );
};
