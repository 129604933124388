import { Row, Col, Box, ColProps } from '@fortum/elemental-ui';
import { OrderDetails } from '@models/orders';
import { FC } from 'react';
import { columnInWrappableRowProps, columnInnerContainerProps } from '../style';
import { CompanySiteDetailsTile } from './CompanySiteDetailsTile';
import { OrderStatusTile } from './OrderStatusTile';
import { TransportDetailsTile } from './TransportDetailsTile';
import { EquipmentDetailsTile } from './EquipmentDetailsTile';
import { InvoicingDetailsTile } from './InvoicingDetailsTile';

interface DeliveryOrderDetailsProps {
  order: OrderDetails;
}

const columnProps: ColProps = {
  xl: 6,
};

export const DeliveryOrderDetails: FC<DeliveryOrderDetailsProps> = ({ order }) => (
  <Row>
    <Col {...columnProps} {...columnInWrappableRowProps}>
      <Box {...columnInnerContainerProps}>
        <CompanySiteDetailsTile companySiteDetails={order.companySiteDetails} wrapSiteAddress={true} />
        <OrderStatusTile
          orderStatus={order.orderStatus}
          creatorName={order.generalInfo.creatorName}
          additionalComments={order.generalInfo.additionalComments}
        />
        <TransportDetailsTile transportDetails={order.transportDetails} />
      </Box>
    </Col>

    <Col {...columnProps}>
      <Box {...columnInnerContainerProps}>
        <EquipmentDetailsTile equipmentDetails={order.equipmentDetails} />
        <InvoicingDetailsTile invoicingDetails={order.invoicingDetails} />
      </Box>
    </Col>
  </Row>
);
