import { Box, ContentText, fontSizes } from '@fortum/elemental-ui';
import { hasValue } from '@utils/dataOperations';
import { NO_DATA_BADGE } from '@utils/dataOperations';
import { FC } from 'react';

interface ContactInformationProps {
  contactName: string | undefined;
  phoneNumber: string | undefined;
  email: string | undefined;
}

export const ContactInformation: FC<ContactInformationProps> = ({ contactName, phoneNumber, email }) => {
  const existingContactElements = [contactName, phoneNumber, email].filter(hasValue);

  return existingContactElements.length > 0 ? (
    <Box display="flex" flexDirection="column">
      {existingContactElements.filter(hasValue).map((value, index) => (
        <ContentText key={index} fontSize={fontSizes.s}>
          {value}
        </ContentText>
      ))}
    </Box>
  ) : (
    NO_DATA_BADGE
  );
};
