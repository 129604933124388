export const sortByValues = [
  'status',
  'executionDate',
  'orderType',
  'wasteType',
  'transportType',
  'containerType',
  'businessPartner',
  'site',
  'orderNo',
  'contractNo',
  'tourNo',
] as const;

export type SortByValue = (typeof sortByValues)[number];

export const NUMBER_OF_PAGE_ELEMENTS = 25;
