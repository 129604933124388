import React, { FC } from 'react';
import { SectionHeader } from './SectionHeader';
import { Box, colors, ContentText, IconCheck, IconCross, IconFile } from '@fortum/elemental-ui';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import {
  labelStyles,
  listItemWithIconStyles,
  VerticalContainerWithSmallGap,
  VerticalContainerWithSmallGapAndPadding,
} from './styles';
import { UserPermission } from '@config/user';
import { Stylable } from '@components/types';
import { Placeholders } from '../Placeholders';
import { VerticalContainer } from '@components/styles';

interface AdditionalPermissionsSectionProps extends Stylable {
  permissions: UserPermission[];
  disabledPermissions: UserPermission[];
  isLoading: boolean;
}

export const AdditionalPermissionsSection: FC<AdditionalPermissionsSectionProps> = ({
  permissions,
  disabledPermissions,
  isLoading,
  className,
}) => {
  const { t } = useTranslation<Namespace>('usersManagement');

  return (
    <VerticalContainerWithSmallGap className={className}>
      <SectionHeader icon={<IconFile />} text={`${t('detailsPanel.addFeaturesAccess.header')}`} size="s" />

      <VerticalContainerWithSmallGapAndPadding>
        {isLoading ? (
          <VerticalContainer>
            <Placeholders lines={2} />
          </VerticalContainer>
        ) : (
          <>
            {permissions.map(permission => (
              <Box key={permission} {...listItemWithIconStyles}>
                <ContentText {...labelStyles}>{t(`permissions.${permission}`)}</ContentText>
                <IconCheck color={colors.oceanGreen} />
              </Box>
            ))}
            {disabledPermissions.map(disabledPermission => (
              <Box key={disabledPermission} {...listItemWithIconStyles}>
                <ContentText {...labelStyles}>{t(`permissions.${disabledPermission}`)}</ContentText>
                <IconCross color={colors.powerRed} />
              </Box>
            ))}
          </>
        )}
      </VerticalContainerWithSmallGapAndPadding>
    </VerticalContainerWithSmallGap>
  );
};
