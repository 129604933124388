import { colors } from '@fortum/elemental-ui';
import styled from 'styled-components';

export const Overlay = styled.div<{ $open: boolean }>`
  display: flex;
  position: fixed;
  top: 0;
  left: ${props => (props.$open ? 0 : 3000)};
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.6);
  justify-content: flex-end;
  opacity: ${props => (props.$open ? 1 : 0)};
  transition: opacity 0.25s;
`;

export const Drawer = styled.div<{ $open: boolean; $width: string }>`
  background-color: ${colors.snowWhite};
  transition: width 0.5s;
  width: ${props => (props.$open ? props.$width : 0)};
`;
