import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { Box, colors, ContentText, fontSizes, IconEInvoice, spacing } from '@fortum/elemental-ui';

export const BrowserNotSupported: FC = () => {
  const { t } = useTranslation<Namespace>('errors');
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      backgroundColor={colors.snowWhite}
      height="100%"
      overflow="auto"
    >
      <Box display="flex" flexDirection="column" alignItems={'center'}>
        <IconEInvoice color={colors.silverGrey} size={48} />
        <ContentText fontSize={fontSizes.xl} color={colors.emperorGrey} pt={spacing.xxxs}>
          {t('browserNotSupported.weAreSorry')}
        </ContentText>
        <ContentText fontSize={fontSizes.s} color={colors.silverGrey}>
          {t('browserNotSupported.notSupported')}
        </ContentText>
        <ContentText fontSize={fontSizes.s} color={colors.silverGrey}>
          {t('browserNotSupported.tryUseDifferentBrowser')}
        </ContentText>
      </Box>
    </Box>
  );
};
