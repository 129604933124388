import Ajv, { JSONSchemaType } from 'ajv';
import { DashboardAppConfig, DashboardReportConfig, DashboardReportObjects } from './types';
import { defaultSelectionsSchema } from '../schemas';

const objectsSchema: JSONSchemaType<DashboardReportObjects> = {
  $id: 'objects-schema',
  type: 'object',
  properties: {
    buttons: {
      type: 'object',
      properties: { switchToMonthly: { type: 'string' }, switchToCumulative: { type: 'string' } },
      required: ['switchToMonthly', 'switchToCumulative'],
    },
    filters: {
      type: 'object',
      properties: {
        filterByCurrentYear: { type: 'string' },
        filterByLastYear: { type: 'string' },
        filterByLastLastYear: { type: 'string' },
        company: { type: 'string' },
        hazardous: { type: 'string' },
      },
      required: ['filterByCurrentYear', 'filterByLastLastYear', 'filterByLastYear', 'company', 'hazardous'],
    },
    charts: {
      type: 'object',
      properties: {
        costsAndCreditsOverTime: { type: 'string' },
        receivedMaterialOverTime: { type: 'string' },
        wasteHandling: { type: 'string' },
        costsInEuros: { type: 'string' },
        costByServiceGroup: { type: 'string' },
      },
      required: ['costsAndCreditsOverTime', 'receivedMaterialOverTime', 'wasteHandling', 'costsInEuros', 'costByServiceGroup'],
    },
    tables: {
      type: 'object',
      properties: {
        summaryByCustomerSite: { type: 'string', nullable: true },
        receivedMaterialOverTime: { type: 'string' },
        summaryByWaste: { type: 'string' },
      },
      required: ['summaryByWaste', 'receivedMaterialOverTime'],
    },
    dynamicTable: {
      type: 'object',
      properties: {
        table: { type: 'string' },
        dimensions: {
          type: 'object',
          properties: {
            company: { type: 'string' },
            wasteDetails: { type: 'string' },
            measures: { type: 'string' },
          },
          required: ['company', 'wasteDetails', 'measures'],
        },
      },
      required: ['table', 'dimensions'],
    },
  },
  required: ['buttons', 'charts', 'dynamicTable', 'filters', 'tables'],
};

const appConfigSchema: JSONSchemaType<DashboardAppConfig> = {
  $id: 'app-config-schema',
  type: 'object',
  properties: {
    appId: { type: 'string' },
    objectIds: { type: 'object', $ref: 'objects-schema' },
    companyNameSelectionFieldName: { type: 'string' },
    defaultSelections: { type: 'array', items: defaultSelectionsSchema },
  },
  required: ['appId', 'companyNameSelectionFieldName', 'defaultSelections'],
};

const DashboardReportConfigSchema: JSONSchemaType<DashboardReportConfig> = {
  type: 'object',
  properties: {
    en: {
      type: 'object',
      $ref: 'app-config-schema',
      required: ['objectIds'],
    },
    fi: { type: 'object', $ref: 'app-config-schema' },
    sv: { type: 'object', $ref: 'app-config-schema' },
  },
  required: ['en'],
};

export const dashboardReportConfigSchemaValidator = () =>
  new Ajv().addSchema([defaultSelectionsSchema, objectsSchema, appConfigSchema]).compile(DashboardReportConfigSchema);
