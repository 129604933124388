import { UserPermission } from '@config/user';

export interface BusinessPartner {
  id: string;
  name: string;
}

export interface UserPreferences {
  type: string;
  value: string;
}

export type UserRole = 'INTERNAL' | 'CUSTOMER_BASIC' | 'CUSTOMER_ADMIN';
export type UserType = 'INTERNAL' | 'EXTERNAL';

type CompanyName = string;

export interface CustomerService {
  description: string;
  email: string;
  phone: string;
  openingHours: string[];
  callCharge: string;
  countryCode: string;
}

export interface User {
  uuid: string;
  name: string;
  lastname: string;
  email: string;
  phone: string;
  agreementAccepted: boolean;
  preferences: UserPreferences[];
  businessPartners: BusinessPartner[];
  customerService: CustomerService;
  userRole: UserRole;
  permissions: UserPermission[];
  permissionsPerCompany: { [key in CompanyName]: UserPermission[] };
}

export const isUser = (user: unknown): user is User =>
  typeof user === 'object' && user !== null && 'name' in user && 'lastname' in user && 'email' in user;

export type InternalAccess = {
  isInternal: true;
  selectedBusinessPartners: BusinessPartner[];
};
export type UserAccess = { isInternal: false } | InternalAccess;

export interface PasswordChangeUrlResponse {
  ticketUrl: string;
}
