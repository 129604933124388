import { Tile } from '@components/Tile';
import { Namespace } from '@config/i18n';
import { getListItemTestId, getTileTestId } from '@utils/testsHelpers';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InvoicingAddress } from '@components/invoicingDetails';
import { getInvoicingAddressElements } from '@utils/dataOperations/address';
import { InputField } from '@components/InputField';
import { displayedValue, hasValue } from '@utils/dataOperations';
import { VerticalContainerWithSmallGap, VerticalListItemWithPadding } from '@routes/ordersCreation/components';
import { CreationForm } from 'src/types/ordersCreation';
import { InvoicingDetails } from '@models/contract';
import { SimplifiedEvent } from 'src/types/events';

const ORDER_LEVEL_REFERENCE_MAX_LENGTH_CHARS = 20;

interface InvoicingDetailsFormProps {
  form: CreationForm['forms'][number];
  invoicingDetails: InvoicingDetails;
  setOrderLevelReference?: (ev: SimplifiedEvent<string>) => void;
}

export const InvoicingDetailsForm: FC<InvoicingDetailsFormProps> = ({ form, invoicingDetails, setOrderLevelReference }) => {
  const { t } = useTranslation<Namespace[]>(['wasteDetails', 'orderCreation']);

  const addressElements = getInvoicingAddressElements(invoicingDetails.address);

  return (
    <Tile header={t('wasteDetails:invoicingDetails.details')} data-testid={getTileTestId('invoicing-details')}>
      <VerticalContainerWithSmallGap>
        <VerticalListItemWithPadding
          label={t('wasteDetails:invoicingDetails.invoicingAddress')}
          data-testid={getListItemTestId('invoicing-address')}
        >
          <InvoicingAddress addressElements={addressElements} />
        </VerticalListItemWithPadding>

        {hasValue(invoicingDetails.address.eInvoiceAddress) && (
          <VerticalListItemWithPadding
            label={t('wasteDetails:invoicingDetails.eInvoiceAddress')}
            data-testid={getListItemTestId('e-invoice-address')}
          >
            {invoicingDetails.address.eInvoiceAddress}
          </VerticalListItemWithPadding>
        )}

        {hasValue(invoicingDetails.customerReference) && (
          <VerticalListItemWithPadding
            label={t('wasteDetails:invoicingDetails.companyReference')}
            data-testid={getListItemTestId('company-reference')}
          >
            {invoicingDetails.customerReference}
          </VerticalListItemWithPadding>
        )}

        {hasValue(invoicingDetails.contactPersonName) && (
          <VerticalListItemWithPadding
            label={t('wasteDetails:invoicingDetails.invoicingContactPerson')}
            data-testid={getListItemTestId('invoicing-contact-person')}
          >
            {invoicingDetails.contactPersonName}
          </VerticalListItemWithPadding>
        )}

        {setOrderLevelReference ? (
          <InputField
            data-testid="order-level-reference-input-field"
            label={t('orderCreation:orderDetails.orderLevelReference.label', {
              charactersLimit: ORDER_LEVEL_REFERENCE_MAX_LENGTH_CHARS,
            })}
            name={`order-level-reference-${form.formIdentifier}`}
            value={form.values.orderLevelReference}
            onChange={setOrderLevelReference}
            size="m"
            maxLength={ORDER_LEVEL_REFERENCE_MAX_LENGTH_CHARS}
          />
        ) : (
          <VerticalListItemWithPadding
            label={t('wasteDetails:invoicingDetails.orderReference')}
            data-testid={getListItemTestId('order-level-reference')}
          >
            {displayedValue(form.values.orderLevelReference)}
          </VerticalListItemWithPadding>
        )}
      </VerticalContainerWithSmallGap>
    </Tile>
  );
};
