import { ChipItem } from '@fortum/elemental-ui';
import { OrdersFiltersHandlerReturnType } from '../useOrdersFiltersHandler';
import { compact, flatten, uniqBy } from 'lodash';
import { OrdersSelectedFilters, OrdersSelectedFiltersWithSearch } from '@models/filters';
import { displayMultiselectAppliedValues } from '@utils/dataOperations';

const getChipValue = (key: string, selectValue: string) => `${key}-${selectValue}`;

export const deconstructChipValue = (chipValue: string): { key: string; selectValue: string } => {
  const _key = chipValue.split('-')[0];
  const _selectValue = chipValue.substring(_key.length + 1);

  return {
    key: _key,
    selectValue: _selectValue,
  };
};

const getSelectItemName = (
  selectItems: OrdersFiltersHandlerReturnType['selectItems'][keyof OrdersSelectedFilters],
  selectValue: string,
) => selectItems.find(item => item.value === selectValue)?.name;

export class SelectToChipItemMapper {
  constructor(private selectItems: OrdersFiltersHandlerReturnType['selectItems']) {}

  toChipItem(key: keyof OrdersFiltersHandlerReturnType['selectItems'], selectValue: string): ChipItem<string> | undefined {
    const selectItemName = getSelectItemName(this.selectItems[key], selectValue);
    return selectItemName
      ? {
          label: selectItemName,
          value: getChipValue(key, selectValue),
        }
      : undefined;
  }
}

export const mapSelectedFiltersToChips = (
  selectedFiltersValues: OrdersFiltersHandlerReturnType['selectedFiltersValues'],
  selectItems: OrdersFiltersHandlerReturnType['selectItems'],
  sortOrder: (keyof OrdersSelectedFiltersWithSearch)[],
): ChipItem<string>[] => {
  const mapper = new SelectToChipItemMapper(selectItems);

  const mappedChipsItemsArrays = (
    Object.entries(selectedFiltersValues) as [keyof OrdersSelectedFiltersWithSearch, string | string[]][]
  )
    .sort((entry1, entry2) => {
      if (entry1[0] === entry2[0]) {
        return 0;
      }

      return sortOrder.indexOf(entry1[0]) > sortOrder.indexOf(entry2[0]) ? 1 : -1;
    })
    .map(([key, values]) => {
      if (values.length === 0) {
        return [];
      }
      if (key !== 'timePeriod' && key !== 'search' && Array.isArray(values)) {
        return values.map(value => mapper.toChipItem(key, value));
      }

      if (typeof values !== 'string') {
        return [];
      }

      if (key === 'timePeriod') {
        return [mapper.toChipItem(key, values)];
      }

      return key === 'search'
        ? [
            {
              label: values,
              value: key,
            },
          ]
        : [];
    });
  const chipsItems = compact(flatten(mappedChipsItemsArrays));

  return uniqBy(chipsItems, item => item.label);
};

export const isKeyOfSetters = (
  value: string,
  setters: OrdersFiltersHandlerReturnType['setters'],
): value is keyof typeof setters => value in setters;

export const getFilterDisplayValue = (
  selectedFilterValues: OrdersFiltersHandlerReturnType['selectedFiltersValues'][keyof OrdersSelectedFilters],
  selectItems: OrdersFiltersHandlerReturnType['selectItems'][keyof OrdersSelectedFilters],
) => {
  if (typeof selectedFilterValues === 'string') {
    return getSelectItemName(selectItems, selectedFilterValues);
  }

  return selectedFilterValues.length === 1
    ? getSelectItemName(selectItems, selectedFilterValues[0])
    : displayMultiselectAppliedValues(selectedFilterValues);
};
