import { getEnvProperty } from '@utils/general';
import { SupportedLanguage, fallbackLang } from './languages';

// TODO: uncomment when Norwegian translations will be finished

const privacyNoticeURLs: { [key in SupportedLanguage]: string | null } = {
  en: getEnvProperty('REACT_APP_PRIVACY_NOTICE_URL_EN') || null,
  fi: getEnvProperty('REACT_APP_PRIVACY_NOTICE_URL_FI') || null,
  sv: getEnvProperty('REACT_APP_PRIVACY_NOTICE_URL_SV') || null,
  // nb: getEnvProperty('REACT_APP_PRIVACY_NOTICE_URL_NO') || null,
};

export const getPrivacyNoticeURL = (currentLanguage: SupportedLanguage) =>
  privacyNoticeURLs[currentLanguage] ?? privacyNoticeURLs[fallbackLang];
