import { useCallback, useContext, useEffect, useState } from 'react';
import { useQlikLayout } from './useQlikLayout';
import { QlikEngineContext } from '../QlikEngineProvider';
import { isEqual } from 'lodash';

const qProp = { qInfo: { qType: 'SelectionObject' }, qSelectionObjectDef: {} };

export const useCurrentSelections = () => {
  const { enigma } = useContext(QlikEngineContext);
  const { qLayout, isLoading, error } = useQlikLayout(enigma, qProp);
  const [qSelections, setQSelections] = useState<enigmaJS.QSelection[]>([]);

  const clearSelections = useCallback(
    async (field?: string) => {
      if (!enigma) {
        return;
      }
      if (field) {
        const qField = await enigma.getField(field);

        await qField?.clear();
      } else {
        await enigma.clearAll();
      }
    },
    [enigma],
  );

  useEffect(() => {
    if (!qLayout?.qSelectionObject || isEqual(qSelections, qLayout.qSelectionObject.qSelections)) return;

    setQSelections(qLayout.qSelectionObject.qSelections);
  }, [qLayout?.qSelectionObject?.qSelections]);

  return { qSelections, clearSelections, isLoading, isError: !!error };
};
