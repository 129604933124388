import { Box, colors, ContentText, fontSizes, IconDistrictHeatingOutage, Link, spacing } from '@fortum/elemental-ui';
import { useNavigate } from 'react-router-dom';
import { paths } from '@config/routes';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';

export const FailedToLoad = () => {
  const navigate = useNavigate();
  const { t } = useTranslation<Namespace>('errors');

  return (
    <Box display="flex" flex={1} flexDirection="column" alignItems="center" paddingTop="246px">
      <Box display="flex" flexDirection="column" maxWidth="392px" alignItems="center" gap={spacing.xxxs}>
        <IconDistrictHeatingOutage paddingBottom={spacing.xxxs} color={colors.hazeRedOrange} />
        <ContentText textAlign="center" fontSize="41px" color={colors.inkGrey}>
          {t('failedToLoad')}
        </ContentText>
        <ContentText textAlign="center" fontSize={fontSizes.l} color={colors.inkGrey}>
          {t('tryReload')}
          <Link noUnderline marginLeft={spacing.xxxxs} onClick={() => navigate(paths.contactUs)}>
            {t('contactUs')}
          </Link>
        </ContentText>
      </Box>
    </Box>
  );
};
