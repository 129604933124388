import { FC, useState } from 'react';
import { Container, NOTIFICATION_MAX_WIDTH_REM, TilesContainer } from './styles';
import { PageHeader } from '@components/PageHeader';
import { Notification, useNotification } from '@components/Notification';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { ChangeTemporaryOrderNoTile } from './ChangeTemporaryOrderNoTile';
import { DeleteTemporaryOrderTile } from './DeleteTemporaryOrderTile';

export const OrdersManagement: FC = () => {
  const { t } = useTranslation<Namespace[]>(['orderCreation', 'errors', 'orderDetails', 'common']);

  const [notificationErrorMessage, setNotificationErrorMessage] = useState<string>();
  const [notificationSuccessMessage, setNotificationSuccessMessage] = useState<string>();

  const { displayNotification, setDisplayErrorNotification, setDisplaySuccessfulNotification, closeNotification } =
    useNotification();

  return (
    <>
      <Container>
        <PageHeader header={t('orderCreation:ordersManagement.header')} />

        <TilesContainer>
          <ChangeTemporaryOrderNoTile
            setDisplayErrorNotification={setDisplayErrorNotification}
            setDisplaySuccessfulNotification={setDisplaySuccessfulNotification}
            setNotificationErrorMessage={setNotificationErrorMessage}
            setNotificationSuccessMessage={setNotificationSuccessMessage}
          />

          <DeleteTemporaryOrderTile
            setDisplayErrorNotification={setDisplayErrorNotification}
            setDisplaySuccessfulNotification={setDisplaySuccessfulNotification}
            setNotificationErrorMessage={setNotificationErrorMessage}
            setNotificationSuccessMessage={setNotificationSuccessMessage}
          />
        </TilesContainer>
      </Container>

      <Notification
        type="error"
        data-testid="orders-management-error-notification"
        content={notificationErrorMessage}
        opened={displayNotification === 'error'}
        onClose={closeNotification}
        maxWidth={NOTIFICATION_MAX_WIDTH_REM}
      />

      <Notification
        type="success"
        data-testid="orders-management-success-notification"
        content={notificationSuccessMessage}
        opened={displayNotification === 'success'}
        onClose={closeNotification}
        maxWidth={NOTIFICATION_MAX_WIDTH_REM}
      />
    </>
  );
};
