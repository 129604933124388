import { RowKey, TableColumnProps } from '@fortum/elemental-ui';
import { UserProfile } from '@models/usersManagement';
import { t } from 'i18next';

type ColumnProps = Pick<TableColumnProps<UserProfile, RowKey>, 'key' | 'name' | 'sortable'>;
type UsersProfilesTableKeys = keyof Omit<UserProfile, 'uuid' | 'lastName' | 'userRole'> | 'userType';

export const getColumnsBaseConfiguration = (): {
  [key in UsersProfilesTableKeys]: ColumnProps;
} => ({
  name: {
    key: 'userName',
    name: t('usersManagement:usersList.name.header'),
  },
  companies: {
    key: 'companies',
    name: t('usersManagement:usersList.company.header'),
  },
  email: {
    key: 'email',
    name: t('usersManagement:usersList.email.header'),
  },
  phone: {
    key: 'phone',
    name: t('usersManagement:usersList.phone.header'),
  },
  userType: {
    key: 'userType',
    name: t('usersManagement:usersList.userType.header'),
  },
});
