import { languages, SupportedLanguage } from '@common/languages';
import { Namespace, onLanguageChange } from '@config/i18n';
import { paths } from '@config/routes';
import { Box, ContentText } from '@fortum/elemental-ui';
import { User } from '@models/user';
import { getSelectedBusinessPartners, isUserInternal } from '@utils/user';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import {
  bpsBoxStyles,
  bpsTitleStyles,
  FortumLogoWitHover,
  Header,
  headerTitleStyles,
  LeftHeaderSection,
  logoStyles,
  RightHeaderSection,
  selectedBpsTextStyles,
  SelectedPartnersText,
  separatorStyles,
} from './AppHeader.styles';
import { AuthWidget, AuthWidgetProps } from './AuthWidget';
import { LanguageWidget } from './LanguageWidget';

type AppHeaderProps = {
  user: User | undefined;
} & Pick<AuthWidgetProps, 'logout'>;

export const AppHeader: FC<AppHeaderProps> = ({ user, logout }) => {
  const { t, i18n } = useTranslation<Namespace[]>(['common', 'mainLayout']);
  const navigate = useNavigate();

  const navigateToHome = () => location.pathname !== paths.main && navigate(paths.main);
  const navigateToSelectCustomer = () => navigate(paths.selectCustomer);

  const selectedBusinessPartners = getSelectedBusinessPartners().map(bp => bp.name);
  const showSelectedBusinessPartners = isUserInternal(user?.userRole) && selectedBusinessPartners.length > 0;

  return (
    <Header>
      <LeftHeaderSection>
        <FortumLogoWitHover {...logoStyles} onClick={navigateToHome} />
        <ContentText {...separatorStyles}>|</ContentText>
        <ContentText {...headerTitleStyles}>{t('common:appName')}</ContentText>
      </LeftHeaderSection>

      <RightHeaderSection>
        {showSelectedBusinessPartners && (
          <Box {...bpsBoxStyles}>
            <ContentText {...bpsTitleStyles}>
              {t('mainLayout:selectedBusinessPartners', { count: selectedBusinessPartners.length })}
            </ContentText>
            <SelectedPartnersText {...selectedBpsTextStyles} onClick={navigateToSelectCustomer}>
              {selectedBusinessPartners.join(',')}
            </SelectedPartnersText>
          </Box>
        )}

        <LanguageWidget
          languages={languages as unknown as SupportedLanguage[]}
          onLanguageChange={onLanguageChange}
          selected={i18n.language as SupportedLanguage}
        />
        <AuthWidget user={user} logout={logout} />
      </RightHeaderSection>
    </Header>
  );
};
