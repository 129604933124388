import { useRef, useEffect, useState, useContext, useCallback, useMemo, FC } from 'react';
import { QlikAppContext } from '../../QlikAppProvider';
import { VisualizationWrapper } from './styles';
import { colors, spacing } from '@fortum/elemental-ui';
import { QlikVisualization } from '@components/qlik/types';
import { Visualization } from './QlikVisualization';
import { Stylable } from '@components/types';

export interface QlikObjectProps extends Stylable {
  id: string;
  noInteraction?: boolean;
  noSelections?: boolean;
  height?: string;
  width?: string;
  minHeight?: string;
  minWidth?: string;
  maxWidth?: string;
  padding?: string;
  backgroundColor?: string;
  dataTestId?: string;
}

export const QlikObject: FC<QlikObjectProps> = ({
  id,
  noSelections = false,
  noInteraction = false,
  height = '100%',
  width = '100%',
  minHeight = 'auto',
  minWidth = 'auto',
  maxWidth = 'auto',
  padding = spacing.xs,
  backgroundColor = colors.snowWhite,
  className,
  dataTestId,
}) => {
  const visualizationRef = useRef<HTMLDivElement>(null);
  const [error, setError] = useState<boolean>(false);

  const { app } = useContext(QlikAppContext);

  const [qVisualization, setQVisualization] = useState<QlikVisualization | null>(null);

  const create = useCallback(async () => {
    if (!app?.visualization) {
      return;
    }
    const _qViz = await app.visualization.get(id);
    setQVisualization(_qViz);
  }, [app, id]);

  const { show, close, resize } = useMemo(
    () => ({
      show: () => visualizationRef.current && qVisualization?.show(visualizationRef.current, { noSelections, noInteraction }),
      close: () => qVisualization?.close(),
      resize: () => qVisualization?.resize(),
    }),
    [qVisualization, visualizationRef.current, noSelections, noInteraction],
  );

  useEffect(() => {
    if (app) {
      const handleChange = async () => {
        setError(false);

        if (!qVisualization || qVisualization.qapp.id !== app.id) await create();
        if (qVisualization) show();
        window.addEventListener('resize', resize);
      };
      handleChange().catch(() => {
        setError(true);
      });

      return () => {
        if (qVisualization) close();
        window.removeEventListener('resize', resize);
      };
    }
  }, [qVisualization, app, resize]);

  return (
    <VisualizationWrapper
      className={className}
      $height={height}
      $minHeight={minHeight}
      $width={width}
      $minWidth={minWidth}
      $maxWidth={maxWidth}
      $padding={padding}
      $backgroundColor={backgroundColor}
      data-testid={dataTestId}
    >
      <Visualization error={error} app={app} visualizationRef={visualizationRef} />
    </VisualizationWrapper>
  );
};
