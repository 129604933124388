import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, ContentText, colors, fontSizes, px2rem, spacing } from '@fortum/elemental-ui';
import { Namespace } from 'i18next';
import { useTranslation } from 'react-i18next';
import { paths } from '@config/routes';

export const OrganisationsSelectorNavigation = () => {
  const { t } = useTranslation<Namespace>('user');
  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      backgroundColor={colors.lightGrey}
      padding={spacing.xxs}
      marginBottom="20px"
      maxWidth={px2rem(598)}
      alignItems="center"
    >
      <ContentText fontSize={fontSizes.s} width={'339px'}>
        {t('selectOrganisations.message')}
      </ContentText>
      <Button
        status="secondary"
        variant="condensed"
        backgroundColor={colors.lightGrey}
        onClick={() => navigate(paths.selectCustomer)}
      >
        {t('selectOrganisations.button')}
      </Button>
    </Box>
  );
};
