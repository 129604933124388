import { useServiceDetails } from '@data/hooks/useServiceDetailas';
import { CenteredLoader } from '@components/layout/styles';
import { ContractsTable } from '@routes/services/components/table/Table';
import { Box, colors, fontSizes, fontWeights, spacing, Tabs } from '@fortum/elemental-ui';
import { StyledContentText } from '@routes/services/components/location/styles';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TreeDetails } from '../menu/types';
import { FailedToLoad } from '@components/layout/FailedToLoad';
import { Namespace } from '@config/i18n';
import { useSearchParams } from 'react-router-dom';
import { SEARCH_PARAMS } from '@config/routes';
import { ContractTypeTab, mapToSortedTabItems } from '@utils/servicesOverview';
import { getActiveTab } from '@utils/servicesOverview';
import { FilteringResult } from '@components/layout/FilteringResult';
import { ContractFiltersKeys } from 'src/types/services';
import { serviceTabsOrder } from '@config/services';

interface ContractLayoutProps {
  location: TreeDetails;
  filters: ContractFiltersKeys;
}

export const ContractLayout: FC<ContractLayoutProps> = memo(({ location, filters }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState<string>('');
  const { i18n } = useTranslation<Namespace>('domain');
  const { t } = useTranslation<Namespace>('services');
  const { data: serviceDetails, fetchStatus, isLoading, error } = useServiceDetails(location.locationCode, filters);

  const setActiveTabInSearchParams = (tabId: string) => {
    searchParams.set(SEARCH_PARAMS.serviceTabId, tabId);
    setSearchParams(searchParams);
  };

  const tabItems = useMemo<ContractTypeTab[] | undefined>(() => {
    if (!serviceDetails?.services || serviceDetails?.services.length === 0) return;

    const items = mapToSortedTabItems(serviceDetails.services, serviceTabsOrder);

    const activeTabFromSearchParams = searchParams.get(SEARCH_PARAMS.serviceTabId);

    setActiveTab(getActiveTab(items, activeTabFromSearchParams, activeTab));

    return items;
  }, [serviceDetails?.services, i18n.language]);

  useEffect(() => {
    setActiveTabInSearchParams(activeTab);
  }, [activeTab, tabItems]);

  const serviceTabSections = useMemo(() => {
    if (!tabItems) return;

    return (
      <>
        {tabItems.map((tabItem, index) => {
          return (
            <section
              key={`${tabItem.name}${index}`}
              aria-labelledby="lock-tab"
              hidden={activeTab !== tabItem.id}
              id={tabItem.type}
              role="tabpanel"
            >
              {tabItem.service && <ContractsTable service={tabItem.service} />}
            </section>
          );
        })}
      </>
    );
  }, [tabItems, activeTab]);

  const onTabChange = useCallback((tabId: string) => {
    setActiveTab(tabId);
    setActiveTabInSearchParams(tabId);
  }, []);

  if ((isLoading || fetchStatus !== 'idle') && !serviceDetails) return <CenteredLoader />;
  else if (error) return <FailedToLoad />;
  else if (!serviceDetails) return <FilteringResult message={t('selectLocationOrFilter')} />;

  return (
    <Box pv={spacing.xs} pl={spacing.s} pr={spacing.xs}>
      <StyledContentText fontWeight={fontWeights.medium} fontSize={fontSizes.s} color={colors.inkGrey}>
        {location.companyName.toUpperCase()}
      </StyledContentText>

      <>
        <Box display="flex" flex="1">
          <StyledContentText fontWeight={fontWeights.default} fontSize={fontSizes.m} color={colors.inkGrey}>
            {location.address}
          </StyledContentText>
          {!location.address.endsWith(location.streetAddress) && (
            <StyledContentText fontWeight={fontWeights.default} fontSize={fontSizes.m} color={colors.inkGrey}>
              {location.streetAddress}
            </StyledContentText>
          )}
        </Box>

        {tabItems && tabItems.length > 0 && (
          <Tabs
            variant={'condensed'}
            items={tabItems}
            active={activeTab}
            onTabChange={onTabChange}
            mb={spacing.xxs}
            pt={spacing.xs}
            withBorder
          />
        )}
        {serviceTabSections}
      </>
    </Box>
  );
});

ContractLayout.displayName = 'ContractLayout';
