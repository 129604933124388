import { borderTypeToColorMap, staticBorderStyles, horizontalContainer, verticalContainer } from '@components/styles';
import { ButtonProps, ContentTextProps, colors, fontSizes, spacing } from '@fortum/elemental-ui';
import styled from 'styled-components';

export const Container = styled.div<{ $error: boolean }>`
  ${verticalContainer};
  ${staticBorderStyles('2px')};

  border-color: ${({ $error }) => borderTypeToColorMap[$error ? 'error' : 'inactive']};

  padding: ${spacing.xs} ${spacing.xs} 48px;
  gap: ${spacing.xs};
  &:hover {
    border-color: ${({ $error }) => borderTypeToColorMap[$error ? 'error' : 'active']};
  }
  &:focus-within {
    border-color: ${borderTypeToColorMap['active']};
  }

  transition: border-color 300ms;
  transition-timing-function: cubic-bezier(0.5, 0, 0, 1);
`;

export const Header = styled.div`
  ${horizontalContainer};

  height: 48px;
  box-sizing: border-box;

  justify-content: space-between;
  align-items: start;
  border-bottom: 1px solid ${colors.fogGrey};

  &:hover {
    cursor: pointer;
  }
`;

export const FormContainer = styled.div`
  ${verticalContainer};

  gap: ${spacing.xs};
`;

export const RequiredFormFieldsContainer = styled.div`
  --input-fields-gap: ${spacing.s};

  ${horizontalContainer};

  flex-wrap: wrap;

  gap: var(--input-fields-gap);

  > div {
    max-width: calc((100% - var(--input-fields-gap)) / 2);
  }
`;

export const ButtonsContainer = styled.div`
  ${horizontalContainer};

  justify-content: space-between;
  align-items: center;
`;

export const SubmitActionButtonsContainer = styled.div`
  ${horizontalContainer};

  gap: ${spacing.xxxs};
`;

export const formDescriptionStyles: ContentTextProps = {
  fontSize: fontSizes.s,
};

export const OrderTypeContainer = styled.div`
  ${horizontalContainer};

  gap: ${spacing.xxxs};
  padding: 0 ${spacing.xxs};
  align-items: center;
`;

export const plainButtonStyles: ButtonProps = {
  variant: 'condensed',
  padding: 0,
  status: 'plain',
  fontWeight: 500,
  fontSize: fontSizes.m,
};

export const discardButtonStyles: ButtonProps = {
  status: 'plain',
  variant: 'condensed',
  color: colors.powerRed,
};
