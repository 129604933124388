import { Co2ReportObjects } from '@config/qlik';
import { FC } from 'react';
import { QlikObject } from '@components/qlik';
import { ContentText } from '@fortum/elemental-ui';
import { SectionHorizontalSubContainer, Section, sectionTitleContentTextProps } from './styles';
import { Namespace } from '@config/i18n';
import { useTranslation } from 'react-i18next';

interface EmissionOverviewProps {
  objectIds: Co2ReportObjects;
}

const VISUALIZATION_PADDING = '8px';
const VISUALIZATION_HEIGHT = '150px';

export const EmissionOverview: FC<EmissionOverviewProps> = ({ objectIds }) => {
  const { t } = useTranslation<Namespace>('reports');

  return (
    <Section data-testid="emission-overview">
      <ContentText {...sectionTitleContentTextProps}>{t('co2.emissionOverview.header')}</ContentText>

      <SectionHorizontalSubContainer>
        <QlikObject
          id={objectIds.kpis.co2eEmissions}
          dataTestId="kpi-co2e-emissions"
          padding={VISUALIZATION_PADDING}
          height={VISUALIZATION_HEIGHT}
        />
        <QlikObject
          padding={VISUALIZATION_PADDING}
          id={objectIds.kpis.avoidedCo2eEmissions}
          dataTestId="kpi-avoided-co2e-emissions"
          height={VISUALIZATION_HEIGHT}
        />

        <QlikObject
          id={objectIds.kpis.netCo2eEmissions}
          padding={VISUALIZATION_PADDING}
          dataTestId="kpi-net-co2e-emissions"
          height={VISUALIZATION_HEIGHT}
        />
      </SectionHorizontalSubContainer>

      <QlikObject
        padding={VISUALIZATION_PADDING}
        id={objectIds.charts.top3NetCo2eProducingWaste}
        dataTestId="kpi-largest-co2-waste-producing"
        height={VISUALIZATION_HEIGHT}
      />
    </Section>
  );
};
