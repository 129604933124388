import { FC, ReactNode } from 'react';
import { ContentText, IconContactByEmail, Loader } from '@fortum/elemental-ui';
import { PendingRequestViewContainer, iconStyles, headingStyle, MessageWrapper, loaderStyles } from './styles';
import { Stylable, Testable } from '@components/types';

interface PendingRequestViewProps extends Stylable, Testable {
  header: string;
  message: ReactNode;
}

export const PendingRequestNote: FC<PendingRequestViewProps> = ({ header, message, 'data-testid': dataTestId, className }) => (
  <PendingRequestViewContainer className={className} data-testid={dataTestId}>
    <IconContactByEmail {...iconStyles} />

    <ContentText {...headingStyle}>{header}</ContentText>
    <MessageWrapper>{message}</MessageWrapper>

    <Loader {...loaderStyles} />
  </PendingRequestViewContainer>
);
