import { Namespace } from '@config/i18n';
import { paths } from '@config/routes';
import { IconTruckOutline } from '@fortum/elemental-ui';
import { User } from '@models/user';
import { isOrdersCreationToggleOn } from '@utils/featureToggles';
import { FC, BaseSyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { SubNavigation, NavItem } from './NavItem';

interface OrdersSubNavigationProps {
  onItemClick: (e: BaseSyntheticEvent) => void;
  user: User | undefined;
  disabled?: boolean;
}

const ordersSubnavigationPaths = [paths.ordersTracking, paths.ordersCreation];

export const OrdersSubNavigation: FC<OrdersSubNavigationProps> = ({ disabled, user, onItemClick }) => {
  const { t } = useTranslation<Namespace>('mainLayout');

  return !!user && isOrdersCreationToggleOn(user) ? (
    <SubNavigation description={t('orders')} disabled={disabled} subnavigationPaths={ordersSubnavigationPaths}>
      <NavItem
        data-testid="orders-nav-item"
        to={paths.ordersTracking}
        onClick={onItemClick}
        description={t('orderTracking')}
        disabled={disabled}
        subNavItem={true}
      />
      <NavItem
        data-testid="orders-creation-nav-item"
        to={paths.ordersCreation}
        onClick={onItemClick}
        description={t('createOrder')}
        disabled={disabled}
        subNavItem={true}
      />
    </SubNavigation>
  ) : (
    <NavItem
      data-testid="orders-nav-item"
      to={paths.ordersTracking}
      onClick={onItemClick}
      disabled={disabled}
      icon={<IconTruckOutline />}
      description={t('orders')}
    />
  );
};
