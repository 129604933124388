import { Box, ContentText, fontSizes } from '@fortum/elemental-ui';
import { FC } from 'react';
import { NO_DATA_BADGE } from '@utils/dataOperations';
import { getHPCodeDisplayableDescription } from '@utils/dataOperations/wasteDetails';
import { Code } from '@models/common';
import { Testable } from '@components/types';

interface HPCodesProps extends Testable {
  hpCodes: Code[] | null;
}

export const HPCodes: FC<HPCodesProps> = ({ hpCodes, 'data-testid': dataTestId }) =>
  !hpCodes?.length ? (
    NO_DATA_BADGE
  ) : (
    <Box display="flex" flexDirection="column" data-testid={dataTestId}>
      {hpCodes
        .map(hpCode => getHPCodeDisplayableDescription(hpCode.code))
        .map((description, index) => (
          <ContentText key={index} fontSize={fontSizes.s}>
            {description}
          </ContentText>
        ))}
    </Box>
  );
