import { Col, ColProps, fontSizes } from '@fortum/elemental-ui';
import { FC } from 'react';
import { WordBreakingContentText } from './style';
import { Testable } from '@components/types';

interface ValueCellProps extends Testable {
  text: string;
  gridColumnProps: ColProps;
}

export const ValueCell: FC<ValueCellProps> = ({ text, gridColumnProps, 'data-testid': testId }) => (
  <Col ph={{ xs: 0 }} {...gridColumnProps} data-testid={testId}>
    <WordBreakingContentText fontSize={fontSizes.s}>{text}</WordBreakingContentText>
  </Col>
);
