import { DEBOUNCE_TIME_MS, debounceOptions as defaultDebounceOptions } from '@config/inputs';
import { useState, useEffect, useCallback } from 'react';
import { SimplifiedEvent } from 'src/types/events';
import { useDebounceCallback } from 'usehooks-ts';

export const useDebounceWithTemporaryValue = <T>(
  value: T,
  handleValueChange: (value: T) => void,
  debounceTimeMs = DEBOUNCE_TIME_MS,
  debounceOptions: Parameters<typeof useDebounceCallback>[2] = defaultDebounceOptions,
) => {
  const [temporaryValue, setTemporaryValue] = useState<T>(value);

  useEffect(() => {
    setTemporaryValue(value);
  }, [value]);

  const debouncedHandleValueChange = useDebounceCallback(handleValueChange, debounceTimeMs, debounceOptions);

  const changeTemporaryValue = useCallback((event: SimplifiedEvent<T>) => {
    const newValue = event.target.value;

    setTemporaryValue(newValue);

    debouncedHandleValueChange(newValue);
  }, []);

  return {
    changeTemporaryValue,
    temporaryValue,
  };
};
