import { verticalContainer } from '@components/styles';
import { BoxProps, ButtonProps, CommonSelectProps, ContentTextProps, fontSizes, Select, spacing } from '@fortum/elemental-ui';
import styled from 'styled-components';
import { FILTER_MAX_WIDTH, FILTER_MIN_WIDTH, FILTERS_GAP } from '../Filters/styles';

export const ContentContainer = styled.div`
  ${verticalContainer};

  padding: ${spacing.xs} ${spacing.xs} ${spacing.xxs};
  gap: ${spacing.xxs};
`;

/**
 * It should have same width as filters in MainFiltersLayout
 */
export const StyledSelect: typeof Select = styled(Select)`
  width: calc((100% - 3 * ${FILTERS_GAP}) / 4);
  min-width: ${FILTER_MIN_WIDTH};
  max-width: ${FILTER_MAX_WIDTH};
`;

export const selectStyles: Omit<CommonSelectProps<string>, 'items'> = {
  borderStyle: 'full',
  variant: 'condensed',
};

export const boxStyles: BoxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const titleStyles: ContentTextProps = {
  fontSize: fontSizes.xl,
};

export const buttonStyles: ButtonProps = {
  variant: 'condensed',
  status: 'plain',
};
