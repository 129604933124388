import { HorizontalContainer, VerticalContainer, verticalContainer } from '@components/styles';
import { ContentTextProps, breakpoints, fontSizes, spacing } from '@fortum/elemental-ui';
import styled from 'styled-components';

export const ReportContainer = styled(VerticalContainer)`
  --objects-vertical-gap: ${spacing.xxxs};
  --objects-horizontal-gap: ${spacing.xxxs};
  gap: ${spacing.xs};
  padding: 0 ${spacing.xs} ${spacing.xs};
`;

export const FiltersContainer = styled(HorizontalContainer)`
  gap: var(--objects-horizontal-gap);
  flex-wrap: wrap;
  flex: 1;
`;

export const Section = styled.section`
  ${verticalContainer()};
  gap: var(--objects-vertical-gap);
`;

export const SectionHorizontalSubContainer = styled(HorizontalContainer)`
  gap: var(--objects-horizontal-gap);

  @media ${`(max-width: ${breakpoints.xl}px)`} {
    flex-wrap: wrap;
  }
`;

export const sectionTitleContentTextProps: ContentTextProps = {
  fontSize: fontSizes.l,
};

export const SectionHeaderContainer = styled(HorizontalContainer)`
  justify-content: space-between;
  align-items: center;
`;

export const TableWithDimensionsContainer = styled(HorizontalContainer)`
  gap: var(--objects-horizontal-gap);
`;

export const DimensionsContainer = styled(VerticalContainer)`
  gap: var(--objects-vertical-gap);
`;
