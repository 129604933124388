import { HttpError } from '@common/http';
import { UserMessage } from '@models/userMessage';
import { getAuthHeaders } from '@utils/data';
import { getUserAccessInfo, isUserAccessOfInternal } from '@utils/user';
import { Headers as HeadersConstants, MimeTypes } from 'http-constants-ts';
import { startCase } from 'lodash';
import { urls } from './config';

export const sendMessageToCustomerService = async (message: UserMessage, language: string) => {
  const headers = getAuthHeaders();
  headers.append(HeadersConstants.CONTENT_TYPE, MimeTypes.APPLICATION_JSON);

  const userAccess = getUserAccessInfo();
  const businessPartnerCodes = isUserAccessOfInternal(userAccess) ? userAccess.selectedBusinessPartners.map(bp => bp.id) : [];

  if (!urls.customerServiceContact) throw Error('Customer service contact URL not provided');

  const { reason, ...rest } = message;

  const response = await fetch(urls.customerServiceContact, {
    method: 'POST',
    mode: 'cors',
    headers,
    body: JSON.stringify({
      ...rest,
      reason: reason ? startCase(reason) : null,
      businessPartnerCodes,
      language,
    }),
  });

  if (!response.ok) throw new HttpError(response.status, response.statusText);
};
