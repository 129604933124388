import { logException } from '@config/azureInsights';

export const getEnvProperty = (key: string): string => {
  const value = process.env[key];

  if (value === undefined) logException(new Error(`Missing environment variable: ${key}`));

  return value || '';
};

export const scrollIntoView = (element?: Element | null) => element?.scrollIntoView({ block: 'end', behavior: 'smooth' });
