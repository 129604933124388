import { CenteredLoader } from '@components/layout/styles';
import { useUserInfo } from '@data/hooks/useUserInfo';
import { User } from '@models/user';
import { ReactNode, FC } from 'react';
import PageNotFound from './errors/not-found/pageNotFound';

interface RestrictedRouteProps {
  validateAccess: (user: User) => boolean;
  children: ReactNode;
}

export const RestrictedRoute: FC<RestrictedRouteProps> = ({ validateAccess, children }) => {
  const { data: user, isLoading } = useUserInfo();

  if (isLoading) {
    return <CenteredLoader />;
  }

  if (!user) {
    throw Error('User not found');
  }

  if (validateAccess(user)) {
    return <>{children}</>;
  }

  return <PageNotFound />;
};
