import { getEnvProperty } from '@utils/general';

const reactAppApiUrl = getEnvProperty('REACT_APP_API_URL');
const baseUrl = reactAppApiUrl ? `${reactAppApiUrl}/rwportal-contracts/v5` : undefined;

export const ContractService = {
  services: baseUrl ? `${baseUrl}/contracts/count/own` : null,
  servicesAdmin: baseUrl ? `${baseUrl}/contracts/count` : null,
  servicesDetails: baseUrl ? `${baseUrl}/contracts/by-location` : null,
  contractDetails: baseUrl ? `${baseUrl}/contracts` : null,
  servicesFilters: baseUrl ? `${baseUrl}/contracts/filters/own` : null,
  servicesFiltersAdmin: baseUrl ? `${baseUrl}/contracts/filters` : null,
  locations: baseUrl ? `${baseUrl}/contracts/locations/own` : null,
  locationsAdmin: baseUrl ? `${baseUrl}/contracts/locations` : null,
  contractsSites: baseUrl ? `${baseUrl}/contracts/sites/own` : null,
  contractsSitesAdmin: baseUrl ? `${baseUrl}/contracts/sites` : null,
  contractsByLocations: baseUrl ? `${baseUrl}/contracts/by-locations` : null,
};
