import { getEnvProperty } from '@utils/general';
import { AnalyticsService } from './analytics';
import { ContractService } from './contracts';
import { OrderService } from './orders';
import { UserService } from './users';

export const urls = {
  ...AnalyticsService,
  ...ContractService,
  ...OrderService,
  ...UserService,
  termsAndConditions: getEnvProperty('REACT_APP_TERMS_AND_CONDITIONS_URL') ?? null,
};
