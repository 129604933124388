import { ServiceTypeValue } from '@models/service';

type ServiceType = 'wasteCollection' | 'wasteReceiving' | 'equipmentSalesService';

export const serviceTypes: {
  [key in ServiceType]: ServiceTypeValue;
} = {
  wasteCollection: 'Waste collection service',
  wasteReceiving: 'Waste receiving service',
  equipmentSalesService: 'Equipment sales service',
};

export const serviceTabsOrder: ServiceTypeValue[] = [
  serviceTypes.wasteCollection,
  serviceTypes.wasteReceiving,
  serviceTypes.equipmentSalesService,
];
