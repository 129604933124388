import { displayedValue, hasValue } from '@utils/dataOperations';
import { NO_DATA_BADGE } from './consts';
import { compact, uniq } from 'lodash';
import { splitAndTrim } from '@utils/strings';
import { InvoicingDetails } from '@models/contract';

export const splitStreetAddressOnTheZipCode = (streetAddress: string | null | undefined): string[] => {
  if (!hasValue(streetAddress)) return [NO_DATA_BADGE];

  const streetAddressElements = splitAndTrim(streetAddress, ',');
  const zipCode = streetAddressElements.pop();

  return compact([streetAddressElements.join(', '), zipCode]);
};

export const getInvoicingAddressElements = ({
  company,
  specificName,
  streetAddress,
  invoicingEmail,
}: InvoicingDetails['address']) =>
  uniq(
    compact([
      displayedValue(company),
      hasValue(specificName) ? specificName : undefined,
      ...splitStreetAddressOnTheZipCode(streetAddress),
      hasValue(invoicingEmail) ? invoicingEmail : undefined,
    ]),
  );
