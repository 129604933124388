import { QueryKeys } from '@common/query';
import { sessionKeys, StorageUtils } from '@common/storage';
import { fetchUserInfo } from '@data/api/user';
import { User } from '@models/user';
import { useQuery } from '@tanstack/react-query';
import { shouldRetry } from '@utils/data';
import { QueryOptions } from './types';

export const getUserInfoQueryOptions = (): QueryOptions<User> => ({
  queryKey: [QueryKeys.userInfo],
  queryFn: fetchUserInfo,
  enabled: StorageUtils.hasItem(sessionKeys.accessToken),
  gcTime: Infinity,
  staleTime: Infinity,
  refetchOnWindowFocus: false,
  retry: shouldRetry,
});

export const useUserInfo = () => useQuery<User, Error>(getUserInfoQueryOptions());
