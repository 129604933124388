import { QlikRootApiConfig } from '@components/qlik/types';
import { getQlikBaseURL } from '@utils/qlik';
import { REQUIRE_JS_RELATIVE_PATH, QLIK_STYLES_RELATIVE_PATH } from './urls';

const fetchCapabilityApisJS = (config: QlikRootApiConfig): Promise<void> =>
  new Promise(resolve => {
    const capabilityApisJS = document.createElement('script');

    capabilityApisJS.src = `${getQlikBaseURL(config)}${REQUIRE_JS_RELATIVE_PATH}`;

    document.head.appendChild(capabilityApisJS);

    capabilityApisJS.onload = () => {
      resolve();
    };
  });

const fetchCapabilityApisCSS = (config: QlikRootApiConfig): Promise<void> =>
  new Promise(resolve => {
    const capabilityApisCSS = document.createElement('link');

    capabilityApisCSS.href = `${getQlikBaseURL(config)}${QLIK_STYLES_RELATIVE_PATH}`;
    capabilityApisCSS.type = 'text/css';
    capabilityApisCSS.rel = 'stylesheet';

    document.head.appendChild(capabilityApisCSS);
    capabilityApisCSS.onload = () => {
      resolve();
    };
  });

export const loadCapabilityApis = async (config: QlikRootApiConfig) =>
  await Promise.all([fetchCapabilityApisJS(config), fetchCapabilityApisCSS(config)]);
