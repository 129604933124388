import { FiltersAccordion } from '@components/FiltersAccordion';
import { PageHeader } from '@components/PageHeader';
import { verticalContainer } from '@components/styles';

import { spacing, colors } from '@fortum/elemental-ui';
import styled from 'styled-components';

export const Container = styled.div`
  ${verticalContainer};
  min-height: var(--page-header-height);
  box-sizing: border-box;
  z-index: 9;
  position: sticky;
  top: var(--app-header-height);
  background-color: ${colors.lightGrey};
  align-items: center;
  padding: 0px ${spacing.xs};
`;

export const StyledCommonHeader = styled(PageHeader)`
  justify-content: space-between;
  padding-left: 0px;
  padding-right: 0px;
`;

export const StyledFiltersAccordion: typeof FiltersAccordion = styled(FiltersAccordion)`
  margin-bottom: ${spacing.xs};
`;
