import { FC, ReactNode, createContext } from 'react';
import { useOrdersCreation } from './useOrdersCreation';

type OrdersCreationContextProps = ReturnType<typeof useOrdersCreation>;

export const OrdersCreationContext = createContext<OrdersCreationContextProps>({
  creationForms: [],
  setValue: () => undefined,
  initializeNewOrderForm: () => undefined,
  createEmptyCreationForm: () => undefined,
  setInitialOrderFormWithContractDetails: () => undefined,
  countMissingRequiredFields: () => 0,
  clearForms: () => undefined,
  deleteOrder: () => undefined,
  discardService: () => undefined,
});

interface OrdersCreationContextProviderProps {
  children: ReactNode;
}

export const OrdersCreationContextProvider: FC<OrdersCreationContextProviderProps> = ({ children }) => {
  const creationHandler = useOrdersCreation();

  const contextValue: OrdersCreationContextProps = {
    ...creationHandler,
  };

  return <OrdersCreationContext.Provider value={contextValue}>{children}</OrdersCreationContext.Provider>;
};
