import { PasswordChangeUrlResponse, User } from '@models/user';
import { getData, getAuthHeaders, postData } from '@utils/data';
import { urls } from './config';
import { Headers as HeadersConstants, MimeTypes } from 'http-constants-ts';
import { HttpError } from '@common/http';
import { Contact } from '@models/contact';

export const fetchUserInfo = async (): Promise<User> => {
  const contentTypeHeader = new Map([[HeadersConstants.CONTENT_TYPE, MimeTypes.APPLICATION_JSON]]);
  if (!urls.userInfo) throw Error('User info URL not provided');

  return getData<User>(urls.userInfo, contentTypeHeader);
};

export const updateUserAgreement = async (): Promise<void> => {
  const headers = getAuthHeaders();
  headers.append(HeadersConstants.CONTENT_TYPE, MimeTypes.APPLICATION_JSON);

  if (!urls.userAgreement) throw Error('User agreement URL not provided');

  const response = await fetch(urls.userAgreement, {
    method: 'PUT',
    mode: 'cors',
    headers,
  });
  if (response.ok) {
    return;
  }
  throw new HttpError(response.status, response.statusText);
};

export const fetchContactsByBusinessPartner = async (businessPartnerCode: string): Promise<Contact[]> => {
  if (!urls.customerContactsByBusinessPartner) throw Error('Contacts by location URL not provided');

  const url = `${urls.customerContactsByBusinessPartner}/${businessPartnerCode}`;

  return postData<Contact[]>(url);
};

export const fetchResetPasswordUrl = async (): Promise<PasswordChangeUrlResponse> => {
  const contentTypeHeader = new Map([[HeadersConstants.CONTENT_TYPE, MimeTypes.APPLICATION_JSON]]);

  const body = '';

  if (!urls.passwordChange) throw Error('Password change URL not provided');

  return postData(urls.passwordChange, body, contentTypeHeader);
};
