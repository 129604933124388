import { VerticalContainer } from '@components/styles';
import { PlaceholderProps } from '@fortum/elemental-ui';
import styled from 'styled-components';

export const Container = styled(VerticalContainer)`
  width: 100%;
  height: 100%;
`;
export const placeholderStyles: PlaceholderProps = {
  height: '24px',
  width: '90%',
  marginVertical: '8px',
};
