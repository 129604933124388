import { Namespace } from '@config/i18n';
import { paths } from '@config/routes';
import {
  ContentText,
  IconBin,
  IconDownloadFromCloud,
  IconEmail,
  IconHome,
  IconTermsOfUse,
  colors,
  fontSizes,
} from '@fortum/elemental-ui';
import { getEnvProperty } from '@utils/general';
import { BaseSyntheticEvent, FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { NavItem } from './NavItem';
import { AppVersionInfo, Navigation } from './styles';
import { isCo2ReportToggleOn, isOrdersManagementToggleOn } from '@utils/featureToggles';
import { useUserInfo } from '@data/hooks/useUserInfo';
import { OrdersSubNavigation } from './OrdersSubNavigation';
import { ReportsSubNavigation } from './ReportsSubNavigation';

interface AppNavbarProps {
  disabled?: boolean;
}

export const AppNavbar: FC<AppNavbarProps> = ({ disabled = false }) => {
  const { t } = useTranslation<Namespace[]>(['mainLayout', 'reports', 'orderCreation']);

  const { data: user } = useUserInfo();

  const onItemClick = useCallback((e: BaseSyntheticEvent) => disabled && e.preventDefault(), [disabled]);

  const renderVersionDesc = useCallback(() => {
    if (!getEnvProperty('REACT_APP_ENV_DESC_TO_BE_DISPLAYED')) return null;

    const description = getEnvProperty('REACT_APP_RELEASE_VERSION')
      ? `${getEnvProperty('REACT_APP_ENV_DESC_TO_BE_DISPLAYED')} ${getEnvProperty('REACT_APP_RELEASE_VERSION').substring(0, 5)}`
      : getEnvProperty('REACT_APP_ENV_DESC_TO_BE_DISPLAYED');

    return (
      <AppVersionInfo>
        <ContentText color={colors.snowWhite} fontSize={fontSizes.xs}>
          {description}
        </ContentText>
      </AppVersionInfo>
    );
  }, []);

  return (
    <Navigation>
      <ul>
        <NavItem
          data-testid="home-nav-item"
          to={paths.main}
          onClick={onItemClick}
          disabled={disabled}
          icon={<IconHome />}
          description={t('mainLayout:home')}
        />

        <OrdersSubNavigation disabled={disabled} user={user} onItemClick={onItemClick} />

        <NavItem
          data-testid="services-nav-item"
          to={paths.services}
          onClick={onItemClick}
          disabled={disabled}
          icon={<IconBin />}
          description={t('mainLayout:services')}
        />

        <ReportsSubNavigation disabled={disabled} onItemClick={onItemClick} />

        {!!user && isCo2ReportToggleOn(user) && (
          <NavItem
            data-testid="co2-report-nav-item"
            to={paths.co2Report}
            onClick={onItemClick}
            disabled={disabled}
            icon={<IconDownloadFromCloud />}
            description={t('reports:co2.navigation')}
          />
        )}
        <NavItem
          data-testid="contact-us-nav-item"
          to={paths.contactUs}
          onClick={onItemClick}
          disabled={disabled}
          icon={<IconEmail />}
          description={t('mainLayout:contact')}
        />

        {user && isOrdersManagementToggleOn(user) && (
          <NavItem
            data-testid="orders-management-nav-item"
            to={paths.ordersManagement}
            onClick={onItemClick}
            disabled={disabled}
            icon={<IconTermsOfUse />}
            description={t('orderCreation:ordersManagement.header')}
            alternativeDefaultColor
          />
        )}
      </ul>

      {renderVersionDesc()}
    </Navigation>
  );
};
