import { QLIK_RESOURCES_RELATIVE_PATH, RESOURCES_RELATIVE_PATH } from './urls';
import { getQlikBaseURL } from '@utils/qlik';

export const loadQlikRootApi = (config, onError) =>
  new Promise(resolve => {
    window.require.config({
      baseUrl: `${getQlikBaseURL(config)}${RESOURCES_RELATIVE_PATH}`,
      paths: {
        qlik: `${getQlikBaseURL(config)}${QLIK_RESOURCES_RELATIVE_PATH}`,
      },
      config: {
        text: {
          useXhr() {
            return true;
          },
        },
      },
    });

    window.require(['js/qlik'], q => {
      q.setOnError(onError);

      resolve(q);
    });
  });
