import { horizontalContainer, HorizontalContainer, staticBorderStyles, verticalContainer } from '@components/styles';
import { breakpoints, colors, ColProps, ContentTextProps, fontSizes, IconProps, LinkProps, spacing } from '@fortum/elemental-ui';
import styled from 'styled-components';
import type { LinksDirection } from './Tile';

export const TileContainer = styled.div`
  ${verticalContainer};
  ${staticBorderStyles()};

  height: 100%;
  box-sizing: border-box;
  padding: ${spacing.xs};
  background-color: ${colors.snowWhite};

  @media ${`(max-width: ${breakpoints.xl}px)`} {
    padding: ${spacing.xs};
  }
`;

export const LinksContainer = styled.div<{ $linksDirection: LinksDirection }>`
  ${({ $linksDirection }) => ($linksDirection === 'horizontal' ? horizontalContainer() : verticalContainer())};

  row-gap: ${spacing.xxxs};
  column-gap: ${spacing.xs};

  padding: ${spacing.xxxs} 0;
`;

export const linkStyles: LinkProps = {
  noUnderline: true,
  disableVisited: true,
  color: colors.oceanGreen,
  size: 's',
};

export const headerTextStyles: ContentTextProps = {
  fontSize: fontSizes.xl,
  textAlign: 'center',
};

export const Header = styled(HorizontalContainer)`
  gap: ${spacing.xxxs};
`;

export const messageTextStyles: ContentTextProps = {
  fontSize: fontSizes.s,
  textAlign: 'left',
  mb: spacing.xxxs,
  minHeight: '48px',
};

export const iconStyles: IconProps = {
  size: 24,
  color: colors.oceanGreen,
};

export const columnStyles: ColProps = {
  xs: 12,
  l: 6,
};

export const firstRowColumnStyles: ColProps = {
  ...columnStyles,
  paddingBottom: { xs: spacing.xs },
};

export const noCo2ColumnStyles: ColProps = {
  xs: 12,
  l: 4,
};

export const reportsColumnStyles: ColProps = {
  ...columnStyles,
  paddingBottom: { xs: spacing.xs, l: 0 },
};
