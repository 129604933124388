import { QlikSelectionObject } from '@components/qlik';
import { FC } from 'react';
import { FiltersContainer } from './styles';
import { Co2ReportObjects } from '@config/qlik';

interface FiltersProps {
  objectIds: Co2ReportObjects;
}

const SELECTION_OBJECTS_PADDING = '0px';

export const Filters: FC<FiltersProps> = ({ objectIds }) => (
  <FiltersContainer data-testid="filters">
    <QlikSelectionObject
      id={objectIds.filters.companyName}
      padding={SELECTION_OBJECTS_PADDING}
      dataTestId="filter-company-name"
    />

    <QlikSelectionObject
      id={objectIds.filters.companySiteCity}
      padding={SELECTION_OBJECTS_PADDING}
      dataTestId="filter-company-site-city"
    />

    <QlikSelectionObject
      id={objectIds.filters.executionYear}
      padding={SELECTION_OBJECTS_PADDING}
      dataTestId="filter-execution-year"
    />

    <QlikSelectionObject
      id={objectIds.filters.executionMonth}
      padding={SELECTION_OBJECTS_PADDING}
      dataTestId="filter-execution-month"
    />

    <QlikSelectionObject
      id={objectIds.filters.hazardousNonHazardous}
      padding={SELECTION_OBJECTS_PADDING}
      dataTestId="filter-hazardous-non-hazardous"
    />

    <QlikSelectionObject
      id={objectIds.filters.transportEmission}
      padding={SELECTION_OBJECTS_PADDING}
      dataTestId="filter-transport-emission"
    />
  </FiltersContainer>
);
