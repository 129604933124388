import { horizontalContainer } from '@components/styles';
import {
  BoxProps,
  colors,
  ContentText,
  ContentTextProps,
  fontSizes,
  FortumLogo,
  FortumLogoProps,
  hex2rgba,
  px2rem,
  spacing,
} from '@fortum/elemental-ui';
import styled from 'styled-components';

export const SelectedPartnersText = styled(ContentText)`
  width: 100%;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${colors.oceanGreen};

  &:hover {
    cursor: pointer;
  }
`;

export const Header = styled.header`
  ${horizontalContainer}

  box-shadow: ${hex2rgba(colors.inkGrey, 0.1)} 0px 0px 1.6875rem 0.125rem;
  align-items: center;
  min-height: var(--app-header-height);
  height: var(--app-header-height);
  max-height: var(--app-header-height);
  background-color: ${colors.snowWhite};
  padding: 0 ${spacing.xxs} 0 ${spacing.xs};
  box-sizing: border-box;
  position: sticky;
  top: 0;
  justify-content: space-between;
  border-bottom: thin solid ${colors.cloudGrey};
  z-index: 10;
`;

export const LeftHeaderSection = styled.section`
  ${horizontalContainer}
  align-items: center;
  column-gap: ${px2rem(12)};
`;

export const RightHeaderSection = styled.section`
  ${horizontalContainer}
  align-items: center;
  column-gap: ${px2rem(2)};
`;

export const FortumLogoWitHover = styled(FortumLogo)`
  &:hover {
    cursor: pointer;
  }
`;

export const headerTitleStyles: ContentTextProps = {
  size: 'l',
  weight: 'medium',
  marginLeft: spacing.xxxs,
};

export const separatorStyles: ContentTextProps = {
  size: 29,
};

export const logoStyles: FortumLogoProps = {
  size: 's',
};

export const bpsBoxStyles: BoxProps = {
  display: 'flex',
  flex: '1',
  flexDirection: 'column',
  alignSelf: 'center',
  maxWidth: '19vw',
  alignItems: 'flex-end',
};

export const bpsTitleStyles: ContentTextProps = {
  color: colors.inkGrey,
  fontSize: fontSizes.s,
};

// because of the font size, we need to override the default text styles from the library
export const selectedBpsTextStyles: ContentTextProps = {
  fontSize: fontSizes.s,
};
