import { QueryKeys } from '@common/query';
import i18n from '@config/i18n';
import { fetchOrderDetails } from '@data/api/order';
import { OrderDetails } from '@models/orders';
import { useQuery } from '@tanstack/react-query';
import { shouldRetry } from '@utils/data';

export const useOrderDetails = (orderNo: string) => {
  const language = i18n.language;

  return useQuery<OrderDetails, Error>({
    queryKey: [QueryKeys.orderDetails, orderNo, language],
    queryFn: () => fetchOrderDetails(orderNo, language),
    refetchOnWindowFocus: false,
    retry: shouldRetry,
  });
};
