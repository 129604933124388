import styled from 'styled-components';
import { COLUMN_GAP_PX, HorizontalContainerWithGaps, ROW_GAP_PX } from '../styles';
import { QLIK_SELECTION_OBJECT_WIDTH_NUMERICAL } from '@components/qlik/components/QlikSelectionObject';
import { VerticalContainer } from '@components/styles';

export const BottomContainer = styled(HorizontalContainerWithGaps)`
  flex: 1;
`;

export const TopContainer = styled(HorizontalContainerWithGaps)`
  --qlik-object-width: ${`${QLIK_SELECTION_OBJECT_WIDTH_NUMERICAL}px`};

  flex-wrap: wrap;
  margin-left: calc(var(--qlik-object-width) + ${COLUMN_GAP_PX});
  align-items: flex-end;
`;
export const LeftSideContainer = styled(VerticalContainer)`
  row-gap: calc(6 * ${ROW_GAP_PX});
`;
