import { OrderDetails, OrderWasteDetails } from '@models/orders';
import { hasValue } from '@utils/dataOperations';
import { getListItemTestId } from '@utils/testsHelpers';
import { EQUIPMENT_QUANTITY_UNIT, WASTE_QUANTITY_UNIT } from '../config';
import { NO_DATA_BADGE } from '@utils/dataOperations';
import { t } from 'i18next';

interface QuantityEntryDetails {
  quantityEntryTestId: string;
  quantityEntryLabel: string;
  quantityValue: string;
}

export const getWasteQuantityEntryDetails = (
  estimation: boolean,
  quantity: OrderWasteDetails['quantity'],
): QuantityEntryDetails => {
  const quantityEntryTestId = estimation ? getListItemTestId('estimated-quantity') : getListItemTestId('delivered-quantity');
  const quantityEntryLabel = estimation ? t('orderDetails:estimatedQuantity') : t('orderDetails:deliveredQuantity');

  const quantityValue = hasValue(quantity) ? `${quantity} ${WASTE_QUANTITY_UNIT}` : NO_DATA_BADGE;

  return {
    quantityEntryTestId,
    quantityEntryLabel,
    quantityValue,
  };
};

export const getEquipmentQuantityEntryDetails = (
  estimation: boolean,
  quantity: OrderDetails['equipmentDetails']['quantity'],
): QuantityEntryDetails => {
  const quantityEntryTestId = estimation
    ? getListItemTestId('estimated-eq-quantity')
    : getListItemTestId('delivered-eq-quantity');
  const quantityEntryLabel = estimation ? t('orderDetails:estimatedQuantity') : t('orderDetails:deliveredQuantity');

  const unit = t(`common:units.${EQUIPMENT_QUANTITY_UNIT}`);

  const quantityValue = hasValue(quantity) ? `${quantity} ${unit}` : NO_DATA_BADGE;

  return {
    quantityEntryTestId,
    quantityEntryLabel,
    quantityValue,
  };
};
