import { QlikApp } from '@components/qlik/types';
import { Namespace } from '@config/i18n';
import { ErrorMessage, Loader } from '@fortum/elemental-ui';
import { useTranslation } from 'react-i18next';
import { FC, RefObject } from 'react';
import { FullWidthAndHeightContainer } from './styles';

interface VisualizationProps {
  error: boolean;
  app: QlikApp | null;

  visualizationRef: RefObject<HTMLDivElement>;
}

export const Visualization: FC<VisualizationProps> = ({ error, app, visualizationRef }) => {
  const { t } = useTranslation<Namespace>('errors');

  if (error) {
    return <ErrorMessage active>{t('reports.failedToDisplayQlikVisualization')}</ErrorMessage>;
  }

  return app ? <FullWidthAndHeightContainer ref={visualizationRef} /> : <Loader size={12} />;
};
