import { User } from '@models/user';
import { hasPermission } from '@utils/user';

export const isOrdersCreationToggleOn = (userInfo: User) =>
  process.env.REACT_APP_ORDER_CREATION_ALLOWED === 'true' && hasPermission(userInfo, 'OrderCreation');

export const isCo2ReportToggleOn = (userInfo: User) =>
  process.env.REACT_APP_CO2_REPORT_ALLOWED === 'true' && hasPermission(userInfo, 'CO2Report');

export const isOrdersManagementToggleOn = (userInfo: User) => hasPermission(userInfo, 'OrdersManagement');

export const isUsersManagementToggleOn = (user: User) =>
  process.env.REACT_APP_USERS_MANAGEMENT_ALLOWED === 'true' && hasPermission(user, 'UserAccountManagement');
