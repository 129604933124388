import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { ButtonsContainer, SelectionManagementContainer } from './styles';
import { Button, colors, useToggle } from '@fortum/elemental-ui';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { BookmarkCreationModal } from './BookmarkCreationModal';
import { useNotification, Notification } from '@components/Notification';
import { BookmarksContext } from '@components/qlik/BookmarksProvider';
import { CurrentSelections, CurrentSelectionsProps } from '@components/qlik';
import { logException } from '@config/azureInsights';

type SelectionsManagementProps = Omit<CurrentSelectionsProps, 'clearSelection'> & {
  clearSelections: (field?: string | undefined) => Promise<void>;
  isSelectionsFetchingError: boolean;
  isSelectionsLoading: boolean;
};

export const SelectionsManagement: FC<SelectionsManagementProps> = ({
  qSelections,
  clearSelections,
  isSelectionsFetchingError,
  isSelectionsLoading,
  ...props
}) => {
  const { t, i18n } = useTranslation<Namespace[]>(['reports', 'errors']);
  const { displayNotification, setDisplayErrorNotification, closeNotification, setDisplaySuccessfulNotification } =
    useNotification();
  const [errorNotificationMessage, setErrorNotificationMessage] = useState<string>('');
  const [successNotificationMessage, setSuccessNotificationMessage] = useState<string>('');
  const bookmarksContext = useContext(BookmarksContext);

  const [modalOpened, toggleModalOpened] = useToggle();

  const onBookmarkCreate = useCallback(
    (title: string, description: string) => {
      if (!bookmarksContext) {
        return;
      }
      bookmarksContext
        .createBookmark(title, description)
        .then(() => {
          setSuccessNotificationMessage(t('reports:bookmarks.bookmarkCreated', { name: title }));
          setDisplaySuccessfulNotification();
        })
        .catch(e => {
          setErrorNotificationMessage(t('errors:reports.bookmarks.failedToCreate'));
          setDisplayErrorNotification();
          logException(e);
        });
    },
    [bookmarksContext?.createBookmark, i18n.language],
  );

  const disableButtons = useMemo(
    () => isSelectionsLoading || isSelectionsFetchingError || qSelections.length === 0,
    [isSelectionsLoading, isSelectionsFetchingError, qSelections.length],
  );

  const clearSingleSelection = useCallback(
    (field: string) => {
      clearSelections(field).catch(() => {
        setErrorNotificationMessage(t('errors:reports.selections.failedToClearSingleSelection', { selectionName: field }));
        setDisplayErrorNotification();
      });
    },
    [clearSelections, i18n.language],
  );

  const clearAllSelections = useCallback(() => {
    clearSelections().catch(() => {
      setErrorNotificationMessage(t('errors:reports.selections.failedToClearSelections'));
      setDisplayErrorNotification();
    });
  }, [clearSelections, i18n.language]);

  return (
    <>
      <SelectionManagementContainer data-testid="selections-management">
        <CurrentSelections
          {...props}
          qSelections={qSelections}
          clearSelection={clearSingleSelection}
          error={isSelectionsFetchingError}
          errorMessage={t('errors:reports.selections.failedToLoadCurrentSelections')}
        />
        {bookmarksContext ? (
          <ButtonsContainer>
            <Button
              id="clear-all-selections-button"
              status="secondary"
              variant="condensed"
              color={colors.composedBlue}
              onClick={clearAllSelections}
              backgroundColor="transparent"
              disabled={disableButtons}
            >
              {t('reports:selectionsManagement.clearAll')}
            </Button>

            <Button
              id="create-bookmark-button"
              status="secondary"
              variant="condensed"
              color={colors.composedBlue}
              backgroundColor="transparent"
              onClick={toggleModalOpened}
              disabled={disableButtons}
            >
              {t('reports:bookmarks.save.long')}
            </Button>
          </ButtonsContainer>
        ) : (
          <Button
            id="clear-all-selections-button"
            status="secondary"
            variant="condensed"
            color={colors.composedBlue}
            onClick={clearAllSelections}
            backgroundColor="transparent"
            disabled={disableButtons}
          >
            {t('reports:selectionsManagement.clearAll')}
          </Button>
        )}
      </SelectionManagementContainer>

      <BookmarkCreationModal opened={modalOpened} onClose={toggleModalOpened} createBookmark={onBookmarkCreate} />

      <Notification
        type="error"
        id="form-error-notification"
        content={errorNotificationMessage}
        opened={displayNotification === 'error'}
        onClose={closeNotification}
        maxWidth="40rem"
      />

      <Notification
        type="success"
        id="form-success-notification"
        content={successNotificationMessage}
        opened={displayNotification === 'success'}
        onClose={closeNotification}
        maxWidth="35rem"
      />
    </>
  );
};
