export const getElementsNames = (
  componentName: string,
): {
  selectName: string;
  multiselectName: string;
  multiselectSelector: string;
} => {
  const multiselectName = `${componentName}-multiselect`;
  return {
    selectName: `${componentName}-select`,
    multiselectName,
    multiselectSelector: `#${multiselectName}`,
  };
};
