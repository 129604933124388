import {
  Button,
  ContentText,
  Heading,
  IconButton,
  IconCustomerReleases,
  IconError,
  IconTrash,
  ModalProps,
  Notification,
  colors,
  fontSizes,
  lineHeights,
  spacing,
} from '@fortum/elemental-ui';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import {
  BookmarksListContainer,
  BookmarkItemContainer,
  BookmarkDetailsContainer,
  MiddleContentContainer,
  StyledModal,
  TopContainer,
} from './styles';
import { Namespace } from '@config/i18n';
import { useTranslation } from 'react-i18next';

interface BookmarkProps {
  qBookmark: enigmaJS.QBookmark;
  remove: (bookmarkId: string, bookmarkTitle: string) => void;
}
//TODO: log errors to azure

const Bookmark: FC<BookmarkProps> = ({ qBookmark, remove }) => {
  const titleDashSeparated = qBookmark.qMeta.title.replace(/\s/g, '-');
  return (
    <BookmarkItemContainer data-testid={`bookmark-${titleDashSeparated}`}>
      <BookmarkDetailsContainer>
        <ContentText fontSize={fontSizes.m}>{qBookmark.qMeta.title}</ContentText>
        <ContentText lineHeight={lineHeights.tight} fontSize={fontSizes.m} color={colors.sonicGrey}>
          {qBookmark.qMeta.description}
        </ContentText>
      </BookmarkDetailsContainer>

      <IconButton
        icon={<IconTrash size={24} />}
        id={`bookmark-${titleDashSeparated}-remove-button`}
        color={colors.hazeRedOrange}
        status="plain"
        onClick={() => remove(qBookmark.qInfo.qId, qBookmark.qMeta.title)}
      />
    </BookmarkItemContainer>
  );
};

const MemoizedBookmark = memo(Bookmark);

type BookmarksRemovalModalProps = Omit<ModalProps, 'onClose' | 'children'> & {
  onClose: () => void;
  qBookmarks: enigmaJS.QBookmark[];
  removeBookmark: (bookmarkId: string, bookmarkTitle: string) => Promise<void>;
};

export const BookmarksRemovalModal: FC<BookmarksRemovalModalProps> = ({ qBookmarks, removeBookmark, ...props }) => {
  const { t } = useTranslation<Namespace[]>(['reports', 'common', 'errors']);
  const [displayError, setDisplayError] = useState<boolean>(false);

  const remove = useCallback(
    (bookmarkId: string, bookmarkTitle: string) => {
      setDisplayError(false);
      removeBookmark(bookmarkId, bookmarkTitle).catch(() => {
        setDisplayError(true);
      });
    },
    [removeBookmark],
  );

  const renderBookmarksList = useCallback(
    () => (
      <BookmarksListContainer $error={displayError}>
        {qBookmarks.map(bookmark => (
          <MemoizedBookmark key={bookmark.qInfo.qId} qBookmark={bookmark} remove={remove} />
        ))}
      </BookmarksListContainer>
    ),
    [qBookmarks, remove, displayError],
  );

  useEffect(() => {
    setDisplayError(false);
  }, [props.opened]);

  return (
    <StyledModal {...props}>
      <TopContainer>
        <IconCustomerReleases size={48} />
        <MiddleContentContainer>
          <Heading level={4} mb={displayError ? 'unset' : spacing.xxs}>
            {t('reports:bookmarks.manageBookmarks')}
          </Heading>
          {displayError && (
            <Notification backgroundColor={colors.hazeRedOrange} icon={<IconError />} hideCloseButton>
              <ContentText>{t('errors:reports.bookmarks.failedToDelete')}</ContentText>
            </Notification>
          )}
          {renderBookmarksList()}
        </MiddleContentContainer>
      </TopContainer>
      <Button status="secondary" onClick={props.onClose} marginTop="80px">
        {t('common:close')}
      </Button>
    </StyledModal>
  );
};
