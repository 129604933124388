import {
  IconDocuments,
  ContentText,
  fontWeights,
  ContentTextProps,
  fontSizes,
  IconTrash,
  IconButton,
  spacing,
  IconButtonProps,
  colors,
} from '@fortum/elemental-ui';
import { FC, memo } from 'react';
import { Header, HeaderInformationContainer, HeaderTextContainer } from './styles';
import { Namespace } from '@config/i18n';
import { useTranslation } from 'react-i18next';

interface ServiceTileHeaderProps {
  serviceIndex: number;
  contractNo: string;
  onIconClick: () => void;
}

const contentTextStyles: ContentTextProps = {
  fontSize: fontSizes.s,
};

const mediumContentTextStyles: ContentTextProps = {
  ...contentTextStyles,
  fontWeight: fontWeights.medium,
};

const iconButtonStyles: Omit<IconButtonProps, 'icon'> = {
  status: 'plain',
  size: 56,
  marginRight: spacing.xs,
  marginLeft: 'auto',
  color: colors.punchRed,
};

export const ServiceTileHeader: FC<ServiceTileHeaderProps> = memo(({ serviceIndex, contractNo, onIconClick }) => {
  const { t } = useTranslation<Namespace[]>(['wasteDetails', 'orderCreation']);
  const itemNumber = `${serviceIndex + 1}. ${t('services:service')} -`;

  return (
    <Header data-testid="service-tile-header">
      <HeaderInformationContainer>
        <IconDocuments size={24} />

        <HeaderTextContainer>
          <ContentText {...mediumContentTextStyles} data-testid="service-index">
            {itemNumber}
          </ContentText>
          <ContentText {...contentTextStyles} data-testid="contract-no-label">
            {t('wasteDetails:contractNo')}
          </ContentText>
          <ContentText {...mediumContentTextStyles} data-testid="contract-no-value">
            {contractNo}
          </ContentText>
        </HeaderTextContainer>
      </HeaderInformationContainer>
      <IconButton
        onClick={onIconClick}
        icon={<IconTrash />}
        aria-label={t('orderCreation:discardServiceLabel')}
        {...iconButtonStyles}
      />
    </Header>
  );
});

ServiceTileHeader.displayName = 'ServiceTileHeader';
