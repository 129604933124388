import { translate } from '@utils/internationalization';
import { Tile } from '@components/Tile';
import { StyledDescriptionListItem } from '@components/styles';
import { ContactInformation, LocationPointDetails } from '@components/transportDetails';
import { Namespace } from '@config/i18n';
import { DescriptionList } from '@fortum/elemental-ui';
import { OrderDetails } from '@models/orders';
import { hasValue } from '@utils/dataOperations';
import { displayedValue } from '@utils/dataOperations';
import { getListItemTestId, getTileTestId } from '@utils/testsHelpers';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface TransportDetailsTileProps {
  transportDetails: OrderDetails['transportDetails'];
}

export const TransportDetailsTile: FC<TransportDetailsTileProps> = ({ transportDetails }) => {
  const { t } = useTranslation<Namespace>('wasteDetails');

  const { contactDetails } = transportDetails;

  return (
    <Tile header={t('transportDetails.details')} data-testid={getTileTestId('transport-details')}>
      <DescriptionList size="condensed">
        <StyledDescriptionListItem label={t('transportDetails.transportType')} data-testid={getListItemTestId('transport-type')}>
          {translate(displayedValue(transportDetails.transportType), 'domain', 'transportType')}
        </StyledDescriptionListItem>

        <StyledDescriptionListItem
          label={t('transportDetails.contactDetails')}
          data-testid={getListItemTestId('contact-details')}
        >
          <ContactInformation
            contactName={contactDetails?.name}
            phoneNumber={contactDetails?.phone}
            email={contactDetails?.email}
          />
        </StyledDescriptionListItem>

        <StyledDescriptionListItem
          label={t('transportDetails.loadingLocation')}
          data-testid={getListItemTestId('loading-location-point')}
        >
          <LocationPointDetails
            companyName={transportDetails.loadingLocation?.companyName}
            address={transportDetails.loadingLocation?.address}
          />
        </StyledDescriptionListItem>

        <StyledDescriptionListItem
          label={t('transportDetails.receivingLocation')}
          data-testid={getListItemTestId('receiving-location-point')}
        >
          <LocationPointDetails
            companyName={transportDetails.receivingLocation?.companyName}
            address={transportDetails.receivingLocation?.address}
          />
        </StyledDescriptionListItem>

        {hasValue(transportDetails.instructionsForDriver) && (
          <StyledDescriptionListItem
            label={t('transportDetails.instructionsForDriver')}
            data-testid={getListItemTestId('instructions-for-driver')}
          >
            {transportDetails.instructionsForDriver}
          </StyledDescriptionListItem>
        )}

        {hasValue(transportDetails.orderSpecificInstructionsForDriver) && (
          <StyledDescriptionListItem
            label={t('orderCreation:form.transportationAdditionalInfo.label')}
            data-testid={getListItemTestId('location-point-additional-info')}
          >
            {transportDetails.orderSpecificInstructionsForDriver}
          </StyledDescriptionListItem>
        )}
      </DescriptionList>
    </Tile>
  );
};
