import { Button, ContentText, IconDistrictHeatingOutage, colors, spacing } from '@fortum/elemental-ui';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { NonWrappingLinkLowerCased, Container, headerTextStyles, messageTextStyles } from './styles';
import { paths } from '@config/routes';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';

interface IncorrectDataErrorProps {
  onClick: () => void;
}

export const IncorrectDataError: FC<IncorrectDataErrorProps> = ({ onClick }) => {
  const { t } = useTranslation<Namespace[]>(['errors', 'common']);
  const navigate = useNavigate();

  return (
    <Container>
      <IconDistrictHeatingOutage color={colors.silverGrey} pb={spacing.xxxs} />

      <ContentText {...headerTextStyles}>{t('errors:ordersCreation.failedToCreateFromTheContract.header')}</ContentText>

      <ContentText {...messageTextStyles}>
        {t('errors:ordersCreation.failedToCreateFromTheContract.message.0')}

        <NonWrappingLinkLowerCased noUnderline onClick={() => navigate(paths.contactUs)}>
          {t('errors:ordersCreation.failedToCreateFromTheContract.message.1')}
        </NonWrappingLinkLowerCased>

        {t('errors:ordersCreation.failedToCreateFromTheContract.message.2')}
      </ContentText>

      <Button status="secondary" onClick={onClick}>
        {t('common:chooseOtherService')}
      </Button>
    </Container>
  );
};
