import { ContentText } from '@fortum/elemental-ui';
import { ServiceSummary } from 'src/types/ordersCreation';
import { hasValue } from '@utils/dataOperations';
import { splitStreetAddressOnTheZipCode } from '@utils/dataOperations/address';
import { compact } from 'lodash';
import { FC } from 'react';
import { Stylable } from './types';
import { VerticalContainer } from './styles';

export interface CompanyDetailsProps extends Stylable {
  company: ServiceSummary['company'];
}

export const CompanyDetails: FC<CompanyDetailsProps> = ({ company, className }) => {
  const { customer, location } = company;

  const elements = compact([
    customer,
    hasValue(location.name) ? location.name : undefined,
    ...splitStreetAddressOnTheZipCode(location.address),
    hasValue(location.exact) ? location.exact : undefined,
  ]);

  return (
    <VerticalContainer data-testid="company-details" className={className}>
      {elements.map((element, index) => (
        <ContentText key={index}>{element}</ContentText>
      ))}
    </VerticalContainer>
  );
};
