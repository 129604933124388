import styled, { css } from 'styled-components';
import { Box, colors, spacing } from '@fortum/elemental-ui';
import { staticBorderStyles } from '@components/styles';

export const Container = styled(Box)`
  ${staticBorderStyles()};

  margin-bottom: ${spacing.xs};
`;

export const BusinessPartnerRow = styled.div<{ $disabled: boolean }>`
  display: flex;
  cursor: pointer;
  ${props =>
    props.$disabled &&
    css`
      color: ${colors.fogGrey};
      -webkit-text-fill-color: ${colors.fogGrey};
      cursor: not-allowed;
    `}
`;
