import { SupportedLanguage } from '@common/languages';
import { QlikReportConfig, QlikConfig } from 'src/types/qlik';
import { co2ReportConfigSchemaValidator } from './schemas';
import { getQlikConnectionConfig } from '../connection';
import { Co2ReportConfig, Co2ReportObjects } from './types';
import { createSchemaErrorMessage, getReportConfig } from '@utils/qlik';
export * from './types';

const validateSchemas = (): QlikConfig<Co2ReportConfig, Co2ReportObjects> => {
  const connectionConfig = getQlikConnectionConfig();

  if (!process.env.REACT_APP_CO2_REPORT_CONFIG) {
    throw Error('CO2 report config not found');
  }

  const reportConfig = JSON.parse(process.env.REACT_APP_CO2_REPORT_CONFIG);

  const validate = co2ReportConfigSchemaValidator();

  if (!validate(reportConfig)) {
    throw Error('Report config does not match the schema: ' + createSchemaErrorMessage(validate.errors));
  }

  return { reportConfig, connectionConfig };
};

export const getCo2ReportConfig = (lang: SupportedLanguage): QlikReportConfig<Co2ReportObjects> => {
  const validatedSchemas = validateSchemas();

  return getReportConfig(validatedSchemas, lang);
};
