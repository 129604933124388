import { QueryKeys } from '@common/query';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { QueryOptions } from './types';
import { shouldRetry } from '@utils/data';
import { ContractsByLocationsResponse } from '@models/contract';
import { ContractsByLocationRequestParameters, fetchContractsByLocations } from '@data/api/contract';
import { PartialBy } from 'src/types/general';

const DEFAULT_NUMBER_OF_PAGES_ELEMENT = 20;

const contractsByLocationsQuery = (
  requestParams: ContractsByLocationRequestParameters,
  enabled = true,
): QueryOptions<ContractsByLocationsResponse> => ({
  enabled,
  queryKey: [QueryKeys.contractsByLocations, requestParams],
  queryFn: () => fetchContractsByLocations(requestParams),
  refetchOnWindowFocus: false,
  retry: shouldRetry,
});

export type UseContractsByLocationsParams = PartialBy<
  ContractsByLocationRequestParameters,
  'language' | 'numberOfPageElements'
> & { enabled?: boolean };

export const useContractsByLocations = ({ enabled = true, ...requestParams }: UseContractsByLocationsParams) => {
  const { i18n } = useTranslation();

  return useQuery<ContractsByLocationsResponse, Error>(
    contractsByLocationsQuery(
      {
        ...requestParams,
        language: requestParams.language ?? i18n.language,
        numberOfPageElements: requestParams.numberOfPageElements ?? DEFAULT_NUMBER_OF_PAGES_ELEMENT,
      },
      enabled,
    ),
  );
};
