import { FC, useContext } from 'react';
import { containsWasteDetails } from '@utils/contract';
import { DetailsFormWithWastes } from './DetailsFormWithWastes';
import { DetailsForm } from './DetailsForm';
import { SingleOrderCreationContext } from '../SingleOrderCreationContextProvider';

export const OrderDetailsForm: FC = () => {
  const { form, contract } = useContext(SingleOrderCreationContext);

  const displayWasteDetails = containsWasteDetails(contract) && form.values.orderType !== 'DELIVERY';

  return displayWasteDetails ? (
    <DetailsFormWithWastes data-testid="order-details" />
  ) : (
    <DetailsForm data-testid="order-details" />
  );
};
