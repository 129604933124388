import styled from 'styled-components';
import { ContentText, fontWeights, spacing } from '@fortum/elemental-ui';
import { MenuItem } from '../menu';

export const StyledContentText = styled(ContentText)`
  word-break: break-word;
`;

export const StyledSpan = styled.span`
  font-weight: ${fontWeights.regular};
  padding-left: ${spacing.xxxxs};
`;
export const StyledAnchor = styled.a`
  display: flex;
  cursor: pointer;
  align-items: center;
`;

export const MenuItemWithPaddingTop = styled(MenuItem)`
  padding-top: ${spacing.xxs};
`;
