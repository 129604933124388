import { Selection } from './types';

export const createSelectValues = (values: string[]) => {
  if (values.length === 0) {
    return '';
  }

  const joinedValues = values.map(value => `"${value}"`).join('|');

  return `(${joinedValues})`;
};

export const validateMandatorySelections = (mandatorySelections: Selection[], currentSelections: enigmaJS.QSelection[]) => {
  if (currentSelections.length === 0 && mandatorySelections.length !== 0) {
    return false;
  }

  return mandatorySelections.every(mandatorySelection =>
    currentSelections.some(currentSelection => {
      const fieldNameMatches = currentSelection.qField === mandatorySelection.fieldName;

      const atLeastOneMandatoryValueFound = currentSelection.qSelectedFieldSelectionInfo.some(selectedValue =>
        mandatorySelection.values.includes(selectedValue.qName),
      );

      return fieldNameMatches && atLeastOneMandatoryValueFound;
    }),
  );
};

export const mapToSelect = (selections: Selection[], engine: enigmaJS.IGeneratedAPI) =>
  selections.map(selection =>
    engine
      .getField(selection.fieldName)
      .then(field => field?.select(createSelectValues(selection.values)))
      .catch(() => {
        throw new Error(`Failed to set ${selection.fieldName}`);
      }),
  );
