import { scrollIntoView } from '@utils/general';
import { createServiceTileId } from '@utils/ordersCreation';
import { RefObject, useEffect, useRef, useState } from 'react';

interface UseScrollToServiceTile {
  serviceTilesContainerRef: RefObject<HTMLDivElement>;
  setContractIdentifierToScrollTo: (contractIdentifier: string) => void;
}

export const useScrollToServiceTile = (): UseScrollToServiceTile => {
  const serviceTilesContainerRef = useRef<HTMLDivElement>(null);
  const [contractIdentifierToScrollTo, setContractIdentifierToScrollTo] = useState<string>();

  useEffect(() => {
    if (!contractIdentifierToScrollTo) return;

    const serviceTileSelector = `#${createServiceTileId(contractIdentifierToScrollTo)}`;

    scrollIntoView(serviceTilesContainerRef.current?.querySelector(serviceTileSelector));
  }, [contractIdentifierToScrollTo]);

  return {
    serviceTilesContainerRef,
    setContractIdentifierToScrollTo,
  };
};
