import { getEnvProperty } from '@utils/general';

const reactAppApiUrl = getEnvProperty('REACT_APP_API_URL');
const baseUrl = reactAppApiUrl ? `${reactAppApiUrl}/rwportal-analytics/v3` : undefined;

export const AnalyticsService = {
  hazardousInWasteServicesSummaryForAdmin: baseUrl ? `${baseUrl}/charts/hazardous-waste` : null,
  hazardousInWasteServicesSummary: baseUrl ? `${baseUrl}/charts/hazardous-waste/own` : null,
  ongoingOrdersSummaryForAdmin: baseUrl ? `${baseUrl}/charts/ongoing-orders` : null,
  ongoingOrdersSummary: baseUrl ? `${baseUrl}/charts/ongoing-orders/own` : null,
  locationsWithCoordinates: baseUrl ? `${baseUrl}/coordinates/locations` : null,
  locationsWithCoordinatesOwn: baseUrl ? `${baseUrl}/coordinates/locations/own` : null,
};
