import React from 'react';
import { Box, ContentText, IconCustomerService, colors, fontSizes, spacing } from '@fortum/elemental-ui';
import { Namespace } from 'i18next';
import { useTranslation } from 'react-i18next';
import { paths } from '@config/routes';
import { ContactUsLink } from './style';

export const ContactUsSection = () => {
  const { t } = useTranslation<Namespace>('user');

  return (
    <Box
      display="flex"
      marginTop={spacing.xs}
      backgroundColor={colors.lightGrey}
      padding={spacing.xxs}
      maxWidth="489px"
      gap={spacing.xxs}
      alignItems="center"
    >
      <IconCustomerService size={48} color={colors.sonicGrey} />
      <ContentText fontSize={fontSizes.s}>
        {t('contactUs.message')}{' '}
        <ContactUsLink noUnderline href={paths.contactUs} fontSize={fontSizes.s}>
          {t('contactUs.link')}
        </ContactUsLink>
      </ContentText>
    </Box>
  );
};
