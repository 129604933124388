import { CenteredLoader } from '@components/layout/styles';
import { Namespace } from '@config/i18n';
import { useUserInfo } from '@data/hooks/useUserInfo';
import { Box, ContentText, DescriptionListItem, fontSizes, fontWeights, spacing } from '@fortum/elemental-ui';
import { useTranslation } from 'react-i18next';
import { ContactUsSection } from './ContactUsSection';
import { ResetPasswordSection } from './ResetPasswordSection';
import { StyledDescriptionList } from './style';
import { useChangePasswordUrl } from '@data/hooks/useChangePasswordUrl';
import { getListItemTestId } from '@utils/testsHelpers';
import { isUserInternal } from '@utils/user';

export const PersonalDetails = () => {
  const { data: user, isLoading, fetchStatus } = useUserInfo();
  const { data: changePasswordUrl } = useChangePasswordUrl(!!user && !isUserInternal(user.userRole));

  const { t } = useTranslation<Namespace>('user');
  return (
    <Box>
      {(isLoading || fetchStatus !== 'idle') && !user ? (
        <CenteredLoader />
      ) : (
        user && (
          <>
            <ContentText fontSize={fontSizes.m} fontWeight={fontWeights.medium} marginBottom={spacing.xxs}>
              {t('personalDetails.title')}
            </ContentText>
            <StyledDescriptionList size="condensed">
              <DescriptionListItem label={t('personalDetails.name')} data-testid={getListItemTestId('name')}>
                {user.name}
              </DescriptionListItem>
              <DescriptionListItem label={t('personalDetails.lastName')} data-testid={getListItemTestId('last-name')}>
                {user.lastname}
              </DescriptionListItem>
              <DescriptionListItem label={t('personalDetails.email')} data-testid={getListItemTestId('email')}>
                {user.email}
              </DescriptionListItem>
              <DescriptionListItem label={t('personalDetails.phone')} data-testid={getListItemTestId('phone-number')}>
                {user.phone}
              </DescriptionListItem>
            </StyledDescriptionList>
            <ContactUsSection />

            {!isUserInternal(user.userRole) && changePasswordUrl && (
              <ResetPasswordSection linkHref={changePasswordUrl.ticketUrl} />
            )}
          </>
        )
      )}
    </Box>
  );
};
