import { EquipmentServiceType } from '@models/contract';
import { HandlingStatusGroup, OrderType } from '@models/orders';

export const equipmentServiceTypesToOrderTypesMap: { [key in EquipmentServiceType]: OrderType[] } = {
  'Pick-up': ['COLLECTION'],
  Submission: ['DELIVERY'],
  Clearance: ['EMPTYING'],
  Exchange: ['COLLECTION', 'DELIVERY'],
};

//As per requirements, Exchange equipment service type is not supported in the orders creation process
export const orderTypesToEquipmentServiceTypesMap: { [key in OrderType]: EquipmentServiceType } = {
  COLLECTION: 'Pick-up',
  DELIVERY: 'Submission',
  EMPTYING: 'Clearance',
};

export const TEMPORARY_ORDER_NO_SUFFIX = 'TMP';

export const HandlingStatusMap: {
  [key in HandlingStatusGroup]: string[];
} = {
  WAITING_IN_LINE: ['O01_QUEUE'],
  IN_PROCESS: ['O20_EXECUT', 'O21_PLAN', 'O22_VENDOR'],
  TRANSPORTATION_IS_PLANNED: ['O25_TRANS'],
  IN_TRANSPORTATION: ['O27_PICKED', 'O29_DELIVE', 'O48_SENT'],
  RECEIVED_DELIVERED: ['O30_ARRIVE', 'O40_RECEPT', 'O50_DONE', 'O51_DROPPE', 'O55_READY', 'O60_WAIT', 'O65_ERROR'],
  INCOMPLETE: ['O15_MISSIN', 'O61_EQUIP', 'O62_DRIVER', 'O63_MISSIN'],
  NEWLY_CREATED: ['O00_ONHOLD'],
};
