import { BaseSyntheticEvent, FC } from 'react';
import { NavItem, SubNavigation } from './NavItem';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { paths } from '@config/routes';

interface ReportsSubNavigationProps {
  onItemClick: (e: BaseSyntheticEvent) => void;
  disabled?: boolean;
}

const reportsSubnavigationPaths = [paths.costDetailsReport, paths.wasteDetailsReport, paths.dashboardReport];

export const ReportsSubNavigation: FC<ReportsSubNavigationProps> = ({ disabled, onItemClick }) => {
  const { t } = useTranslation<Namespace[]>(['mainLayout', 'reports']);

  return (
    <SubNavigation description={t('mainLayout:reports')} disabled={disabled} subnavigationPaths={reportsSubnavigationPaths}>
      <NavItem
        data-testid="dashboard-report-nav-item"
        to={paths.dashboardReport}
        onClick={onItemClick}
        description={t('reports:dashboard.navigation')}
        disabled={disabled}
        subNavItem={true}
      />
      <NavItem
        data-testid="waste-qty-report-nav-item"
        to={paths.wasteDetailsReport}
        onClick={onItemClick}
        description={t('reports:wasteQty.navigation')}
        disabled={disabled}
        subNavItem={true}
      />
      <NavItem
        data-testid="cost-report-nav-item"
        to={paths.costDetailsReport}
        onClick={onItemClick}
        description={t('reports:cost.navigation')}
        disabled={disabled}
        subNavItem={true}
      />
    </SubNavigation>
  );
};
