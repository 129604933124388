import { Row, Col, Box, ColProps, RowProps } from '@fortum/elemental-ui';
import { OrderDetails } from '@models/orders';
import { FC } from 'react';
import { GRID_GAP, columnInWrappableRowProps, columnInnerContainerProps } from '../style';
import { CompanySiteDetailsTile } from './CompanySiteDetailsTile';
import { EquipmentDetailsTile } from './EquipmentDetailsTile';
import { InvoicingDetailsTile } from './InvoicingDetailsTile';
import { OrderStatusTile } from './OrderStatusTile';
import { TransportDetailsTile } from './TransportDetailsTile';
import { WasteDetailsTile } from './WasteDetailsTile';

interface ReceivingCollectionOrderDetailsProps {
  order: OrderDetails;
}

const fullWidthColumnProps: ColProps = {
  xs: 12,
};

const columnProps: ColProps = {
  xl: 6,
};

const firstRowProps: RowProps = {
  mb: GRID_GAP,
};

export const ReceivingCollectionOrderDetails: FC<ReceivingCollectionOrderDetailsProps> = ({ order }) => (
  <>
    <Row {...firstRowProps}>
      <Col {...fullWidthColumnProps}>
        <CompanySiteDetailsTile companySiteDetails={order.companySiteDetails} />
      </Col>
    </Row>

    <Row>
      {order.wasteDetails && (
        <Col {...columnProps} {...columnInWrappableRowProps}>
          <WasteDetailsTile wasteDetails={order.wasteDetails} />
        </Col>
      )}

      <Col {...columnProps}>
        <Box {...columnInnerContainerProps}>
          <OrderStatusTile
            orderStatus={order.orderStatus}
            creatorName={order.generalInfo.creatorName}
            additionalComments={order.generalInfo.additionalComments}
          />
          <EquipmentDetailsTile equipmentDetails={order.equipmentDetails} />
          <TransportDetailsTile transportDetails={order.transportDetails} />
          <InvoicingDetailsTile invoicingDetails={order.invoicingDetails} />
        </Box>
      </Col>
    </Row>
  </>
);
