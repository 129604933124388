import { Code } from './common';
import { Contact } from './contact';
import { ResponseWithPagination } from './general';

interface Location {
  name: string | null;
  address: string | null;
  exact: string | null;
}

export interface GeneralInfo {
  wasteDescription: string;
  equipmentDescription: string;
  location: Location;
  serviceType: string;
  customer: string;
  contractNumber: string;
  customerCode: string;
  expired: boolean;
}

export interface WasteDetails {
  description: string;
  consistency: string[];
  ewcCode: Code;
  hazard: boolean | null;
  hazardousMaterialClasses: string[];
  unNumber: string | null;
  rdCode: string;
  hpCodes: Code[];
  oilType: string | null;
  popCompounds: string | null;
  environmentalHazardous: boolean | null;
  animalicByProduct: boolean | null;
  adr: boolean | null;
}

export interface EquipmentDetails {
  description: string;
  type: string;
  serviceTypes: string[];
}

export interface TransportDetails {
  type: string;
  recurrence: string;
  instructionsForDriver: string | null;
  deliveryLocation: Location;
  pickUpLocation: Location;
}

export interface ServiceDetails {
  wasteDetails: WasteDetails;
  equipmentDetails: EquipmentDetails;
  transportDetails: TransportDetails;
}

export interface ServiceContractDetails {
  contractNumber: string;
  serviceType: string;
  customer: string | null;
  location: Location;
}

export interface InvoicingDetails {
  address: {
    company: string;
    streetAddress: string;
    invoicingEmail: string;
    eInvoiceAddress: string | null;
    specificName: string;
  };
  customerReference: string;
  contactPersonName: string;
  orderReference: string | null;
  costCenter: string | null;
}
export interface ContractDetails {
  serviceContractDetails: ServiceContractDetails;
  invoicingDetails: InvoicingDetails;
  contactDetails: Contact;
}

export interface Contract {
  generalInfo: GeneralInfo;
  serviceDetails: ServiceDetails;
  contractDetails: ContractDetails;
  ordersDetails: {
    numberOfOrders: number;
  };
}

export interface ContractLocationsResponse {
  locations: ContractLocation[];
}

export interface ContractLocation {
  name: string;
  city: string;
  address: string;
  code: string;
}

export const equipmentServiceTypes = ['Pick-up', 'Submission', 'Clearance', 'Exchange'] as const;

export type EquipmentServiceType = (typeof equipmentServiceTypes)[number];

export interface ContractsSitesResponse {
  sites: {
    site: string;
    locations: string[];
  }[];
}

export interface ContractByLocation {
  companyName: string;
  contractLocation: string;
  serviceType: string;
  wasteType: string;
  equipmentType: string;
  transportType: string;
  contractNo: string;
  dataSource: string;
}

export type ContractsByLocationsResponse = ResponseWithPagination<ContractByLocation[]>;
