import { SupportedLanguage, fallbackLang } from '@common/languages';
import { QlikError, QlikRawError, QlikRootApiConfig, qlikRawErrorMessages } from '@components/qlik/types';
import { ReportConfig, QlikConfig, QlikReportConfig, QlikAppAuthConfig } from 'src/types/qlik';
import { ErrorObject } from 'ajv';
import schema from 'enigma.js/schemas/12.170.2.json';
import { getUserAccessInfo, isUserAccessOfInternal } from './user';

export const getProtocol = (isSecure: boolean) => (isSecure ? 'https' : 'http');

export const getQlikBaseURL = (config: QlikRootApiConfig) => {
  const hostWithOptionalPort = config.port ? `${config.host}:${config.port}` : config.host;

  return `${getProtocol(config.isSecure)}://${hostWithOptionalPort}${config.prefix ? '/' + config.prefix : ''}`;
};

export const appendSlash = (prefix: string | undefined) => {
  if (!prefix?.trim() || prefix === '/') return '/';

  return `/${prefix}/`;
};

export const toQlikError = (error: QlikRawError) => {
  if (error.code === schema.enums.LocalizedErrorCode.LOCERR_GENERIC_ACCESS_DENIED) {
    return new QlikError(error.message, 'ACCESS_DENIED');
  }

  if (
    error.code === schema.enums.LocalizedErrorCode.LOCERR_GENERIC_CONNECTION_LOST ||
    error.message === qlikRawErrorMessages.proxySessionTimedOut ||
    error.message === qlikRawErrorMessages.proxySessionClosed
  ) {
    return new QlikError('Session closed', 'SESSION_TIMED_OUT');
  }

  return error.message === qlikRawErrorMessages.noData ? null : new QlikError('Failed to open the app', 'APP_OPENING_ERROR');
};
export const createSchemaErrorMessage = (errors: ErrorObject[] | null | undefined) =>
  errors?.map(error => `${error.message} (${error.instancePath})`).join(', ') ?? '';

//TODO: adjust naming when models from old and new approach merged
export const getReportConfig = <T extends ReportConfig<K>, K extends object>(
  qlikConfig: QlikConfig<T, K>,
  lang: SupportedLanguage,
): QlikReportConfig<K> => {
  const { reportConfig, connectionConfig } = qlikConfig;

  const reportLanguage: SupportedLanguage = reportConfig[lang] ? lang : fallbackLang;
  const appConfig = reportConfig[reportLanguage];

  const staticConfig = {
    qlikAppConfig: {
      ...connectionConfig,
      appId: appConfig.appId,
    },
    defaultSelections: appConfig.defaultSelections,
    objectIds: appConfig.objectIds ?? reportConfig.en.objectIds,
  };

  const userAccessInfo = getUserAccessInfo();

  if (!isUserAccessOfInternal(userAccessInfo)) {
    return staticConfig;
  }

  return {
    ...staticConfig,
    mandatorySelections: [
      {
        fieldName: appConfig.companyNameSelectionFieldName,
        values: userAccessInfo.selectedBusinessPartners.map(bp => bp.name),
      },
    ],
  };
};

export const getQlikUrl = (config: QlikAppAuthConfig) => `${config.protocol}://${config.host}/${config.prefix}/qrs/about`;
