import { FC, memo } from 'react';
import { AdrHazardClass } from '@models/waste';
import { spacing } from '@fortum/elemental-ui';

interface AdrPictogramProps {
  hazardClass?: AdrHazardClass;
}

const AdrPictogramComponent: FC<AdrPictogramProps> = ({ hazardClass }) => {
  const pictogramFileName = hazardClass ? `${hazardClass}.svg` : 'empty.svg';

  return (
    <img
      id={hazardClass}
      style={{ width: spacing.l, height: spacing.l }}
      src={`${'/pictograms/adr/ADR_'}${pictogramFileName}`}
    ></img>
  );
};

export const AdrPictogram = memo(AdrPictogramComponent);
