export const SEARCH_PARAMS = {
  serviceTabId: 'serviceTabId',
  locationCode: 'locationCode',
};

export const ORDERS_CREATION_ROUTE_PATH = 'create';
export const ORDERS_TRACKING_ROUTE_PATH = 'order-tracking';
export const ORDERS_MANAGEMENT_ROUTE_PATH = 'management';
export const ORDERS_CREATION_SUMMARY_ROUTE_PATH = 'summary';

export const paths = {
  main: '/',
  services: '/services',
  orders: '/orders',
  ordersTracking: `/orders/${ORDERS_TRACKING_ROUTE_PATH}`,
  reports: '/reports',
  dashboardReport: '/reports/dashboard',
  costDetailsReport: '/reports/cost-details',
  wasteDetailsReport: '/reports/waste-details',
  co2Report: '/co2-report',
  documents: '/documents',
  contactUs: '/contact',
  selectCustomer: '/customer',
  auth: '/auth/callback',
  userProfile: '/user/profile',
  termsAndConditions: '/terms-and-conditions',
  ordersCreation: `/orders/${ORDERS_CREATION_ROUTE_PATH}`,
  ordersManagement: `/backoffice/${ORDERS_MANAGEMENT_ROUTE_PATH}`,
  usersManagement: `/users-management`,
} as const;

export type Path = (typeof paths)[keyof typeof paths];

export const queryPaths = {
  servicesByLocation: `${paths.services}?${SEARCH_PARAMS.locationCode}=`,
};

export const fullScreenPaths: string[] = [paths.userProfile, paths.selectCustomer, paths.usersManagement];
