import { Box, BoxProps, ContentText, fontSizes } from '@fortum/elemental-ui';
import { OrderDetails } from '@models/orders';
import { hasValue } from '@utils/dataOperations';
import { NO_DATA_BADGE } from '@utils/dataOperations';
import { splitAndTrim } from '@utils/strings';
import { FC } from 'react';

interface SiteAddressProps {
  address: OrderDetails['companySiteDetails']['address'];
  wrap?: boolean;
}

const verticalContainerProps: BoxProps = {
  display: 'flex',
  flexDirection: 'column',
};

export const SiteAddress: FC<SiteAddressProps> = ({ address, wrap = false }) => {
  if (!hasValue(address)) {
    return NO_DATA_BADGE;
  }

  if (!wrap) {
    return address;
  }

  return (
    <Box {...verticalContainerProps}>
      {splitAndTrim(address, ',').map((element, index) => (
        <ContentText key={index} fontSize={fontSizes.s}>
          {element}
        </ContentText>
      ))}
    </Box>
  );
};
