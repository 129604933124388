import React from 'react';
import { PersonalDetails } from './components/PersonalDetails';
import { MainContainer } from './style';
import { BusinessPartnersTable } from './components/BusinessPartnersTable';
import { PageHeader } from './PageHeader';
import { Grid, Row, Col } from '@fortum/elemental-ui';

const UserProfile = () => {
  return (
    <>
      <PageHeader />
      <MainContainer>
        <Grid maxWidth="100%" padding={{ m: '0px' }} margin={{ m: '0px' }}>
          <Row>
            <Col l={5}>
              <PersonalDetails />
            </Col>
            <Col l={1}></Col>
            <Col l={6}>
              <BusinessPartnersTable />
            </Col>
          </Row>
        </Grid>
      </MainContainer>
    </>
  );
};

export default UserProfile;
