import styled from 'styled-components';
import { spacing, colors } from '@fortum/elemental-ui';
import { QlikObject } from '@components/qlik';
import { HorizontalContainer, VerticalContainer } from '@components/styles';
import { ErrorView } from '@components/layout';
import { ExportButton } from './components/ExportButton';

export const ROW_GAP_PX = spacing.xxxs;
export const COLUMN_GAP_PX = spacing.xxxs;

export const QlikButton = styled(QlikObject)`
  span {
    font-size: 13px;
  }
`;

export const ReportWrapper = styled(VerticalContainer)`
  flex: 1;

  column-gap: ${COLUMN_GAP_PX};
  row-gap: ${ROW_GAP_PX};
  padding: ${spacing.xxs};
`;

export const HorizontalContainerWithGaps = styled(HorizontalContainer)`
  column-gap: ${COLUMN_GAP_PX};
  row-gap: ${ROW_GAP_PX};
`;

export const VerticalContainerWithGaps = styled(VerticalContainer)`
  row-gap: ${ROW_GAP_PX};
`;

export const HorizontalWrappableContainer = styled(HorizontalContainerWithGaps)`
  flex-wrap: wrap;
`;

export const HeaderContainer = styled(HorizontalContainer)`
  height: 91px;
  min-height: 91px;

  justify-content: space-between;
  align-items: center;
  padding: 0 ${spacing.xs};
`;

export const HeaderButtonsContainer = styled(HorizontalContainer)`
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  column-gap: ${spacing.xxs};
`;

export const ErrorViewWithBackground = styled(ErrorView)`
  background-color: ${colors.snowWhite};
  padding-top: 246px;
`;

export const ExportButtonPositionedRight = styled(ExportButton)`
  margin-left: auto;
`;
