import { DEFAULT_DEBOUNCE_TIME_MS } from '@common/consts';
import { Namespace } from '@config/i18n';
import { useOrderFilters } from '@data/hooks/useOrderFilters';
import { mapToSelectItems, mapToTranslatedSelectItems } from '@utils/dataOperations/multiselect';
import { mapOrderStatusCodesToSelectItems } from '@utils/orders';
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounceValue } from 'usehooks-ts';
import { OrdersFiltersHandlerReturnType, SELECT_ITEMS_INITIAL_VALUES, SelectItems } from './types';
import { useFiltersSetters } from './useFiltersSetters';

const debounceOptions: Parameters<typeof useDebounceValue>[2] = { maxWait: 1000, trailing: true };

export const useOrdersFiltersHandler = (
  temporaryState: boolean,
  debounceTimeMS: number | undefined = DEFAULT_DEBOUNCE_TIME_MS,
): OrdersFiltersHandlerReturnType => {
  const { i18n } = useTranslation<Namespace>('orders');

  const { selectedFiltersValues, setters, reset, setAllExceptSearch, filtersPrecedence, setAll } =
    useFiltersSetters(temporaryState);

  const [debouncedSelectedFiltersValuesWithPrecedence, setDebouncedSelectedFiltersValuesWithPrecedence] = useDebounceValue(
    useMemo(() => ({ selectedFiltersValues, filtersPrecedence }), []),
    debounceTimeMS,
    debounceOptions,
  );

  useEffect(() => {
    setDebouncedSelectedFiltersValuesWithPrecedence({ selectedFiltersValues, filtersPrecedence });
  }, [selectedFiltersValues]);

  const {
    data: availableFilters,
    isLoading,
    isError,
  } = useOrderFilters(
    temporaryState,
    debouncedSelectedFiltersValuesWithPrecedence.filtersPrecedence,
    debouncedSelectedFiltersValuesWithPrecedence.selectedFiltersValues,
  );
  const [selectItems, setSelectItems] = useState<SelectItems>(SELECT_ITEMS_INITIAL_VALUES);

  useEffect(() => {
    if (!availableFilters) return;

    setSelectItems({
      orderStatus: mapOrderStatusCodesToSelectItems(availableFilters.orderStatus),
      orderType: mapToTranslatedSelectItems(availableFilters.orderType, 'domain', 'orderTypes'),
      city: mapToSelectItems(availableFilters.city),
      containerType: mapToSelectItems(availableFilters.containerType),
      transportType: mapToTranslatedSelectItems(availableFilters.transportType, 'domain', 'transportType'),
      businessPartner: mapToSelectItems(availableFilters.businessPartner),
      wasteDescription: mapToSelectItems(availableFilters.wasteDescription),
      timePeriod: mapToTranslatedSelectItems(availableFilters.timePeriod, 'orders', 'filters.timePeriod.values'),
    });
  }, [availableFilters, i18n.language]);

  useLayoutEffect(() => {
    reset();
  }, [i18n.language]);

  return {
    selectedFiltersValues,
    setters,
    selectItems,
    reset,
    setAllExceptSearch,
    filtersPrecedence,
    setAll,
    isLoading,
    isError,
    debouncedSelectedFiltersValuesWithPrecedence,
  };
};
