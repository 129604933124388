import { TEMPORARY_ORDER_NO_SUFFIX } from '@config/order';
import { HandlingStatusMap } from '@config/order';
import { HandlingStatusesGroups, HandlingStatusGroup } from '@models/orders';
import { translate } from './internationalization';
import { t } from 'i18next';
import { hasValue, mapToTranslatedSelectItems } from './dataOperations';
import { SelectItem } from '@fortum/elemental-ui';
import { uniq, compact } from 'lodash';
import { OrdersSelectedFiltersWithSearch } from '@models/filters';
import { OrdersFiltersHandlerReturnType } from '@routes/orders/components/useOrdersFiltersHandler';

export const isTemporaryOrderNo = (orderNo: string, temporaryOrderNoSuffix = TEMPORARY_ORDER_NO_SUFFIX) =>
  orderNo.endsWith(temporaryOrderNoSuffix);

export const isNewlyCreatedOrder = (handlingStatusCode: string) => HandlingStatusMap.NEWLY_CREATED.includes(handlingStatusCode);

export const getStatusGroup = (orderStatusCode: string) =>
  HandlingStatusesGroups.find(statusGroup => HandlingStatusMap[statusGroup].includes(orderStatusCode));

export const translateOrderStatusCode = (orderStatusCode: string) => {
  const handlingStatusGroup = getStatusGroup(orderStatusCode);

  return handlingStatusGroup && translate(handlingStatusGroup, 'domain', 'orderHandlingStatus');
};

export const getOrderExecutionDate = (row: { executionDate: string; orderHandlingStatus: string }) =>
  HandlingStatusMap.IN_PROCESS.includes(row.orderHandlingStatus) && hasValue(row.executionDate)
    ? `${row.executionDate.slice(0, 10)} ${t('common:estimation')}`
    : row.executionDate.slice(0, 10);

export const mapOrderStatusCodesToSelectItems = (statusCodes: string[]): SelectItem<HandlingStatusGroup>[] => {
  const statusGroups = uniq(compact(statusCodes.map(getStatusGroup)));

  return mapToTranslatedSelectItems(statusGroups, 'domain', 'orderHandlingStatus');
};

export const getSelectedFiltersAmount = (selectedFiltersValues: OrdersSelectedFiltersWithSearch, searchIsCounted = false) =>
  Object.entries(selectedFiltersValues)
    .map(([key, values]) => {
      if (key === 'search') return searchIsCounted ? 1 : 0;

      if (typeof values === 'string' && values.length > 0) {
        return 1;
      }
      return values.length;
    })
    .reduce((prev, current) => prev + current);

export class FiltersAvailabilityValidator {
  constructor(
    private selectItems: OrdersFiltersHandlerReturnType['selectItems'],
    private isLoading: boolean,
    private isError: boolean,
    private precedence: OrdersFiltersHandlerReturnType['filtersPrecedence'],
  ) {}

  filterDisabled = (key: keyof OrdersFiltersHandlerReturnType['selectItems']) => {
    if (this.selectItems[key].length === 0 || (this.isLoading && this.precedence.length === 0) || this.isError) {
      return true;
    }

    return this.isLoading && this.precedence[this.precedence.length - 1].key !== key;
  };
}
