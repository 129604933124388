import { HorizontalContainer, VerticalContainer } from '@components/styles';
import { ButtonProps, colors, InputFieldProps, spacing } from '@fortum/elemental-ui';
import styled from 'styled-components';

export const NOTIFICATION_MAX_WIDTH_REM = '30rem';

export const Container = styled(VerticalContainer)`
  flex: 1;
  background-color: ${colors.snowWhite};
`;

export const FormContainer = styled(VerticalContainer)`
  gap: ${spacing.xs};
`;

export const buttonStyles: ButtonProps = {
  variant: 'condensed',
  alignSelf: 'end',
  mt: spacing.xxxs,
  minWidth: '30%',
};

export const TilesContainer = styled(HorizontalContainer)`
  padding: 48px ${spacing.xs};
  gap: ${spacing.xs};

  & > div {
    flex: 1;
    height: fit-content;
  }
`;

export const inputFieldStyles: Pick<InputFieldProps, 'size'> = {
  size: 'm',
};
