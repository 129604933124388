import { ContentText, Placeholder } from '@fortum/elemental-ui';
import { FC, ReactNode } from 'react';
import { ChildrenWrapper, labelStyles, ListItemWrapper, placeholderStyles } from './styles';
import { Stylable, Testable } from '@components/types';

interface VerticalListItemProps extends Testable, Stylable {
  label: string;
  children: ReactNode;
  displayPlaceholder?: boolean;
}

export const VerticalListItem: FC<VerticalListItemProps> = ({
  label,
  children,
  'data-testid': testId,
  className,
  displayPlaceholder,
}) => (
  <ListItemWrapper className={className} data-testid={testId}>
    <ContentText {...labelStyles}>{label}</ContentText>

    {displayPlaceholder ? <Placeholder {...placeholderStyles} /> : <ChildrenWrapper>{children}</ChildrenWrapper>}
  </ListItemWrapper>
);
