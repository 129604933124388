import {
  countAllMissingRequiredFields,
  setEmptyOrdersForms,
  setSingleOrderForm,
  addNewOrderForm,
  updateOrderForm,
  deleteOrderForm,
} from '@utils/ordersCreation';
import { useCallback, useState } from 'react';
import { CreationForms, FormValues } from 'src/types/ordersCreation';
import { v4 as uuidV4 } from 'uuid';
import { requiredFields } from '../config';
import { ServiceSummary } from 'src/types/ordersCreation';

export const useOrdersCreation = () => {
  const [creationForms, setCreationForms] = useState<CreationForms>([]);

  const clearForms = useCallback(() => setCreationForms([]), []);

  const discardService = useCallback(
    (contractIdentifier: string) =>
      setCreationForms(prev =>
        prev.length === 1 ? [] : prev.filter(creationForm => creationForm.contractIdentifier !== contractIdentifier),
      ),
    [],
  );

  const deleteOrder = useCallback(
    (contractIdentifier: string, formIdentifier: string) =>
      setCreationForms(prev => deleteOrderForm(prev, contractIdentifier, formIdentifier)),
    [],
  );

  const setValue = useCallback(
    <K extends keyof FormValues>(fieldKey: K, value: FormValues[K], formIdentifier: string, contractIdentifier: string) =>
      setCreationForms(prev =>
        prev.map(creationForm =>
          creationForm.contractIdentifier !== contractIdentifier
            ? creationForm
            : {
                ...creationForm,
                forms: updateOrderForm(creationForm.forms, formIdentifier, fieldKey, value),
              },
        ),
      ),
    [],
  );

  const createEmptyCreationForm = useCallback(
    (contractIdentifier: string) => setCreationForms(prev => setEmptyOrdersForms(prev, contractIdentifier)),
    [],
  );

  const setInitialOrderFormWithContractDetails = useCallback(
    (contractIdentifier: string, serviceSummary: ServiceSummary) =>
      setCreationForms(prev => setSingleOrderForm(prev, uuidV4(), contractIdentifier, serviceSummary)),
    [],
  );

  const initializeNewOrderForm = useCallback(
    (contractIdentifier: string, serviceSummary: ServiceSummary) =>
      setCreationForms(prev => addNewOrderForm(prev, uuidV4(), contractIdentifier, serviceSummary)),
    [],
  );

  const countMissingRequiredFields = useCallback(
    () => countAllMissingRequiredFields(creationForms, requiredFields),
    [creationForms],
  );

  return {
    creationForms,
    setValue,
    initializeNewOrderForm,
    createEmptyCreationForm,
    countMissingRequiredFields,
    clearForms,
    setInitialOrderFormWithContractDetails,
    deleteOrder,
    discardService,
  };
};
