import { useBookmarksList } from './hooks/useBookmarksList';
import { useBookmarksManagement } from './hooks/useBookmarksManagement';
import i18n from '@config/i18n';
import { createContext, FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

interface BookmarksContextProps {
  createBookmark: (title: string, description: string) => Promise<void>;
  selectBookmark: (id: string) => Promise<void>;
  selectedBookmark: string;
  bookmarksFetchingResult: {
    qBookmarks: enigmaJS.QBookmark[];
    isError: boolean;
    isLoading: boolean;
  };
  removeBookmark: (bookmarkId: string, bookmarkTitle: string) => Promise<void>;
}

export const BookmarksContext = createContext<BookmarksContextProps | null>(null);

interface BookmarksContextProviderProps {
  children: ReactNode;
  qSelections: enigmaJS.QSelection[];
}

export const BookmarksContextProvider: FC<BookmarksContextProviderProps> = ({ children, qSelections }) => {
  const { qBookmarks, isError, isLoading } = useBookmarksList();
  const bookmarksManagement = useBookmarksManagement();
  const bookmarkJustAppliedRef = useRef<boolean>(false);

  const [selectedBookmark, setSelectedBookmark] = useState<string>('');

  useEffect(() => {
    setSelectedBookmark('');
  }, [i18n.language]);

  useEffect(() => {
    if (!qSelections) {
      return;
    }
    if (bookmarkJustAppliedRef.current === true) {
      bookmarkJustAppliedRef.current = false;
      return;
    }

    setSelectedBookmark('');
  }, [qSelections]);

  const selectBookmark = useCallback(
    async (id: string) => {
      setSelectedBookmark(id);
      if (!id) {
        return Promise.resolve();
      }
      return bookmarksManagement
        .applyBookmark(id)
        .then(success => {
          if (!success) {
            throw Error('Failed to apply bookmark');
          }
          bookmarkJustAppliedRef.current = true;
        })
        .catch(() => {
          setSelectedBookmark('');
          throw Error('Failed to apply bookmark');
        });
    },
    [bookmarksManagement.applyBookmark],
  );

  const createBookmark = useCallback(
    async (title: string, description: string) =>
      bookmarksManagement.createBookmark(title, description).then(id => {
        setSelectedBookmark(id);
      }),
    [bookmarksManagement.createBookmark],
  );

  const removeBookmark = useCallback(
    async (bookmarkId: string, bookmarkTitle: string) =>
      bookmarksManagement.removeBookmark(bookmarkId).then(success => {
        if (!success) {
          throw Error(`Failed to remove a bookmark ${bookmarkTitle}`);
        }
        if (bookmarkId === selectedBookmark) {
          setSelectedBookmark('');
        }
      }),
    [selectedBookmark],
  );

  const bookmarksFetchingResult = useMemo(
    () => ({
      qBookmarks,
      isError,
      isLoading,
    }),
    [qBookmarks, isError, isLoading],
  );

  const providerValue = useMemo(() => {
    return {
      createBookmark,
      selectBookmark,
      selectedBookmark,
      bookmarksFetchingResult,
      removeBookmark,
    };
  }, [selectBookmark, createBookmark, selectedBookmark, bookmarksFetchingResult, removeBookmark]);

  return <BookmarksContext.Provider value={providerValue}>{children}</BookmarksContext.Provider>;
};
