import { PageHeader } from '@components/PageHeader';
import { VerticalContainer } from '@components/styles';
import { ButtonProps, ContentTextProps, IconProps, LinkProps, colors, fontWeights, spacing } from '@fortum/elemental-ui';
import styled from 'styled-components';

export const Container = styled(VerticalContainer)`
  gap: ${spacing.xxs};
`;

export const buttonStyles: ButtonProps = {
  status: 'secondary',
  variant: 'condensed',
  alignSelf: 'flex-start',
};

export const mediumTextStyles: ContentTextProps = {
  fontWeight: fontWeights.medium,
  mr: spacing.xxxxs,
};

export const textStyles: ContentTextProps = {
  display: 'inline-flex',
};

export const errorTextStyles: ContentTextProps = {
  color: colors.punchRed,
};

export const successTextStyles: ContentTextProps = {
  color: colors.oceanGreen,
};

const iconStyles: IconProps = {
  color: colors.oceanGreen,
  mr: spacing.xxxs,
};

export const crossIconStyles: IconProps = {
  ...iconStyles,
  color: colors.punchRed,
};

export const checkIconStyles: IconProps = {
  ...iconStyles,
  color: colors.oceanGreen,
};

export const StyledCommonPageHeader = styled(PageHeader)`
  background-color: ${colors.snowWhite};
  padding-left: 0px;
  padding-right: 0px;
`;

export const linkStyles: LinkProps = {
  ml: spacing.xxxxs,
  color: errorTextStyles.color,
};
