import { ContractLocationsResponse } from '@models/contract';
import { UserAccess } from '@models/user';
import { postData } from '@utils/data';
import { isUserAccessOfInternal } from '@utils/user';
import { Headers as HeadersConstants, MimeTypes } from 'http-constants-ts';
import { urls } from './config';

export const fetchContractLocations = async (userAccess: UserAccess, language: string): Promise<ContractLocationsResponse> => {
  if (!urls.locationsAdmin) throw Error('Full sites addresses url not found');
  if (!urls.locations) throw Error('Full sites addresses url not found');

  const contentTypeHeader = new Map([[HeadersConstants.CONTENT_TYPE, MimeTypes.APPLICATION_JSON]]);

  if (isUserAccessOfInternal(userAccess)) {
    return postData<ContractLocationsResponse>(
      `${urls.locationsAdmin}`,
      JSON.stringify({
        language,
        businessPartnersIds: userAccess.selectedBusinessPartners.map(bp => bp.id),
      }),
      contentTypeHeader,
    );
  } else {
    return postData<ContractLocationsResponse>(`${urls.locations}`, JSON.stringify({ language }), contentTypeHeader);
  }
};
