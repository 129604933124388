import { PageHeader, PendingRequestNote } from '@components/index';
import { colors, ContentTextProps, LinkProps } from '@fortum/elemental-ui';
import styled from 'styled-components';

export const PositionedPendingRequestView = styled(PendingRequestNote)`
  padding-top: 160px;
`;

export const messageWrappingTextStyles: ContentTextProps = {
  color: 'inherit',
  fontSize: 'inherit',
};

export const linkStyles: LinkProps = {
  fontSize: 'inherit',
  noUnderline: true,
  marginLeft: '2px',
};

export const StyledPageHeader = styled(PageHeader)`
  background-color: ${colors.snowWhite};
  padding-left: 0px;
  padding-right: 0px;
`;
