import { Namespace } from '@config/i18n';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from './styles';
import { ContentText, IconDocuments, colors } from '@fortum/elemental-ui';
import { VerticalContainer } from '@components/styles';

export const SummaryPreviewHeader: FC = () => {
  const { t } = useTranslation<Namespace>('orderCreation');

  return (
    <Container>
      <IconDocuments color={colors.oceanGreen} />

      <VerticalContainer>
        <ContentText size={29}>{t('summaryPage.header.orderPreview')}</ContentText>
        <ContentText>{t('summaryPage.header.description')}</ContentText>
      </VerticalContainer>
    </Container>
  );
};
