import styled from 'styled-components';
import { COLUMN_GAP_PX, HorizontalContainerWithGaps, HorizontalWrappableContainer } from '../styles';
import { QLIK_SELECTION_OBJECT_WIDTH_NUMERICAL } from '@components/qlik/components/QlikSelectionObject';

export const BottomContainer = styled(HorizontalContainerWithGaps)`
  flex: 1;
`;

export const TopContainer = styled(HorizontalWrappableContainer)`
  --qlik-object-width: ${`${QLIK_SELECTION_OBJECT_WIDTH_NUMERICAL}px`};

  margin-left: calc(var(--qlik-object-width) + ${COLUMN_GAP_PX});
  flex-wrap: wrap;
  align-items: flex-end;
`;
