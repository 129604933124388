import { HeaderCell, HeaderRow, TableTile, ValueCell, ValuesRow } from '@components/TableTile';
import { logEvent } from '@config/azureInsights';
import { Namespace } from '@config/i18n';
import { paths } from '@config/routes';
import { Box, CommonColProps, ContentText, ForwardLink, fontWeights } from '@fortum/elemental-ui';
import { OrderDetails } from '@models/orders';
import { NO_DATA_BADGE, displayedValue, hasValue } from '@utils/dataOperations';
import { translate } from '@utils/internationalization';
import { isTemporaryOrderNo } from '@utils/orders';
import { createContractIdentifier } from '@utils/routes';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { containerProps, headerContainerProps, headerProps, linkProps, orderTypeContainerProps } from './styles';

interface OrderDetailsTileProps {
  generalInfo: OrderDetails['generalInfo'];
}

const colProps: CommonColProps = {
  xxs: 2,
};

export const OrderDetailsTile: FC<OrderDetailsTileProps> = ({ generalInfo }) => {
  const { t } = useTranslation<Namespace[]>(['orderDetails', 'wasteDetails']);
  const navigate = useNavigate();

  const pathToServiceDetails = useMemo<string>(() => {
    const contractIdentifier = createContractIdentifier(generalInfo.dataSource, generalInfo.contractNo);

    return `${paths.services}/${contractIdentifier}`;
  }, [generalInfo.dataSource, generalInfo.contractNo]);

  const handleLinkClick = () => {
    logEvent('orderDetailsServiceDetailsClicked', null);
    navigate(pathToServiceDetails);
  };

  const orderType = hasValue(generalInfo.orderType)
    ? translate(generalInfo.orderType.toUpperCase(), 'orderDetails', 'orderType')
    : NO_DATA_BADGE;

  return (
    <Box {...containerProps} data-testid="order-details-tile">
      <Box {...headerContainerProps}>
        <Box {...orderTypeContainerProps}>
          <ContentText {...headerProps} fontWeight={fontWeights.medium}>{`${t('orderTypeFieldLabel')}: `}</ContentText>

          <ContentText {...headerProps} data-testid="order-type">
            {orderType}
          </ContentText>
        </Box>

        <ForwardLink {...linkProps} onClick={handleLinkClick} data-testid="service-link">
          {t('wasteDetails:wasteServiceDetails')}
        </ForwardLink>
      </Box>

      <TableTile data-testid="order-details-tile">
        <HeaderRow>
          <HeaderCell text={t('orderNo')} gridColumnProps={colProps} />
          <HeaderCell text={t('contractNo')} gridColumnProps={colProps} />
          <HeaderCell text={t('customerOrderNo')} gridColumnProps={colProps} />
          <HeaderCell text={t('tourNo')} gridColumnProps={colProps} />
          <HeaderCell text={t('creationDate')} gridColumnProps={colProps} />
          <HeaderCell text={t('executionDate')} gridColumnProps={colProps} />
        </HeaderRow>

        <ValuesRow>
          <ValueCell
            text={isTemporaryOrderNo(generalInfo.orderNo) ? NO_DATA_BADGE : generalInfo.orderNo}
            gridColumnProps={colProps}
            data-testid="order-no-value-col"
          />

          <ValueCell
            text={displayedValue(generalInfo.contractNo)}
            gridColumnProps={colProps}
            data-testid="contract-no-value-col"
          />

          <ValueCell
            text={displayedValue(generalInfo.customerOrderNo)}
            gridColumnProps={colProps}
            data-testid="customer-no-value-col"
          />

          <ValueCell text={displayedValue(generalInfo.tourNo)} gridColumnProps={colProps} data-testid="tour-no-value-col" />

          <ValueCell
            text={displayedValue(generalInfo.creationDate)}
            gridColumnProps={colProps}
            data-testid="creation-date-value-col"
          />

          <ValueCell
            text={displayedValue(generalInfo.executionDate)}
            gridColumnProps={colProps}
            data-testid="execution-date-value-col"
          />
        </ValuesRow>
      </TableTile>
    </Box>
  );
};
