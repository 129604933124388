import { useRef, useState, useCallback, useEffect } from 'react';

export const useQlikLayout = (
  engine: enigmaJS.IGeneratedAPI | null,
  qProp: Parameters<enigmaJS.SessionObject['createSessionObject']>[0],
) => {
  const isMounted = useRef(true);
  const qSessionObjectRef = useRef<enigmaJS.SessionObject | null>(null);
  const [qLayout, setQLayout] = useState<enigmaJS.Layout | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const update = useCallback(async () => {
    try {
      setError(null);
      setIsLoading(true);
      const _qLayout = await qSessionObjectRef.current?.getLayout();
      if (isMounted.current && _qLayout) {
        setQLayout(_qLayout);
      }
    } catch {
      setError(new Error('Failed to get qLayout'));
      setQLayout(null);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!engine) {
      return;
    }

    (async () => {
      qSessionObjectRef.current = await engine.createSessionObject(qProp);
      qSessionObjectRef.current?.on('changed', () => {
        update();
      });

      update();
    })();
  }, [engine]);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return { qLayout, error, isLoading };
};
