export const getCurrentSelections = async (engine: enigmaJS.IGeneratedAPI) => {
  const sessionObject = await engine.createSessionObject({
    qInfo: { qType: 'SelectionObject' },
    qSelectionObjectDef: {},
  });

  const layout = await sessionObject.getLayout();

  return layout.qSelectionObject?.qSelections;
};
