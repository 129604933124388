import { getEnvProperty } from '@utils/general';

const reactAppApiUrl = getEnvProperty('REACT_APP_API_URL');

const baseUrlV1 = reactAppApiUrl ? `${reactAppApiUrl}/rwportal-orders/v1` : undefined;
const baseUrlV3 = reactAppApiUrl ? `${reactAppApiUrl}/rwportal-orders/v3` : undefined;

export const OrderService = {
  orderInfoAdmin: baseUrlV3 ? `${baseUrlV3}/orders/info` : null,
  orderInfo: baseUrlV3 ? `${baseUrlV3}/orders/info/own` : null,
  ordersFilters: baseUrlV3 ? `${baseUrlV3}/orders/filters/own` : null,
  ordersFiltersForAdmin: baseUrlV3 ? `${baseUrlV3}/orders/filters` : null,
  orderDetails: baseUrlV3 ? `${baseUrlV3}/orders` : null,
  ordersCreation: baseUrlV3 ? `${baseUrlV3}/orders/create` : null,
  orderNoChange: baseUrlV1 ? `${baseUrlV1}/backoffice/orderNo/update` : null,
  orderManagement: baseUrlV1 ? `${baseUrlV1}/backoffice/order` : null,
};
