import { SupportedLanguage } from '@common/languages';
import { QlikReportConfig, QlikConfig } from 'src/types/qlik';
import { getQlikConnectionConfig } from '../connection';
import { CostReportConfig, CostReportObjects } from './types';
import { costReportConfigSchemaValidator } from './schemas';
import { createSchemaErrorMessage, getReportConfig } from '@utils/qlik';

export * from './types';

const validateSchemas = (): QlikConfig<CostReportConfig, CostReportObjects> => {
  const connectionConfig = getQlikConnectionConfig();

  if (!process.env.REACT_APP_COST_REPORT_CONFIG) {
    throw Error('Cost report config not found');
  }

  const reportConfig = JSON.parse(process.env.REACT_APP_COST_REPORT_CONFIG);

  const validate = costReportConfigSchemaValidator();

  if (!validate(reportConfig)) {
    throw Error('Report config does not match the schema: ' + createSchemaErrorMessage(validate.errors));
  }

  return { reportConfig, connectionConfig };
};

export const getCostReportConfig = (lang: SupportedLanguage): QlikReportConfig<CostReportObjects> => {
  const validatedSchemas = validateSchemas();

  return getReportConfig(validatedSchemas, lang);
};
