const CookieCategoriesCodes = ['C0001', 'C0002', 'C0003', 'C0004', 'C0005'] as const;
const CookieCategories = [
  'NECESSARY_COOKIES',
  'PERFORMANCE_COOKIES',
  'FUNCTIONAL_COOKIES',
  'TARGETING_COOKIES',
  'SOCIAL_MEDIA_COOKIES',
] as const;

export const CookieCategoriesMapping: {
  [key in (typeof CookieCategories)[number]]: (typeof CookieCategoriesCodes)[number];
} = {
  NECESSARY_COOKIES: 'C0001',
  PERFORMANCE_COOKIES: 'C0002',
  FUNCTIONAL_COOKIES: 'C0003',
  TARGETING_COOKIES: 'C0004',
  SOCIAL_MEDIA_COOKIES: 'C0005',
};

export const AzureCookiesSubcategory = 'H61';
