export interface ExactAddress {
  name: string;
  locationCode: string;
  numberOfContracts: number;
  totalNumberOfContracts: number;
}

export interface Address {
  name: string;
  exactAddresses: ExactAddress[];
  numberOfContracts: number;
  totalNumberOfContracts: number;
}

export interface Company {
  name: string;
  addresses: Address[];
}

export interface City {
  name: string;
  companies: Company[];
}

export interface Service {
  cities: City[];
}

export interface Services {
  services: ServiceType[];
}

export interface ServiceType {
  type: string;
  contracts: Detail[];
}

export interface Detail {
  dataSource: string;
  wasteDescription: string;
  containerType: string;
  transportType: string;
  cycleType: string;
  queue: number;
  contractNo: string;
}

export const serviceTypeValues = ['Waste collection service', 'Waste receiving service', 'Equipment sales service'] as const;

export type ServiceTypeValue = (typeof serviceTypeValues)[number];
