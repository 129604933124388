import { QueryOptions } from './types';
import { QueryKeys } from '@common/query';
import { fetchContractLocations } from '@data/api/contractLocations';
import { useQuery } from '@tanstack/react-query';
import { UserAccess } from '@models/user';
import { getUserAccessInfo, isUserAccessOfInternal } from '@utils/user';
import { useTranslation } from 'react-i18next';
import { ContractLocationsResponse } from '@models/contract';
import { shouldRetry } from '@utils/data';

export const locationsQuery = (rules: UserAccess, language: string): QueryOptions<ContractLocationsResponse> => ({
  queryKey: isUserAccessOfInternal(rules)
    ? [QueryKeys.contractLocations, rules.selectedBusinessPartners.map(bp => bp.id).join(','), language]
    : [QueryKeys.contractLocations, language],
  queryFn: () => fetchContractLocations(rules, language),
  refetchOnWindowFocus: false,
  retry: shouldRetry,
});
export const useLocations = () => {
  const { i18n } = useTranslation();
  return useQuery<ContractLocationsResponse, Error>(locationsQuery(getUserAccessInfo(), i18n.language));
};
