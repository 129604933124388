import { colors, ContentText, fontSizes, fontWeights, IconContactByPhone, spacing } from '@fortum/elemental-ui';
import { ContactDetailsContainer, OpeningHoursContainer } from './styles';
import { useUserInfo } from '@data/hooks/useUserInfo';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { customerServiceCountryCodes } from '@common/languages';
import { translate } from '@utils/internationalization';
import { FC, useMemo } from 'react';

/**
 * user details error is not handled here, because it is handled on the application start.
 * If this request returns an error, a general 401 error view is displayed on the whole page.
 * Same about loading - the value retrieved on the app start is stored in the cache and read in this view
 */
export const ContactDetails: FC = () => {
  const { data: user } = useUserInfo();
  const { t, i18n } = useTranslation<Namespace>('contactUs');

  const schedule = useMemo<{ key: string; value: string }[]>(() => {
    const countryCode = user?.customerService.countryCode ?? customerServiceCountryCodes[0];

    return t('customerService-' + countryCode + '.schedule', {
      returnObjects: true,
    }) as { key: string; value: string }[];
  }, [i18n.language, user]);

  return (
    <ContactDetailsContainer>
      <IconContactByPhone size={48} color={colors.oceanGreen} />
      <ContentText fontSize={fontSizes.l}>{t('contactByPhoneDescription')}</ContentText>

      {user?.customerService && (
        <>
          <ContentText fontSize={fontSizes.m} fontWeight={fontWeights.medium} mt={spacing.xs}>
            {user.customerService.description}
            {translate(
              'description',
              'contactUs',
              `customerService-${user?.customerService.countryCode ?? customerServiceCountryCodes[0]}`,
            )}
          </ContentText>
          <ContentText fontSize={fontSizes.s}>{user.customerService.email}</ContentText>

          <ContentText fontSize="29px" mt={spacing.xxs} color={colors.cushyBlue}>
            {user.customerService.phone}
          </ContentText>
          <ContentText fontSize={fontSizes.s}>{user.customerService.callCharge}</ContentText>

          <OpeningHoursContainer>
            {schedule.map(item => (
              <ContentText key={`${item.key}-${item.value}`} fontSize={fontSizes.s} color={colors.sonicGrey}>
                {`${item.key} ${item.value}`}
              </ContentText>
            ))}
          </OpeningHoursContainer>
        </>
      )}
    </ContactDetailsContainer>
  );
};
