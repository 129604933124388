import {
  colors,
  DescriptionList,
  fontWeights,
  ForwardLink,
  lineHeights,
  Link,
  Loader,
  px2rem,
  spacing,
  Table,
} from '@fortum/elemental-ui';
import styled from 'styled-components';

export const StyledDescriptionList = styled(DescriptionList)`
  max-width: 495px;

  dt,
  dd {
    font-size: ${spacing.xxs};
  }

  dd {
    font-weight: ${fontWeights.regular};
    width: ${px2rem(287)};
  }
`;

export const StyledTable: typeof Table = styled(Table)`
  max-height: 616px;
  margin-bottom: 25px;

  table {
    width: 100%;
  }

  td {
    font-size: 16px;
    width: 50%;
    line-height: ${lineHeights.normal};
  }

  thead tr th button {
    font-size: 16px;
    font-weight: ${fontWeights.medium};
  }
`;

export const RightCenteredLoader = styled(Loader)`
  & {
    position: absolute;
    top: 50%;
    bottom: 50%;
    right: 30%;
    left: 70%;
    z-index: 1;
  }
`;

export const ContactUsLink = styled(Link)`
  &:visited {
    color: ${colors.cushyBlue};
  }
`;

export const ChangePasswordLink = styled(ForwardLink)`
  &:visited {
    color: ${colors.cushyBlue};
  }
`;
