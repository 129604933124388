import { Namespace } from '@config/i18n';
import { IconProfileOutline, Menu, MenuItemWithIcon } from '@fortum/elemental-ui';
import { useTranslation } from 'react-i18next';
import { FC, SyntheticEvent, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { paths } from '@config/routes';
import { urls } from '@data/api/config';
import { fallbackLang, isSupportedLanguage } from '@common/languages';
import { getPrivacyNoticeURL } from '@common/privacyNotive';
import { User } from '@models/user';
import { getUserName } from '@utils/user';
import { compact } from 'lodash';
import { isUsersManagementToggleOn } from '@utils/featureToggles';

export interface AuthWidgetProps {
  logout: () => void;
  user: User | undefined;
}

type MenuValue = 'user-profile' | 'logout' | 'privacy-notice' | 'terms-and-conditions' | 'users-list';
type AuthWidgetItem = MenuItemWithIcon<MenuValue> | Omit<MenuItemWithIcon<MenuValue>, 'icon'>;

export const AuthWidget: FC<AuthWidgetProps> = ({ user, logout }) => {
  const { t, i18n } = useTranslation<Namespace[]>(['common', 'mainLayout', 'termsAndCondition', 'usersManagement']);
  const navigate = useNavigate();

  const userName = getUserName(user);

  const items: AuthWidgetItem[] = useMemo(
    () =>
      compact([
        { value: 'user-profile', name: t('mainLayout:userProfile') },
        user &&
          isUsersManagementToggleOn(user) && {
            value: 'users-list',
            name: t('usersManagement:appMenuItem.label'),
          },
        {
          value: 'privacy-notice',
          name: t('mainLayout:privacyNotice'),
        },
        {
          value: 'terms-and-conditions',
          name: t('termsAndCondition:termsAndConditions'),
        },
        {
          value: 'logout',
          name: t('common:logout'),
        },
      ]),
    [i18n.language, user],
  );

  const onSelect = useCallback(
    (value: MenuValue | SyntheticEvent<HTMLElement, Event>) => {
      if (typeof value !== 'string') {
        return;
      }

      if (value === 'user-profile') {
        navigate(paths.userProfile);
        return;
      }

      if (value === 'privacy-notice') {
        const currentLanguage = isSupportedLanguage(i18n.language) ? i18n.language : fallbackLang;
        const privacyNoticeURL = getPrivacyNoticeURL(currentLanguage);

        if (!privacyNoticeURL) throw Error('Privacy notice URL not found');

        window.open(privacyNoticeURL, '_blank');
        return;
      }

      if (value === 'terms-and-conditions') {
        if (!urls.termsAndConditions) throw Error('Terms and Conditions URL not found');
        window.open(urls.termsAndConditions, '_blank');
        return;
      }

      if (value === 'users-list') {
        navigate(paths.usersManagement);
        return;
      }

      logout();
    },
    [i18n.language],
  );

  return (
    <Menu id="auth-widget" items={items} label={userName} onSelect={onSelect} icon={<IconProfileOutline />} menuWidth="221px" />
  );
};
