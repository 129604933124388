import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { FC, useMemo } from 'react';
import { User } from '@models/user';
import { Box, ContentText, colors, fontSizes, fontWeights, px2rem } from '@fortum/elemental-ui';
import { customerServiceCountryCodes } from '@common/languages';

interface ServiceHoursProps {
  user: User;
}

export const ServiceHours: FC<ServiceHoursProps> = ({ user }) => {
  const { t, i18n } = useTranslation<Namespace[]>(['contactUs', 'orderCreation']);

  const schedule = useMemo<{ key: string; value: string }[]>(() => {
    const countryCode = user.customerService.countryCode ?? customerServiceCountryCodes[0];
    const countrySpecificSchedule = 'customerService-' + countryCode + '.schedule';

    return t(countrySpecificSchedule, {
      returnObjects: true,
    });
  }, [i18n.language, user]);

  return (
    <Box width={px2rem(147)}>
      <ContentText fontWeight={fontWeights.medium}>{t('orderCreation:footer.serviceHours')}</ContentText>
      {schedule.map(item => (
        <ContentText key={`${item.key}-${item.value}`} fontSize={fontSizes.s} color={colors.sonicGrey}>
          {`${item.key} ${item.value}`}
        </ContentText>
      ))}
    </Box>
  );
};
