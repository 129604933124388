import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { FC } from 'react';
import { User } from '@models/user';
import { Box, ContentText, colors, fontSizes, fontWeights, px2rem } from '@fortum/elemental-ui';

interface PhoneProps {
  phone: User['customerService']['phone'];
}

export const Phone: FC<PhoneProps> = ({ phone }) => {
  const { t } = useTranslation<Namespace[]>(['contactUs', 'orderCreation']);

  return (
    <Box width={px2rem(167)}>
      <ContentText fontWeight={fontWeights.medium}>{t('orderCreation:footer.phone')}</ContentText>
      <ContentText fontSize={fontSizes.l} fontWeight={fontWeights.medium} color={colors.oceanGreen}>
        {phone}
      </ContentText>
    </Box>
  );
};
