import { colors } from '@fortum/elemental-ui';
import styled from 'styled-components';
import { QlikObject } from '../QlikObject';

export const StyledQlikObject: typeof QlikObject = styled(QlikObject)`
  span {
    color: ${colors.inkGrey};
  }

  article {
    border: 0;
  }
`;
