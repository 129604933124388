import { FC } from 'react';
import { useDefaultSelections, useCurrentSelections } from '@components/qlik';
import { ReportProps } from '../types';
import { Co2ReportObjects } from '@config/qlik';
import { ErrorViewWithBackground } from '../styles';
import { PageHeader, SelectionsManagement } from '../components';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { CenteredLoader } from '@components/layout/styles';
import { paths } from '@config/routes';
import { ReportContainer } from './styles';
import { Filters } from './Filters';
import { EmissionOverview } from './EmissionOverview';
import { EmissionInsights } from './EmissionInsights';
import { Table } from './Table';

export const Report: FC<ReportProps<Co2ReportObjects>> = ({
  defaultSelections,
  exportDataHandler,
  mandatorySelections,
  objectIds,
}) => {
  const { t } = useTranslation<Namespace[]>(['reports', 'errors']);

  const { qSelections, clearSelections, isError, isLoading } = useCurrentSelections();

  const { mandatorySelectionsError, mandatorySelectionsValidationInProgress } = useDefaultSelections(
    defaultSelections,
    mandatorySelections,
  );

  if (mandatorySelectionsValidationInProgress) {
    return <CenteredLoader />;
  }

  if (mandatorySelectionsError) {
    return (
      <ErrorViewWithBackground
        header={t('errors:reports.nothingToReport.header')}
        message={t('errors:reports.nothingToReport.message')}
        link={{
          navigatePath: paths.selectCustomer,
          text: t('errors:reports.nothingToReport.linkMessage'),
        }}
      />
    );
  }

  return (
    <>
      <PageHeader title={t('reports:co2.title')} />

      <ReportContainer>
        <SelectionsManagement
          qSelections={qSelections}
          clearSelections={clearSelections}
          isSelectionsFetchingError={isError}
          isSelectionsLoading={isLoading}
        />

        <Filters objectIds={objectIds} />

        <EmissionOverview objectIds={objectIds} />

        <EmissionInsights objectIds={objectIds} />

        <Table objectIds={objectIds} exportDataHandler={exportDataHandler} />
      </ReportContainer>
    </>
  );
};
