import { HorizontalContainer, StyledDescriptionListItem, VerticalContainer, staticBorderStyles } from '@components/styles';
import {
  BoxProps,
  ButtonProps,
  colors,
  ContentTextProps,
  DescriptionList,
  fontSizes,
  fontWeights,
  IconButtonProps,
  IconProps,
  lineHeights,
  PlaceholderProps,
  px2rem,
  spacing,
} from '@fortum/elemental-ui';
import { UserProfileStatus } from '@models/usersManagement';
import styled, { css } from 'styled-components';
import { AdditionalPermissionsSection } from './AdditionalPermissionsSection';
import { FailedToLoadDataNote } from '@components/index';

export const DRAWER_WIDTH = '600px';

export const Container = styled(VerticalContainer)`
  padding: ${spacing.xs} ${spacing.s};
  position: relative;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
  gap: ${spacing.xs};
`;

export const HeaderContainer = styled(HorizontalContainer)`
  justify-content: space-between;
  align-items: center;
`;

export const SectionHeaderContainer = styled(HorizontalContainer)`
  gap: ${spacing.xxxs};
`;

export const closeIconButtonStyles: Omit<IconButtonProps, 'icon'> = {
  status: 'plain',
  color: colors.inkGrey,
  top: '19px',
  right: spacing.xs,
  position: 'absolute',
};

export const sectionIconStyles: IconProps = {
  color: colors.oceanGreen,
  size: 24,
};

const headerTextBasicStyles: ContentTextProps = {
  fontWeight: fontWeights.medium,
};

export const smallHeaderTextStyles: ContentTextProps = {
  ...headerTextBasicStyles,
  fontSize: fontSizes.m,
};

export const mediumHeaderTextStyles: ContentTextProps = {
  ...headerTextBasicStyles,
  fontSize: fontSizes.l,
};

const headerPlaceholderStyles: PlaceholderProps = {
  flex: 0.91,
};

export const smallHeaderPlaceholderStyles: PlaceholderProps = {
  ...headerPlaceholderStyles,
  height: '27px',
};

export const mediumHeaderPlaceholderStyles: PlaceholderProps = {
  ...headerPlaceholderStyles,
  height: '30px',
};

export const MediumDescriptionListItem = styled(StyledDescriptionListItem)`
  dd {
    font-size: ${fontSizes.m.s};
    font-weight: ${fontWeights.medium};
  }
`;

export const StyledDescriptionList = styled(DescriptionList)`
  div > dd,
  div > dt {
    padding-top: ${spacing.xxxxs} !important;
    padding-bottom: ${spacing.xxxxs} !important;
  }
`;

export const DescriptionListContainer = styled.div`
  padding: ${spacing.xs} ${spacing.s} 0;
`;

export const Divider = styled.div`
  border-bottom: ${px2rem(2)} solid ${colors.cloudGrey};
`;

export const listItemWithIconStyles: BoxProps = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '40px',
};

export const labelStyles: ContentTextProps = {
  fontSize: fontSizes.s,
};

export const VerticalContainerWithSmallGap = styled(VerticalContainer)`
  gap: ${spacing.xxxs};
`;

export const VerticalContainerWithSmallGapAndPadding = styled(VerticalContainerWithSmallGap)`
  padding-left: ${spacing.s};
`;

export const smallPaddingBottomBoxProps: BoxProps = {
  padding: `${spacing.xxxs} ${spacing.s} ${spacing.xxs}`,
};

export const ButtonContainer = styled(VerticalContainer)`
  margin-top: auto;
  align-items: end;
`;

export const closeButtonStyles: ButtonProps = {
  minWidth: '40%',
};

export const PermissionsContainer = styled(VerticalContainer)`
  padding-left: ${spacing.s};
`;

export const ShiftedAdditionalPermissionsSection = styled(AdditionalPermissionsSection)`
  padding-left: ${spacing.s};
`;

export const Status = styled.p<{ $status: UserProfileStatus }>`
  ${staticBorderStyles('0px')};
  ${({ $status }) =>
    $status === 'ACTIVE'
      ? css`
          color: ${colors.oceanGreen};
          background-color: rgba(15, 122, 90, 0.1);
        `
      : $status === 'PENDING'
        ? css`
            color: ${colors.fineOrange};
            background-color: rgba(214, 146, 28, 0.1);
          `
        : css`
            color: ${colors.punchRed};
            background-color: rgba(184, 62, 77, 0.1);
          `};

  line-height: ${lineHeights.normal};
  padding: 0 ${spacing.xs};
  width: fit-content;
`;

export const UserRoleContainer = styled(HorizontalContainer)`
  gap: ${spacing.xs};
`;

export const UserRole = styled.p`
  ${staticBorderStyles('0px')};
  color: ${colors.sonicGrey};
  background-color: ${colors.cloudGrey};

  text-align: center;
  line-height: ${lineHeights.normal};
  padding: 0 ${spacing.xs};
`;

export const StyledErrorView = styled(FailedToLoadDataNote)`
  margin-top: 48px;
  margin-bottom: ${spacing.xs};
`;
