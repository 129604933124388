import { SupportedLanguage } from '@common/languages';
import { getQlikConnectionConfig } from '../connection';
import { QlikReportConfig, QlikConfig } from 'src/types/qlik';
import { QtyReportObjects, WasteQtyReportConfig } from './types';
import { wasteQtyReportConfigSchemaValidator } from './schemas';
import { createSchemaErrorMessage, getReportConfig } from '@utils/qlik';

export * from './types';

const validateSchemas = (): QlikConfig<WasteQtyReportConfig, QtyReportObjects> => {
  const connectionConfig = getQlikConnectionConfig();

  if (!process.env.REACT_APP_WASTE_QTY_REPORT_CONFIG) {
    throw Error('Waste report config not found');
  }

  const reportConfig = JSON.parse(process.env.REACT_APP_WASTE_QTY_REPORT_CONFIG);

  const validate = wasteQtyReportConfigSchemaValidator();

  if (!validate(reportConfig)) {
    throw Error('Report config does not match the schema: ' + createSchemaErrorMessage(validate.errors));
  }

  return { reportConfig, connectionConfig };
};

export const getWasteQtyReportConfig = (lang: SupportedLanguage): QlikReportConfig<QtyReportObjects> => {
  const validatedSchemas = validateSchemas();

  return getReportConfig(validatedSchemas, lang);
};
