import { HttpError } from '@common/http';
import { overwriteTemporaryOrderNumber } from '@data/api/order';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { OrderNoUpdateRequestBody } from 'src/types/ordersCreation';

type OrderNumberOverwriteMutationOptions = Omit<
  UseMutationOptions<void, HttpError, OrderNoUpdateRequestBody, unknown>,
  'mutationFn'
>;

export const useOrderNumberOverwriteMutation = (mutationOptions?: OrderNumberOverwriteMutationOptions) =>
  useMutation({
    mutationFn: overwriteTemporaryOrderNumber,
    ...mutationOptions,
  });
