import { QueryOptions } from '@data/hooks/types';
import { QueryKeys } from '@common/query';
import { fetchContractDetails } from '@data/api/contract';
import { useQuery } from '@tanstack/react-query';
import { Contract } from '@models/contract';
import { shouldRetry } from '@utils/data';

const contractDetailsQuery = (
  contractNo: string | null,
  dataSource: string | null,
  language: string,
): QueryOptions<Contract> => ({
  queryKey: [QueryKeys.contractDetails, contractNo, dataSource, language],
  queryFn: () => fetchContractDetails(contractNo!, dataSource, language),
  enabled: !!contractNo,
  refetchOnWindowFocus: false,
  retry: shouldRetry,
});

export const useContractDetails = (contractNo: string | null, dataSource: string | null, language: string) =>
  useQuery<Contract, Error>(contractDetailsQuery(contractNo, dataSource, language));
