import { useMemo } from 'react';
import { getFilterDisplayValue } from './Filters/utils';
import { OrdersFiltersHandlerReturnType } from './useOrdersFiltersHandler';

export const useFiltersDisplayValues = (
  selectedFiltersValues: OrdersFiltersHandlerReturnType['selectedFiltersValues'],
  selectItems: OrdersFiltersHandlerReturnType['selectItems'],
) => {
  const wasteDescriptionDisplayValue = useMemo(
    () => getFilterDisplayValue(selectedFiltersValues.wasteDescription, selectItems.wasteDescription),
    [selectedFiltersValues.wasteDescription, selectItems.wasteDescription],
  );

  const orderStatusDisplayValue = useMemo(
    () => getFilterDisplayValue(selectedFiltersValues.orderStatus, selectItems.orderStatus),
    [selectedFiltersValues.orderStatus, selectItems.orderStatus],
  );

  const orderTypeDisplayValue = useMemo(
    () => getFilterDisplayValue(selectedFiltersValues.orderType, selectItems.orderType),
    [selectedFiltersValues.orderType, selectItems.orderType],
  );

  const containerTypeDisplayValue = useMemo(
    () => getFilterDisplayValue(selectedFiltersValues.containerType, selectItems.containerType),
    [selectedFiltersValues.containerType, selectItems.containerType],
  );

  const transportTypeDisplayValue = useMemo(
    () => getFilterDisplayValue(selectedFiltersValues.transportType, selectItems.transportType),
    [selectedFiltersValues.transportType, selectItems.transportType],
  );

  const businessPartnerDisplayValue = useMemo(
    () => getFilterDisplayValue(selectedFiltersValues.businessPartner, selectItems.businessPartner),
    [selectedFiltersValues.businessPartner, selectItems.businessPartner],
  );

  const cityDisplayValue = useMemo(
    () => getFilterDisplayValue(selectedFiltersValues.city, selectItems.city),
    [selectedFiltersValues.city, selectItems.city],
  );

  return {
    wasteDescriptionDisplayValue,
    orderStatusDisplayValue,
    orderTypeDisplayValue,
    containerTypeDisplayValue,
    transportTypeDisplayValue,
    businessPartnerDisplayValue,
    cityDisplayValue,
  };
};
