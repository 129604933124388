import { FC } from 'react';
import { SectionHeader } from './SectionHeader';
import { Box, colors, ContentText, IconCheck, IconInspection } from '@fortum/elemental-ui';
import { DetailedUserProfile } from '@models/usersManagement';
import { labelStyles, listItemWithIconStyles, PermissionsContainer } from './styles';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { VerticalContainer } from '@components/styles';
import { Placeholders } from '../Placeholders';

interface DefaultPermissionsSectionProps {
  user: DetailedUserProfile | undefined;
  isLoading: boolean;
}

export const DefaultPermissionsSection: FC<DefaultPermissionsSectionProps> = ({ user, isLoading }) => {
  const { t } = useTranslation<Namespace>('usersManagement');

  return (
    <VerticalContainer>
      <SectionHeader icon={<IconInspection />} text={t('detailsPanel.defaultPermissions.header')} size="s" />

      <PermissionsContainer>
        {isLoading ? (
          <Placeholders lines={3} />
        ) : (
          user?.defaultPermissions.map(permission => (
            <Box key={permission} {...listItemWithIconStyles}>
              <ContentText {...labelStyles}>{t(`permissions.${permission}`)}</ContentText>
              <IconCheck color={colors.oceanGreen} />
            </Box>
          ))
        )}
      </PermissionsContainer>
    </VerticalContainer>
  );
};
