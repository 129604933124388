import { verticalContainer } from '@components/styles';
import { spacing } from '@fortum/elemental-ui';
import styled from 'styled-components';

export const ContactDetailsContainer = styled.div`
  ${verticalContainer};
  flex: 1;
  align-items: center;
  max-width: 448px;
`;

export const OpeningHoursContainer = styled.div`
  ${verticalContainer};

  margin-top: ${spacing.xs};

  row-gap: ${spacing.xxxs};

  text-align: center;
`;
