import { NoResultsNote } from '@components/index';
import { FilteringResult } from '@components/layout/FilteringResult';
import { SEARCH_PARAMS } from '@config/routes';
import { ContractsFiltersContext } from '@contexts/ContractsFiltersContext';
import { useServices } from '@data/hooks/useServices';
import { Box, colors } from '@fortum/elemental-ui';
import { ContractLayout } from '@routes/services/components/table/ContractLayout';
import { getContractsAmountInService, groupCitiesByLocationCode } from '@utils/services';
import { Namespace } from 'i18next';
import { FC, useContext, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { LocationsTree } from './components/location/LocationsTree';
import { PageHeader } from './components/PageHeader';
import { Container, ErrorContainer } from './styles';

//TODO: investigate vertical scroll bar being displayed and hidden right after, when opening the accordion
const ServiceContainer: FC = () => {
  const [searchParams] = useSearchParams();
  const locationCode = searchParams.get(SEARCH_PARAMS.locationCode);
  const { t } = useTranslation<Namespace>('services');
  const { debouncedFiltersKeysWithSearch } = useContext(ContractsFiltersContext);
  const pageHeaderRef = useRef<HTMLDivElement>(null);
  const [pageHeaderHeight, setPageHeaderHeight] = useState<string | undefined>(
    pageHeaderRef.current?.clientHeight ? `${pageHeaderRef.current?.clientHeight}px` : undefined,
  );

  useLayoutEffect(() => {
    if (!pageHeaderRef?.current) {
      return;
    }

    const observer = new ResizeObserver(entries => {
      setPageHeaderHeight(entries[0].borderBoxSize[0].blockSize.toString() + 'px');
    });
    observer.observe(pageHeaderRef?.current);

    return () => observer.disconnect();
  }, []);

  const { data: service, fetchStatus, isLoading } = useServices(debouncedFiltersKeysWithSearch);

  const treeDetailsByLocationCode = useMemo(
    () => (service?.cities ? groupCitiesByLocationCode(service.cities) : null),
    [service],
  );

  const location = locationCode && treeDetailsByLocationCode?.[locationCode];

  const serviceLayout = useMemo(() => {
    if (service?.cities && service.cities.length === 0) {
      return (
        <ErrorContainer>
          <NoResultsNote />
        </ErrorContainer>
      );
    }

    if (
      !location &&
      debouncedFiltersKeysWithSearch.search === '' &&
      debouncedFiltersKeysWithSearch.equipments.length === 0 &&
      debouncedFiltersKeysWithSearch.wastes.length === 0
    ) {
      return <FilteringResult message={t('selectLocationOrFilter')} />;
    }

    if (
      !location &&
      service?.cities &&
      service.cities.length > 0 &&
      (debouncedFiltersKeysWithSearch.search ||
        debouncedFiltersKeysWithSearch.equipments ||
        debouncedFiltersKeysWithSearch.wastes)
    ) {
      return (
        <FilteringResult
          title={t('servicesFound', { amount: getContractsAmountInService(service) })}
          message={t('filterOrSearchIsDone')}
          isSuccessful={true}
        />
      );
    }
    if (location) {
      return <ContractLayout location={location} filters={debouncedFiltersKeysWithSearch} />;
    }

    return <FilteringResult message={t('selectLocationOrFilter')} />;
  }, [
    location,
    debouncedFiltersKeysWithSearch.search,
    debouncedFiltersKeysWithSearch.wastes,
    debouncedFiltersKeysWithSearch.equipments,
    service?.cities,
  ]);

  return (
    <Container>
      <PageHeader pageName={t('header')} ref={pageHeaderRef} />

      <Box display="flex" flexDirection="row" flex={1}>
        <LocationsTree
          pageHeaderHeight={pageHeaderHeight}
          cities={service?.cities}
          isLoading={isLoading || fetchStatus !== 'idle'}
        />

        <Box display="flex" flexGrow={1} backgroundColor={colors.snowWhite} flexDirection="column" overflowX="auto">
          {serviceLayout}
        </Box>
      </Box>
    </Container>
  );
};

export default ServiceContainer;
