import { FailedToLoadDataNote, NoResultsNote } from '@components/index';
import { Namespace } from '@config/i18n';
import {
  Box,
  ContentText,
  IconAdd,
  IconCross,
  IconSearch,
  IconSubtract,
  InputField,
  colors,
  fontSizes,
  fontWeights,
  spacing,
} from '@fortum/elemental-ui';
import { BusinessPartner } from '@models/user';
import { BusinessPartnerRow, Container } from '@routes/customer/style';
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { AutoSizer } from 'react-virtualized';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';
import { bpsSelectionItemDisabled } from '@utils/user';
import { ErrorContainer } from './styles';

interface Props {
  businessPartners: BusinessPartner[];
  groupedBP: Record<string, BusinessPartner>;
  selectedBps: BusinessPartner[];
  searchValue: string;
  search: (val: string) => void;
  deselect: (bp: BusinessPartner) => void;
  select: (bp: BusinessPartner) => void;
  hasError?: boolean;
}

const ListComponent: FC<Props> = ({
  searchValue,
  businessPartners,
  groupedBP,
  search,
  selectedBps,
  deselect,
  select,
  hasError,
}) => {
  const { t } = useTranslation<Namespace>('businessPartners');

  return (
    <Container pv={spacing.xs} ph={spacing.s}>
      <InputField
        pb={spacing.xs}
        id="filterBusinessPartners"
        name={'filterBusinessPartners'}
        label={t('searchBusinessPartner')}
        width="100%"
        rightIcon={searchValue !== '' ? <IconCross onClick={() => search('')} /> : undefined}
        leftIcon={<IconSearch />}
        value={searchValue}
        onRightIconClick={() => search('')}
        onChange={event => search(event.target.value)}
      />
      <Box height="50vh" display="flex" flex={1} flexDirection="column">
        {hasError ? (
          <ErrorContainer>
            <FailedToLoadDataNote />
          </ErrorContainer>
        ) : businessPartners.length === 0 ? (
          <ErrorContainer>
            <NoResultsNote />
          </ErrorContainer>
        ) : (
          <ListContent
            groupedBP={groupedBP}
            selectedBps={selectedBps}
            deselect={deselect}
            select={select}
            businessPartners={businessPartners}
          />
        )}
      </Box>
    </Container>
  );
};

type ListContentProps = Pick<Props, 'groupedBP' | 'selectedBps' | 'deselect' | 'select' | 'businessPartners'>;

const ListContent: FC<ListContentProps> = ({ groupedBP, selectedBps, deselect, select, businessPartners }) => (
  <AutoSizer>
    {({ height, width }) => (
      <List
        innerElementType={'ul'}
        itemData={businessPartners}
        itemCount={businessPartners.length}
        itemSize={90}
        height={height}
        width={width}
      >
        {({ data, index, style }) => (
          <ListItem
            data={data}
            index={index}
            style={style}
            groupedBP={groupedBP}
            selectedBps={selectedBps}
            deselect={deselect}
            select={select}
          />
        )}
      </List>
    )}
  </AutoSizer>
);

type ListItemProps = ListChildComponentProps & Pick<ListContentProps, 'groupedBP' | 'selectedBps' | 'deselect' | 'select'>;

const ListItem: FC<ListItemProps> = ({ data, index, style, groupedBP, selectedBps, deselect, select }) => {
  const letter = data[index].name[0].toUpperCase();
  const key = `${data[index].name}-${data[index].id}-${index}`;

  const handleItemClick = () => {
    if (selectedBps.find(bp => bp.id === data[index].id)) deselect(data[index]);
    else select(data[index]);
  };

  return (
    <li style={style} id={key}>
      {groupedBP[letter].id === data[index].id && (
        <ContentText pl={spacing.xxxxs} fontSize={fontSizes.xl} color={colors.inkGrey} fontWeight={fontWeights.medium}>
          {letter}
        </ContentText>
      )}
      <BusinessPartnerRow
        $disabled={bpsSelectionItemDisabled(selectedBps, data[index].id, data[index].name)}
        onClick={handleItemClick}
        key={key}
      >
        <Box paddingTop="2px">
          {selectedBps.some(selectedBP => selectedBP.id === data[index].id && selectedBP.name === data[index].name) ? (
            <IconSubtract
              size={24}
              color={bpsSelectionItemDisabled(selectedBps, data[index].id, data[index].name) ? colors.fogGrey : colors.oceanGreen}
            />
          ) : (
            <IconAdd
              size={24}
              color={bpsSelectionItemDisabled(selectedBps, data[index].id, data[index].name) ? colors.fogGrey : colors.oceanGreen}
            />
          )}
        </Box>
        <Box pl={spacing.xxs}>
          <ContentText fontSize={fontSizes.l} color={colors.inkGrey}>
            {data[index].name}
          </ContentText>
          <ContentText fontSize={fontSizes.l} color={colors.sonicGrey}>
            {data[index].id}
          </ContentText>
        </Box>
      </BusinessPartnerRow>
    </li>
  );
};

export const ListLayout = memo(ListComponent);
