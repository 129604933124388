import { TabItem } from '@fortum/elemental-ui';
import { ServiceType, ServiceTypeValue } from '@models/service';
import { camelCase } from 'lodash';
import { translate } from './internationalization';
import { isSupportedServiceTypeValue } from './contract';

export type ContractTypeTab = TabItem<string> & {
  type: ServiceType['type'];
  service: ServiceType;
};

const getTabName = (serviceType: string, contractsLength: number) => {
  const contractType = translate(camelCase(serviceType), 'domain', 'contractType');

  return `${contractType} (${contractsLength})`;
};

const servicesTabsSortingFunction = (
  firstServiceItem: ContractTypeTab,
  secondServiceItem: ContractTypeTab,
  servicesSortingOrder: ServiceTypeValue[],
) => {
  const firstIndex = isSupportedServiceTypeValue(firstServiceItem.type)
    ? servicesSortingOrder.indexOf(firstServiceItem.type)
    : servicesSortingOrder.length;

  const secondIndex = isSupportedServiceTypeValue(secondServiceItem.type)
    ? servicesSortingOrder.indexOf(secondServiceItem.type)
    : servicesSortingOrder.length;

  return firstIndex - secondIndex;
};

// TODO: rename ServiceType to sth more accurate
export const mapToSortedTabItems = (services: ServiceType[], servicesSortingOrder: ServiceTypeValue[]): ContractTypeTab[] =>
  services
    .map((service, index) => ({
      id: `${service.type}-${index}`,
      name: getTabName(service.type, service.contracts.length),
      type: service.type,
      service,
    }))
    .sort((a, b) => servicesTabsSortingFunction(a, b, servicesSortingOrder));

export const getActiveTab = (tabs: ContractTypeTab[], activeTabFromSearchParams: string | null, activeTab: string) =>
  tabs.find(tab => tab.id === activeTabFromSearchParams || tab.id === activeTab)?.id || tabs[0]?.id;
