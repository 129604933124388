import { CenteredLoader } from '@components/layout/styles';
import { LocationContainer } from '@routes/services/styles';
import { hasContracts } from '@utils/services';
import { FC, memo } from 'react';
import { CityLayout } from './CityLayout';
import { City } from '@models/service';

interface LocationsTreeProps {
  pageHeaderHeight: string | undefined;
  isLoading: boolean;
  cities: City[] | undefined;
}

export const LocationsTree: FC<LocationsTreeProps> = memo(({ pageHeaderHeight, isLoading, cities }) => (
  <LocationContainer id="locations" $pageHeaderHeight={pageHeaderHeight}>
    {isLoading || !cities ? (
      <CenteredLoader />
    ) : (
      cities.filter(hasContracts).map((city, index) => <CityLayout id={city.name} key={`${city.name}-${index}`} city={city} />)
    )}
  </LocationContainer>
));

LocationsTree.displayName = 'LocationsTree';
