import { getEnvProperty } from '@utils/general';

const reactAppApiUrl = getEnvProperty('REACT_APP_API_URL');
const baseUrlV1 = reactAppApiUrl ? `${reactAppApiUrl}/rwportal-users/v1` : undefined;
const baseUrlV3 = reactAppApiUrl ? `${reactAppApiUrl}/rwportal-users/v3` : undefined;
const baseUrlV4 = reactAppApiUrl ? `${reactAppApiUrl}/rwportal-users/v4` : undefined;

export const UserService = {
  userInfo: baseUrlV4 ? `${baseUrlV4}/user/details` : null,
  userLogOut: baseUrlV4 ? `${baseUrlV4}/user/logout` : null,
  userAgreement: baseUrlV4 ? `${baseUrlV4}/user/agreement` : null,
  customerServiceContact: baseUrlV3 ? `${baseUrlV3}/contact/customer-service` : null,
  customerContactsByBusinessPartner: baseUrlV3 ? `${baseUrlV3}/contact/by-business-partner` : null,
  passwordChange: baseUrlV4 ? `${baseUrlV4}/user/change-password` : null,
  usersList: baseUrlV1 ? `${baseUrlV1}/users` : null,
  usersListFilters: baseUrlV1 ? `${baseUrlV1}/users/companies` : null,
};
