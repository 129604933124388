import { FC } from 'react';
import { HeaderContainer, headerTextStyles, iconStyles } from './styles';
import { BoxProps, ComponentOrElement, ContentText, IconProps, renderComponentOrElement } from '@fortum/elemental-ui';
import { Stylable, Testable } from '@components/types';

interface PageHeaderProps extends Stylable, Testable {
  header: string;
  icon?: ComponentOrElement<IconProps>;
  componentRight?: ComponentOrElement<BoxProps> | null;
}

export const PageHeader: FC<PageHeaderProps> = ({ header, icon, componentRight, className, 'data-testid': dataTestId }) => (
  <HeaderContainer className={className} data-testid={dataTestId}>
    {icon && renderComponentOrElement(icon, { ...iconStyles })}

    <ContentText {...headerTextStyles}>{header}</ContentText>

    {componentRight && renderComponentOrElement(componentRight, {})}
  </HeaderContainer>
);
