import { HeaderCell, HeaderRow, TableTile, ValueCell, ValuesRow } from '@components/TableTile';
import { logEvent } from '@config/azureInsights';
import { Namespace } from '@config/i18n';
import { paths } from '@config/routes';
import { Box, Button, ForwardLink, IconAdd, spacing } from '@fortum/elemental-ui';
import { GeneralInfo } from '@models/contract';
import { displayedValue } from '@utils/dataOperations';
import { isOrdersCreationToggleOn } from '@utils/featureToggles';
import { translate } from '@utils/internationalization';
import { shouldAllowOrdersCreation } from '@utils/services';
import { camelCase } from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { buttonProps, contractNoColProps, linkBoxProps, linkProps, openOrdersColProps, serviceTypeColProps } from './styles';
import { useUserInfo } from '@data/hooks/useUserInfo';

interface ContractDetailsTileProps {
  generalInfo: GeneralInfo;
  numberOfOrders: number;
  contractIdentifier: string;
}

export const ContractDetailsTile: FC<ContractDetailsTileProps> = ({ generalInfo, numberOfOrders, contractIdentifier }) => {
  const { data: user } = useUserInfo();
  const { t } = useTranslation<Namespace[]>(['wasteDetails', 'orderCreation']);

  const navigate = useNavigate();

  const numberOfOpenOrders = t('numberOfOrders', { numberOfOrders });
  const serviceTypeValue = translate(camelCase(generalInfo.serviceType), 'domain', 'contractType');

  const shouldDisplayOrdersCreationButton =
    user &&
    isOrdersCreationToggleOn(user) &&
    !generalInfo.expired &&
    shouldAllowOrdersCreation(contractIdentifier, generalInfo.serviceType);

  const handleSeeRelatedOrdersClick = () => {
    logEvent('serviceDetailsOpenOrdersClicked', null);

    navigate(paths.ordersTracking, { state: { contractNo: generalInfo.contractNumber } });
  };

  return (
    <>
      <Box {...linkBoxProps} marginBottom={shouldDisplayOrdersCreationButton ? spacing.xxs : spacing.xxxs}>
        <ForwardLink {...linkProps} onClick={handleSeeRelatedOrdersClick} data-testid="link-to-orders">
          {t('wasteDetails:seeRelatedOrders')}
        </ForwardLink>

        {shouldDisplayOrdersCreationButton && (
          <Button
            {...buttonProps}
            data-testid="navigate-to-orders-creation-button"
            rightIcon={<IconAdd />}
            onClick={() => navigate(paths.ordersCreation, { state: { contractIdentifier } })}
          >
            {t('orderCreation:createNewOrder')}
          </Button>
        )}
      </Box>

      <TableTile>
        <HeaderRow>
          <HeaderCell text={t('wasteDetails:contractNo')} gridColumnProps={contractNoColProps} />
          <HeaderCell text={t('wasteDetails:queue')} gridColumnProps={openOrdersColProps} />
          <HeaderCell text={t('wasteDetails:serviceType')} gridColumnProps={serviceTypeColProps} />
        </HeaderRow>

        <ValuesRow>
          <ValueCell
            text={displayedValue(generalInfo.contractNumber)}
            gridColumnProps={contractNoColProps}
            data-testid="contract-number-value-col"
          />

          <ValueCell text={numberOfOpenOrders} gridColumnProps={openOrdersColProps} data-testid="open-orders-value-col" />

          <ValueCell text={serviceTypeValue} gridColumnProps={serviceTypeColProps} data-testid="service-type-value-col" />
        </ValuesRow>
      </TableTile>
    </>
  );
};
