import { Co2ReportObjects } from '@config/qlik';
import { FC } from 'react';
import { Section, SectionHorizontalSubContainer, sectionTitleContentTextProps } from './styles';
import { ContentText } from '@fortum/elemental-ui';
import { QlikObject } from '@components/qlik';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';

interface EmissionInsightsProps {
  objectIds: Co2ReportObjects;
}

const CHARTS_PADDING = '8px';
const PIE_CHARTS_HEIGHT = '280px';
const BAR_CHARTS_HEIGHT = '344px';

export const EmissionInsights: FC<EmissionInsightsProps> = ({ objectIds }) => {
  const { t } = useTranslation<Namespace>('reports');

  return (
    <Section data-testid="emission-insights">
      <ContentText {...sectionTitleContentTextProps}>{t('co2.emissionInsights.header')}</ContentText>

      <SectionHorizontalSubContainer>
        <QlikObject
          dataTestId="pie-chart-co2e-emissions-waste-desc"
          id={objectIds.charts.co2eEmissions}
          padding={CHARTS_PADDING}
          height={PIE_CHARTS_HEIGHT}
        />
        <QlikObject
          dataTestId="pie-chart-co2e-emissions-avoided"
          id={objectIds.charts.co2eEmissionsAvoided}
          padding={CHARTS_PADDING}
          height={PIE_CHARTS_HEIGHT}
        />
        <QlikObject
          dataTestId="pie-chart-emissions-by-logistics-and-treatment"
          id={objectIds.charts.emissionsByLogisticsAndTreatment}
          padding={CHARTS_PADDING}
          height={PIE_CHARTS_HEIGHT}
        />
      </SectionHorizontalSubContainer>

      <SectionHorizontalSubContainer>
        <QlikObject
          dataTestId="bar-chart-total-waste-amount"
          id={objectIds.charts.totalWasteAmount}
          padding={CHARTS_PADDING}
          height={BAR_CHARTS_HEIGHT}
        />

        <QlikObject
          dataTestId="bar-chart-total-emission"
          id={objectIds.charts.totalEmissions}
          padding={CHARTS_PADDING}
          height={BAR_CHARTS_HEIGHT}
        />
      </SectionHorizontalSubContainer>
    </Section>
  );
};
