import { useContext, useRef } from 'react';
import { useQlikLayout } from './useQlikLayout';
import { QlikEngineContext } from '../QlikEngineProvider';

const qProp = {
  qInfo: { qType: 'BookmarkList' },
  qBookmarkListDef: {
    qType: 'bookmark',
  },
};

export const useBookmarksList = () => {
  const { enigma } = useContext(QlikEngineContext);
  const { qLayout, error, isLoading } = useQlikLayout(enigma, qProp);
  const bookmarksRef = useRef<enigmaJS.QBookmark[]>([]);

  if (qLayout && qLayout.qBookmarkList) {
    bookmarksRef.current = qLayout.qBookmarkList.qItems;
  }

  return { qBookmarks: bookmarksRef.current, isError: !!error, isLoading };
};
