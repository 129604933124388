import {
  Box,
  colors,
  ComponentOrElement,
  ContentText,
  fontSizes,
  IconDistrictHeatingOutage,
  IconMassMarketTested,
  IconProps,
  renderComponentOrElement,
  spacing,
} from '@fortum/elemental-ui';
import { useNavigate } from 'react-router-dom';
import { NonWrappingLinkLowerCased } from './styles';
import { paths } from '@config/routes';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { FC } from 'react';
import { Stylable } from '@components/types';

interface Props extends Stylable {
  header: string;
  message: string;
  link?: {
    navigatePath: string;
    text: string;
  };
  icon: ComponentOrElement<IconProps>;
}

const NoDataView: FC<Props> = ({ header, message, link, className, icon }) => {
  const navigate = useNavigate();

  return (
    <Box display="flex" flex={1} flexDirection="column" alignItems="center" className={className}>
      <Box display="flex" flexDirection="column" maxWidth="450px" alignItems="center">
        {renderComponentOrElement(icon, { paddingBottom: spacing.xxxs, color: colors.silverGrey })}

        <ContentText fontSize="23px" color={colors.emperorGrey} textAlign="center">
          {header}
        </ContentText>
        <ContentText textAlign="center" fontSize={fontSizes.s} color={colors.silverGrey}>
          {message}
          {link && (
            <NonWrappingLinkLowerCased
              fontSize={fontSizes.s}
              noUnderline
              marginLeft={spacing.xxxxs}
              onClick={() => navigate(link.navigatePath)}
            >
              {link.text}
            </NonWrappingLinkLowerCased>
          )}
        </ContentText>
      </Box>
    </Box>
  );
};

export const ErrorView: FC<Partial<Props> & { hideLink?: boolean }> = ({
  icon = IconDistrictHeatingOutage,
  header,
  link,
  message,
  hideLink = false,
  className,
}) => {
  const { t } = useTranslation<Namespace>('errors');
  return (
    <NoDataView
      className={className}
      icon={icon}
      header={header ?? t('failedToLoad')}
      message={message ?? t('tryReload')}
      link={!link && hideLink ? undefined : { navigatePath: paths.contactUs, text: t('errors:contactUs') }}
    />
  );
};

export const EmptyResponseView: FC<Partial<Props> & { hideLink?: boolean }> = ({
  icon = IconMassMarketTested,
  header,
  link,
  message,
  hideLink = false,
  className,
}) => {
  const { t } = useTranslation<Namespace[]>(['errors', 'common']);
  return (
    <NoDataView
      className={className}
      icon={icon}
      header={header ?? t('common:emptyResponse.header')}
      message={message ?? t('common:emptyResponse.message')}
      link={!link && hideLink ? undefined : { navigatePath: paths.contactUs, text: t('errors:contactUs') }}
    />
  );
};
