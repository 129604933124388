import {
  ContentText,
  ContentTextProps,
  RadioButtonGroupProps as ElementalRadioButtonGroupProps,
  Radio,
  fontSizes,
  fontWeights,
} from '@fortum/elemental-ui';
import { FC, memo } from 'react';
import { RadioButtonsContainer } from './styles';
import { Testable } from '@components/types';

type HorizontalRadioButtonGroupProps = Pick<ElementalRadioButtonGroupProps, 'label' | 'onChange' | 'value' | 'items'> & Testable;

const labelStyles: ContentTextProps = {
  fontSize: fontSizes.s,
  fontWeight: fontWeights.medium,
};

//TODO: use only checked prop when it is fixed
export const HorizontalRadioButtonGroup: FC<HorizontalRadioButtonGroupProps> = memo(
  ({ label, onChange, value, items, 'data-testid': testId }) => (
    <RadioButtonsContainer data-testid={testId}>
      <ContentText {...labelStyles}>{label}</ContentText>

      {items.map(item => {
        const checked = value === item.value;
        const dataTestId = `${item.value}${checked ? '-checked' : ''}`;

        return (
          <Radio
            key={item.value}
            data-testid={dataTestId}
            value={item.value}
            label={item.label}
            id={item.id}
            onChange={onChange}
            checked={checked}
          />
        );
      })}
    </RadioButtonsContainer>
  ),
);

HorizontalRadioButtonGroup.displayName = 'HorizontalRadioButtonGroup';
