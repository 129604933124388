import { ContactFooterContainer, ContactInformationContainer } from './styles';
import { useUserInfo } from '@data/hooks/useUserInfo';
import { FC } from 'react';
import { EmailAddress } from './EmailAddress';
import { Phone } from './Phone';
import { NeedHelpMessage } from './NeedHelpMessage';
import { ServiceHours } from './ServiceHours';

export const ContactFooter: FC = () => {
  const { data: user } = useUserInfo();

  return (
    <ContactFooterContainer>
      <NeedHelpMessage />
      {user && (
        <ContactInformationContainer>
          <EmailAddress email={user.customerService.email} />
          <Phone phone={user.customerService.phone} />
          <ServiceHours user={user} />
        </ContactInformationContainer>
      )}
    </ContactFooterContainer>
  );
};
