import { useCallback, useContext } from 'react';
import { QlikAppContext } from '../QlikAppProvider';

export const useBookmarksManagement = () => {
  const { app } = useContext(QlikAppContext);

  const removeBookmark = useCallback(
    async (id: string) => {
      if (!app) {
        throw Error('App not found');
      }

      return app.bookmark.remove(id);
    },
    [app],
  );

  const applyBookmark = useCallback(
    async (id: string) => {
      if (!app) {
        throw Error('App not found');
      }

      await app.model.engineApp.clearAllSoftPatches();

      return app.bookmark.apply(id);
    },
    [app],
  );

  const createBookmark = useCallback(
    async (title: string, description: string) => {
      if (!app) {
        throw Error('App not found');
      }

      const res = await app.model.engineApp.createBookmarkEx({
        qProp: {
          qInfo: {
            qType: 'bookmark',
          },
          qMetaDef: {
            title,
            description,
            isExtended: true,
          },
          qIncludeVariables: true,
        },
        qObjectIdsToPatch: [],
      });

      return res.qInfo.id;
    },
    [app],
  );

  return { removeBookmark, applyBookmark, createBookmark };
};
