import { HeaderButtonsContainer } from '../../styles';
import { BookmarksContext } from '@components/qlik';
import { FC, useContext } from 'react';
import { BookmarksManagement } from './BookmarksManagement';
import { PageHeader as CommonPageHeader } from '@components/PageHeader';

export interface PageHeaderProps {
  title: string;
}

export const PageHeader: FC<PageHeaderProps> = ({ title }) => {
  const bookmarksContext = useContext(BookmarksContext);

  return bookmarksContext ? (
    <CommonPageHeader
      header={title}
      componentRight={
        <HeaderButtonsContainer>
          <BookmarksManagement />
        </HeaderButtonsContainer>
      }
    />
  ) : (
    <CommonPageHeader header={title} />
  );
};
