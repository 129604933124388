import { translate } from '@utils/internationalization';
import { Box, BoxProps, ContentText, ContentTextProps, fontSizes } from '@fortum/elemental-ui';
import { hasValue } from '@utils/dataOperations';
import { NO_DATA_BADGE } from '@utils/dataOperations';
import { FC } from 'react';
import { Testable } from '@components/types';

interface WasteRdCodeProps extends Testable {
  code: string;
}

const textStyles: ContentTextProps = {
  fontSize: fontSizes.s,
};

const containerStyles: BoxProps = {
  display: 'flex',
  flexDirection: 'column',
};

export const WasteRdCode: FC<WasteRdCodeProps> = ({ code, 'data-testid': dataTestId }) =>
  hasValue(code) ? (
    <Box {...containerStyles} data-testid={dataTestId}>
      <ContentText {...textStyles} data-testid="code-entry">
        {code}
      </ContentText>
      <ContentText {...textStyles} data-testid="description-entry">
        {translate(code, 'domain', 'rdCode')}
      </ContentText>
    </Box>
  ) : (
    NO_DATA_BADGE
  );
