import { QueryKeys } from '@common/query';
import { sessionKeys, StorageUtils } from '@common/storage';
import { queryClient } from '@config/queryClient';
import { UserPermission } from '@config/user';
import type { BusinessPartner, InternalAccess, User, UserAccess, UserRole, UserType } from '@models/user';

export const SELECTED_BUSINESS_PARTNERS_LIMIT = 10;

export const bpsSelectionMissing = (userAccess: UserAccess | undefined) => {
  if (userAccess?.isInternal && userAccess?.selectedBusinessPartners.length === 0) {
    return true;
  }

  return false;
};

// TODO: consider this moving into another util bundle, but as for now it's mostly used with users operations, thus it could live here
export const getSelectedBusinessPartners = (): BusinessPartner[] =>
  StorageUtils.getObject<BusinessPartner[]>(sessionKeys.selectedBusinessPartners) || [];

export const isUserInternal = (userRole: UserRole | undefined) => userRole === 'INTERNAL';

export const isUserAccessOfInternal = (userAccess: UserAccess | undefined): userAccess is InternalAccess =>
  !!userAccess && userAccess.isInternal;

export const getUserAccessInfo = (): UserAccess => {
  const user = queryClient.getQueryData<User>([QueryKeys.userInfo]);

  if (!user) throw new Error('User not initialized');

  if (isUserInternal(user.userRole)) {
    return {
      isInternal: true,
      selectedBusinessPartners: getSelectedBusinessPartners(),
    };
  }

  return { isInternal: false };
};

export const bpsSelectionItemDisabled = (selectedBps: BusinessPartner[], itemId: string, itemName: string) =>
  selectedBps.length >= SELECTED_BUSINESS_PARTNERS_LIMIT &&
  !selectedBps.some(selectedBP => selectedBP.id === itemId && selectedBP.name === itemName);

export const getUserName = (user: User | undefined) => (user ? [user.name, user.lastname].filter(Boolean).join(' ') : '');

export const hasPermission = (user: User, permission: UserPermission) => {
  const permissionsPerCompany = Object.values(user.permissionsPerCompany).flat();

  return user.permissions.includes(permission) || permissionsPerCompany.includes(permission);
};

export const getUserType = (userRole: UserRole): UserType => (isUserInternal(userRole) ? 'INTERNAL' : 'EXTERNAL');
