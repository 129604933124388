import { createContext, Dispatch, FC, ReactNode, SetStateAction, useState } from 'react';
import { OrdersFiltersHandlerReturnType, useOrdersFiltersHandler } from './useOrdersFiltersHandler';
import { SortByValue } from './config';
import { INITIAL_PAGE_NUMBER } from '@common/consts';

type OrdersFiltersContextProps = OrdersFiltersHandlerReturnType & {
  sortBy: SortByValue;
  setSortBy: Dispatch<SetStateAction<SortByValue>>;
  activePage: number;
  setActivePage: Dispatch<SetStateAction<number>>;
};

export const OrdersFiltersContext = createContext<OrdersFiltersContextProps | null>(null);
interface OrdersFiltersContextProviderProps {
  children: ReactNode;
}
export const OrdersFiltersContextProvider: FC<OrdersFiltersContextProviderProps> = ({ children }) => {
  const filtersHandler = useOrdersFiltersHandler(false);
  const [sortBy, setSortBy] = useState<SortByValue>('status');
  const [activePage, setActivePage] = useState<number>(INITIAL_PAGE_NUMBER);

  const contextValue: OrdersFiltersContextProps = {
    ...filtersHandler,
    sortBy,
    setSortBy,
    activePage,
    setActivePage,
  };

  return <OrdersFiltersContext.Provider value={contextValue}>{children}</OrdersFiltersContext.Provider>;
};
