import { OrdersSelectedFiltersWithSearch } from '@models/filters';

export const chipsDisplayOrder: (keyof OrdersSelectedFiltersWithSearch)[] = [
  'timePeriod',
  'orderStatus',
  'orderType',
  'wasteDescription',
  'transportType',
  'containerType',
  'businessPartner',
  'city',
  'search',
];
