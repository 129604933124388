import { getAuthHeaders } from '@utils/data';
import { Headers as HeadersConstants, MimeTypes } from 'http-constants-ts';
import { urls } from './config';

export const terminateApplicationSession = async () => {
  const headers = getAuthHeaders();
  headers.append(HeadersConstants.CONTENT_TYPE, MimeTypes.APPLICATION_JSON);

  if (!urls.userLogOut) throw Error('URL for token revocation not provided');

  await fetch(urls.userLogOut, { method: 'PUT', mode: 'cors', headers });
};
