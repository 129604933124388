// "NO_DATA_INDICATOR" is a char used by BE to indicate that there is no data
// "NO_DATA_BAGE" is a char used by FE to display empty data

export const NO_DATA_BADGE = '-';

export const NO_DATA_INDICATOR = '-';

export const DATA_SOURCE_PREFIX = 'enwis_db';
export const DATA_SOURCE_DELIMITER = '_';

export const SEARCH_CHIP_VALUE = 'search';
